const ArrowLeft = ({ color = '#342236' }: { color?: string }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3963 14.6117C10.7039 14.9179 11.2014 14.9167 11.5076 14.6092V14.6092C11.8138 14.3016 11.8126 13.8041 11.5051 13.4979L6.31856 8.33461L11.4819 3.14808C11.7881 2.84053 11.787 2.34301 11.4794 2.03683V2.03683C11.1719 1.73066 10.6743 1.73177 10.3682 2.03932L4.71665 7.71622C4.37391 8.0605 4.37515 8.61744 4.71944 8.96018L10.3963 14.6117Z"
        fill={color}
        stroke={color}
        strokeWidth="2.66667"
      />
    </svg>
  );
};

export default ArrowLeft;
