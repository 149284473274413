import gsap from 'gsap';
import { Block } from './blocks/block';
import { isoContainer } from './isoContainer';
import { Player } from './player/player';
import { toIsoX, toIsoY } from './utils';

export class ScrollingWorld extends isoContainer {
  constructor(x: number, y: number, z: number) {
    super(x, y, z);
  }

  public updatePlayerBlockIndex(player: Player, block: Block, offset: number = 1): void {
    this.setChildIndex(player, this.getChildIndex(block) + offset);
  }

  public reset(centerX: number, centerY: number) {
    gsap.killTweensOf(this);
    gsap.killTweensOf(this.pivot);
    gsap.killTweensOf(this.scale);
    this.removeChildren(0, this.children.length);
    this.setPosition(centerX, centerY, 0);
    this.pivot.set(0);
    this.scale.set(1.0);
  }

  public scrollTo(x: number, y: number, interrupt: boolean = false): void {
    gsap.to(this.pivot, {
      duration: 1.0,
      x: toIsoX(x, y, 0),
      y: toIsoY(x, y, 0),
      ease: interrupt ? 'sine.out' : 'sine.inOut',
      overwrite: true,
    });
  }

  public getScreenDistance(player: Player, targetBlock: Block, scale: number): number {
    const screenDist = Math.abs(
      toIsoX(targetBlock.startPos.x, targetBlock.startPos.y, 0) - toIsoX(player.isoX, player.isoY, 0)
    );
    return screenDist * scale + 20;
  }

  public adjustZoom(player: Player, targetBlock: Block, scale: number): void {
    const screenDist = this.getScreenDistance(player, targetBlock, scale) - window.innerWidth * 0.5;
    const newScale = screenDist < 0 ? 1.0 : 1.0 - (screenDist / window.innerWidth) * 0.5;

    if (this.scale.x != newScale) {
      gsap.to(this.scale, {
        duration: 1.0,
        x: newScale,
        y: newScale,
        ease: 'power1.out',
      });
    }
  }
}
