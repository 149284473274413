import styles from './ClickableLeaderboardItem.module.scss';
import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text/Text';
import { ArrowRight } from '@/components/Icons/ArrowRight';

type ClickableLeaderboardItemProps = {
  rank: number;
  onClick: () => void;
  logo?: string;
  name: string;
  score: number;
};

export const ClickableLeaderboardItem = ({ rank, onClick, logo, name, score }: ClickableLeaderboardItemProps) => {
  return (
    <Button className={styles.clickableLeaderboardItem} onClick={onClick} fullWidth>
      <div className={styles.itemContent}>
        <Text as={'span'} type={'mono'} translate={false}>
          {rank}
        </Text>
        {logo && <img src={logo} alt={`display-image-${name}`} />}
        <Text as={'span'} type={'mono'} translate={false}>
          {name}
        </Text>
      </div>
      <div className={styles.itemContent}>
        <Text as={'span'} type={'mono'}>
          {{ score }}
        </Text>
        <div className={styles.separator} />
        <ArrowRight />
      </div>
    </Button>
  );
};
