import cls from 'classnames';
import React, { useState, useCallback, useEffect } from 'react';
import sittingCat from '../../assets/cat-sitting.png';
import { useGroupLeaderboardData, useGenericLeaderboardData } from '../../hooks/use-leaderboard-data';
import { usePageViewTracking } from '../../game-react/hooks/use-page-view-tracking';
import { BackButton } from '../BackButton/BackButton';
import { Button } from '../Button/Button';
import styles from './Leaderboard.module.scss';
import { useGroups } from '../../game-react/hooks/use-groups';
import Select, { components } from 'react-select';
import { LOCAL_STORAGE_LAST_VIEWED_GROUP_LEADERBOARD_KEY } from '../../utils/constants';
import { Text } from '../Text/Text';
import { UserGroup } from '@/types/UserGroup';
import { useArcadeSdk } from '@/lib/arcade-react-sdk/ArcadeSdkProvider';
import { LeaderboardType } from '@/types/leaderboard';
import { LeaderboardTable as LeaderboardComponent } from '@/features/leaderboard/LeaderboardTable';
import { LeaderboardEntry, LeaderboardScoreType } from '@/features/leaderboard/LeaderboardEntry';
import { PartnerGroup, usePartnerGroups } from '@/hooks/use-partner-groups';
import { transformLeaderboardData } from '@/api/domains/leaderboards/leaderboard-utils';
import { usePlayerData } from '@/hooks/use-player-leaderboard-data';
import { t } from 'i18next';

type LeaderboardProps = {
  onBackHome: () => void;
  selectedTab?: LeaderboardTabType;
  eventGroupId?: string;
};

type TimeTab = {
  name: string;
  controls: string;
  id: LeaderboardType;
  featureFlag?: string;
};

const DEFAULT_TIME_PERIOD_TABS: TimeTab[] = [
  {
    name: 'Daily',
    controls: 'all',
    id: LeaderboardType.DAILY,
  },
  {
    name: 'Weekly',
    controls: 'all',
    id: LeaderboardType.WEEKLY,
  },
  {
    name: 'All-Time',
    controls: 'all',
    id: LeaderboardType.ALL,
  },
];

export enum LeaderboardTabType {
  Global = 'global',
  Groups = 'groups',
  ComingSoon = 'coming-soon',
  DailyChallenge = 'daily-challenge',
}

export const Leaderboard = ({
  onBackHome,
  selectedTab = LeaderboardTabType.Global,
  eventGroupId,
}: LeaderboardProps) => {
  usePageViewTracking('leaderboard');
  const [selected, setSelected] = useState(selectedTab);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(LeaderboardType.DAILY);
  const { groupData } = useGroups();
  const { flags } = useArcadeSdk();

  const tabs = [
    {
      name: 'Global',
      controls: 'all',
      id: LeaderboardTabType.Global,
    },
    {
      name: 'Groups',
      controls: 'all',
      id: LeaderboardTabType.Groups,
    },
    ...(flags.dailyChallenge
      ? [
          {
            name: 'Daily Challenge',
            controls: 'all',
            id: LeaderboardTabType.DailyChallenge,
          },
        ]
      : []),
    {
      name: 'Coming Soon',
      controls: 'disabled',
      id: LeaderboardTabType.ComingSoon,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton onClick={onBackHome} />
        <Text as="h3" type="heading" className={styles.heading}>
          Leaderboard
        </Text>
      </div>
      <img className={styles.sittingCat} src={sittingCat} />
      <LeaderboardTab tabs={tabs} selectedTab={selected} setSelectedTab={setSelected} />
      <LeaderboardTable
        leaderboardTabType={selected}
        leaderboardId={selectedTimePeriod}
        setSelectedTimePeriod={setSelectedTimePeriod}
        groupData={groupData}
        eventGroupId={eventGroupId}
      />
    </div>
  );
};

export default Leaderboard;

export const LeaderboardTable = ({
  leaderboardTabType,
  leaderboardId,
  setSelectedTimePeriod,
  groupData,
  eventGroupId,
}: {
  leaderboardTabType: LeaderboardTabType;
  leaderboardId: LeaderboardType;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<LeaderboardType>>;
  groupData: UserGroup[];
  eventGroupId?: string;
}) => {
  const { flags } = useArcadeSdk();
  const { playToPromote } = flags;
  const shouldRenderPartnerGroups = !playToPromote;

  const lastViewedGroupLeaderboard = localStorage.getItem(LOCAL_STORAGE_LAST_VIEWED_GROUP_LEADERBOARD_KEY);
  const [selectedGroupId, setSelectedGroupId] = useState(eventGroupId || lastViewedGroupLeaderboard || '');

  /**
   *
   * If the selected group is a partner group, and we do not show partner groups, then remove the selection.
   */
  useEffect(() => {
    const selectedGroup = groupData.find((group) => group.telegramId === selectedGroupId);
    if (!shouldRenderPartnerGroups && selectedGroup?.isPartner === true) {
      setSelectedGroupId('');
      localStorage.removeItem(LOCAL_STORAGE_LAST_VIEWED_GROUP_LEADERBOARD_KEY);
    }
  }, [selectedGroupId, groupData, shouldRenderPartnerGroups]);

  const id = leaderboardTabType === LeaderboardTabType.DailyChallenge ? LeaderboardType.DAILY_CHALLENGE : leaderboardId;

  const { data: playerData } = usePlayerData();
  const {
    data: leaderboardData,
    fetchNextPage: fetchLeaderboardData,
    isFetching: isFechingLeaderboardData,
    error: leaderboardError,
    hasNextPage: hasMoreLeaderboardData,
    refetch: refetchLeaderboardData,
    isFetchingNextPage,
  } = useGenericLeaderboardData({ leaderboardId: id });

  const leaderboardResult = transformLeaderboardData(leaderboardData);

  const {
    groupLeaderboardData,
    fetchNextGroupLeaderboardData,
    isFetchingNextGroupLeaderboardPage,
    isFechingGroupLeaderboardData,
    hasMoreGroupLeaderboardData,
    refetchGroupLeaderboardData,
  } = useGroupLeaderboardData(
    id,
    leaderboardTabType === LeaderboardTabType.DailyChallenge ? undefined : selectedGroupId
  );

  const isGroupLeaderboard = leaderboardTabType === LeaderboardTabType.Groups;

  const selectedLeaderboardData = isGroupLeaderboard ? groupLeaderboardData : leaderboardResult;

  const fetchNextSelectedLeaderboardData = isGroupLeaderboard ? fetchNextGroupLeaderboardData : fetchLeaderboardData;
  const refetchSelectedLeaderboardData = isGroupLeaderboard ? refetchGroupLeaderboardData : refetchLeaderboardData;
  const isFetchingNextSelectedLeaderboardPage = isGroupLeaderboard
    ? isFetchingNextGroupLeaderboardPage
    : isFetchingNextPage;
  const isFechingSelectedLeaderboardData = isGroupLeaderboard
    ? isFechingGroupLeaderboardData
    : isFechingLeaderboardData;
  const hasMoreSelectedLeaderboardData = isGroupLeaderboard ? hasMoreGroupLeaderboardData : hasMoreLeaderboardData;

  const memoizedFetchNextData = useCallback(() => {
    fetchNextSelectedLeaderboardData();
  }, [fetchNextSelectedLeaderboardData]);

  const { partnerGroups, getPartnerGroup } = usePartnerGroups();

  if (!partnerGroups) {
    return null;
  }

  // If we're in the leaderboard for a partner community, we want to show the
  // group logo for every player. Else, we show the logo based on the community
  // ID set in the user's metadata
  const showPartnerLogo = isGroupLeaderboard && partnerGroups.some((group) => group.telegramId === selectedGroupId);

  const communityLogoId = showPartnerLogo ? selectedGroupId : playerData?.communityId?.toString();

  const community = getPartnerGroup(communityLogoId);

  const selectedGroup = [...partnerGroups, ...groupData].find((group) => group.telegramId === selectedGroupId);

  const timeTabs = leaderboardTabType === LeaderboardTabType.DailyChallenge ? undefined : DEFAULT_TIME_PERIOD_TABS;

  return (
    <>
      {isGroupLeaderboard && (
        <GroupDropdown
          partnerGroups={shouldRenderPartnerGroups ? partnerGroups : []}
          allGroups={groupData}
          setGroup={(group) => {
            localStorage.setItem(LOCAL_STORAGE_LAST_VIEWED_GROUP_LEADERBOARD_KEY, group);
            setSelectedGroupId(group);
          }}
          selectedGroup={selectedGroup}
        />
      )}
      <LeaderboardComponent
        items={selectedLeaderboardData?.results}
        isFetchingData={isFechingSelectedLeaderboardData}
        isFetchingNextPage={isFetchingNextSelectedLeaderboardPage}
        hasNextPage={hasMoreSelectedLeaderboardData ?? false}
        fetchNextPage={memoizedFetchNextData}
        error={leaderboardError === undefined}
        onError={refetchSelectedLeaderboardData}
        tabs={timeTabs}
        tabType={leaderboardTabType}
        setSelectedTab={setSelectedTimePeriod}
        selectedTab={leaderboardId}
        className={styles.leaderboardTable}
      />

      {playerData && (
        <div>
          <LeaderboardEntry
            key={selectedLeaderboardData?.playerResult?.position}
            name={playerData.username!}
            img={community?.logo}
            score={selectedLeaderboardData?.playerResult?.score}
            scoreType={
              leaderboardTabType === LeaderboardTabType.DailyChallenge
                ? LeaderboardScoreType.timeScore
                : LeaderboardScoreType.numberScore
            }
            rank={selectedLeaderboardData?.playerResult?.position}
            single
          />
        </div>
      )}
    </>
  );
};

export type Tab = {
  id: LeaderboardTabType;
  name: string;
  controls: string;
};

export type LeaderboardTabsProps = {
  tabs: Tab[];
  selectedTab: LeaderboardTabType;
  setSelectedTab: (type: LeaderboardTabType) => void;
};

export function LeaderboardTab({ tabs, selectedTab, setSelectedTab }: LeaderboardTabsProps) {
  return (
    <ul className={styles.tabsNavStyle}>
      {tabs.map((tab, i) => (
        <div key={'button-container-' + tab.name} className={styles.tab}>
          <Button
            key={tab.name}
            onClick={async () => {
              setSelectedTab(tab.id);
            }}
            disabled={tab.controls === 'disabled'}
            className={cls(styles.linkComponent, {
              [styles.isSelected]: selectedTab === tab.id,
              [styles.disabled]: tab.controls === 'disabled',
            })}
          >
            <Text as="span" type="button">
              {tab.name}
            </Text>
          </Button>
          {i < tabs.length - 1 && <div className={styles.separator} />}
        </div>
      ))}
    </ul>
  );
}

function GroupDropdown({
  partnerGroups,
  allGroups,
  setGroup,
  selectedGroup,
}: {
  partnerGroups: PartnerGroup[];
  allGroups: UserGroup[];
  setGroup: (group: string) => void;
  selectedGroup?: UserGroup | PartnerGroup;
}) {
  const nonPartnerGroup = allGroups.filter((group) => !group.isPartner);
  const showPartners = partnerGroups.length > 0;

  const groupedOptions: {
    readonly label: string;
    readonly options: { label: string; value: string; image?: string }[];
  }[] = [
    ...(showPartners
      ? [
          {
            label: t('Partners'),
            options: partnerGroups.map((item) => ({
              label: item.displayName,
              value: item.telegramId,
              image: item.logo,
              hasContest: item.hasContest,
            })),
          },
        ]
      : []),
    {
      label: showPartners ? t('Other Groups') : t('Groups'),
      options: nonPartnerGroup.map((item) => ({
        label: item.displayName,
        value: item.telegramId,
      })),
    },
  ];

  // @ts-expect-error external lib doesn't expose prop type for this component
  const Placeholder = (props) => (
    <components.Placeholder {...props} className={styles.dropdownPlaceholder}></components.Placeholder>
  );

  // @ts-expect-error external lib doesn't expose prop type for this component
  const Control = (props) => <components.Control {...props} className={styles.dropdown}></components.Control>;

  // @ts-expect-error external lib doesn't expose prop type for this component
  const Option = (props) => (
    <components.Option {...props}>
      {props.data.image ? <img src={props.data.image} /> : null}
      {props.label}
    </components.Option>
  );

  let hasContest = false;
  if (selectedGroup) {
    hasContest = 'hasContest' in selectedGroup ? selectedGroup.hasContest : false;
  }

  return (
    <Select
      options={groupedOptions}
      placeholder="Select Group"
      components={{ Placeholder, Control, Option }}
      //@ts-expect-error external lib expects wrong types because the options are grouped
      onChange={(e: { label: string; value: string }) => {
        setGroup(e.value);
      }}
      value={
        selectedGroup
          ? {
              label: selectedGroup?.displayName,
              value: selectedGroup?.telegramId,
            }
          : null
      }
      noOptionsMessage={() => 'No groups found!'}
      isSearchable={false}
      styles={{
        control: (base) => ({
          ...base,
          boxShadow: 'none',
          border: '0.5px solid #202025',
          fontFamily: 'DM Sans',
          fontWeight: 500,
          padding: '0 12px',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        dropdownIndicator: (base) => ({
          ...base,
          color: '#202025',
        }),
        group: (base) => ({
          ...base,
          backgroundColor: '#202025',
          marginTop: 0,
          padding: 0,
        }),
        groupHeading: (base) => ({
          ...base,
          backgroundColor: '#202025',
          padding: 16,
          paddingBlock: 12,
          letterSpacing: '0.1em',
          color: 'white',
          fontFamily: 'DM Mono',
          fontWeight: 500,
          fontSize: 13,
          margin: 0,
        }),
        menuList: (base) => ({
          ...base,
          borderRadius: 16,
          padding: 0,
          background: 'white',
          minHeight: 50,
          maxHeight: 'calc(100vh - 230px)',
        }),
        option: (base) => ({
          ...base,
          background: 'white',
          color: '#202025',
          padding: '12px',
          fontFamily: 'DM Sans',
          fontWeight: 500,
          fontSize: 16,
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          img: {
            width: '24px',
            height: '24px',
          },
          ':active': {
            background: 'white',
          },
        }),
        menu: (base) => ({
          ...base,
          borderRadius: 16,
          background: 'transparent',
        }),
        input: (base) => ({
          ...base,
          color: 'transparent',
        }),
        singleValue: (base) => ({
          ...base,
          display: 'flex',
          alignItems: 'center',
          padding: 5,
          ':after': hasContest
            ? {
                backgroundColor: '#FF5546',
                borderRadius: '4px',
                content: '"contest"',
                color: 'white',
                display: 'block',
                marginLeft: '8px',
                padding: '2px 4px',
                fontSize: '10px',
                textTransform: 'uppercase',
              }
            : {},
        }),
      }}
    />
  );
}
