import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { useNakama } from './NakamaProvider';

type UserFlags = {
  hasSeenFTUE: boolean;
  hasAcceptedToS: boolean;
  hasChangedUsername: boolean;
};

type UserFlagsContextType = {
  userFlags: UserFlags;
  setHasSeenFTUEFlag: (seen: boolean) => void;
  setHasAcceptedToSFlag: (accepted: boolean) => void;
  setHasChangedUsernameFlag: (changed: boolean) => void;
};

const GetUserFlagsResponseSchema = z.object({
  has_seen_ftue: z.boolean(),
  has_accepted_tos: z.boolean(),
  has_changed_username: z.boolean(),
});

type GetUserFlagsResponse = z.infer<typeof GetUserFlagsResponseSchema>;

const UserFlagsContext = createContext<UserFlagsContextType>({
  userFlags: {
    hasSeenFTUE: false,
    hasAcceptedToS: false,
    hasChangedUsername: false,
  },
  setHasSeenFTUEFlag: () => {},
  setHasAcceptedToSFlag: () => {},
  setHasChangedUsernameFlag: () => {},
});

export const UserFlagsProvider = ({ children }: { children: ReactNode }) => {
  const { client, session, isReady } = useNakama();
  const [userFlags, setUserFlags] = useState<UserFlags>({
    hasSeenFTUE: false,
    hasAcceptedToS: false,
    hasChangedUsername: false,
  });

  const fetchUserFlags = useCallback(async () => {
    if (!client || !session) {
      return;
    }

    const response = await client.readStorageObjects(session, {
      object_ids: [
        {
          collection: 'user_flags',
          key: 'has_seen_ftue',
          user_id: session?.user_id,
        },
        {
          collection: 'user_flags',
          key: 'has_accepted_tos',
          user_id: session?.user_id,
        },
        {
          collection: 'user_flags',
          key: 'has_changed_username',
          user_id: session?.user_id,
        },
      ],
    });

    let flags: GetUserFlagsResponse = {} as GetUserFlagsResponse;
    for (const obj of response.objects) {
      if (obj.value) {
        flags = { ...flags, ...obj.value };
      }
    }

    if (!GetUserFlagsResponseSchema.safeParse(flags).success) {
      console.error('Invalid flags response', flags);
      return;
    }

    // Set initial values for local state and in the game
    setUserFlags({
      hasSeenFTUE: flags.has_seen_ftue,
      hasAcceptedToS: flags.has_accepted_tos,
      hasChangedUsername: flags.has_changed_username,
    });
  }, [client, session]);

  const persistUserFlags = useCallback(
    async (newFlags: UserFlags) => {
      if (!client || !session) {
        return;
      }
      await client.writeStorageObjects(session, [
        {
          collection: 'user_flags',
          key: 'has_seen_ftue',
          value: { has_seen_ftue: newFlags.hasSeenFTUE },
        },
        {
          collection: 'user_flags',
          key: 'has_accepted_tos',
          value: { has_accepted_tos: newFlags.hasAcceptedToS },
        },
        {
          collection: 'user_flags',
          key: 'has_changed_username',
          value: { has_changed_username: newFlags.hasChangedUsername },
        },
      ]);
    },
    [client, session]
  );

  useEffect(() => {
    if (isReady) {
      fetchUserFlags();
    }
  }, [isReady, fetchUserFlags]);

  const setHasSeenFTUEFlag = useCallback(
    (seen: boolean) => {
      setUserFlags((prev) => {
        const newFlags = {
          ...prev,
          hasSeenFTUE: seen,
        };
        persistUserFlags(newFlags);
        return newFlags;
      });
    },
    [persistUserFlags]
  );

  const setHasAcceptedToSFlag = useCallback(
    (accepted: boolean) => {
      setUserFlags((prev) => {
        const newFlags = {
          ...prev,
          hasAcceptedToS: accepted,
        };
        persistUserFlags(newFlags);
        return newFlags;
      });
    },
    [persistUserFlags]
  );

  const setHasChangedUsernameFlag = useCallback(
    (changed: boolean) => {
      setUserFlags((prev) => {
        const newFlags = {
          ...prev,
          hasChangedUsername: changed,
        };
        persistUserFlags(newFlags);
        return newFlags;
      });
    },
    [persistUserFlags]
  );

  return (
    <UserFlagsContext.Provider
      value={{
        userFlags,
        setHasSeenFTUEFlag,
        setHasAcceptedToSFlag,
        setHasChangedUsernameFlag,
      }}
    >
      {children}
    </UserFlagsContext.Provider>
  );
};

export const useUserFlags = () => {
  return useContext(UserFlagsContext);
};
