import React from 'react';

type Props = {
  fill?: string;
};

export const EditIcon: React.FC<Props> = React.memo(({ fill = '#FF5546' }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.698 22.0234H5.09801C4.68839 22.0251 4.28261 21.9445 3.9047 21.7865C3.5268 21.6285 3.18447 21.3962 2.89801 21.1034C2.60944 20.8127 2.38132 20.4678 2.22685 20.0884C2.07237 19.7091 1.99459 19.3029 1.99801 18.8934V7.30337C1.9929 6.89565 2.07096 6.49117 2.22742 6.11463C2.38388 5.7381 2.61546 5.39741 2.90801 5.11337C3.19265 4.82477 3.53283 4.59685 3.90801 4.44337C4.28809 4.28443 4.69603 4.20284 5.10801 4.20337H9.54801C9.74692 4.20337 9.93769 4.28239 10.0783 4.42304C10.219 4.56369 10.298 4.75446 10.298 4.95337C10.298 5.15228 10.219 5.34305 10.0783 5.4837C9.93769 5.62435 9.74692 5.70337 9.54801 5.70337H5.10801C4.89292 5.70922 4.68019 5.74974 4.47801 5.82337C4.18335 5.94775 3.9321 6.15656 3.75592 6.4235C3.57975 6.69043 3.48652 7.00354 3.48801 7.32337V18.9134C3.4855 19.1282 3.52577 19.3414 3.60646 19.5406C3.68715 19.7397 3.80665 19.9209 3.95801 20.0734C4.10934 20.2235 4.28882 20.3423 4.48616 20.423C4.6835 20.5036 4.89482 20.5446 5.10801 20.5434H16.708C16.921 20.5434 17.131 20.5034 17.328 20.4234C17.5241 20.3446 17.7013 20.2254 17.848 20.0734C18 19.9266 18.1193 19.7494 18.198 19.5534C18.2864 19.3552 18.3307 19.1403 18.328 18.9234V14.4834C18.328 14.2845 18.407 14.0937 18.5477 13.953C18.6883 13.8124 18.8791 13.7334 19.078 13.7334C19.2769 13.7334 19.4677 13.8124 19.6083 13.953C19.749 14.0937 19.828 14.2845 19.828 14.4834V18.9534C19.8297 19.363 19.7492 19.7688 19.5911 20.1467C19.4331 20.5246 19.2008 20.8669 18.908 21.1534C18.6209 21.4415 18.2815 21.6723 17.908 21.8334C17.521 21.9734 17.11 22.0384 16.698 22.0234Z"
        fill={fill}
      />
      <path
        d="M21.808 5.48335C21.706 5.22587 21.5491 4.99378 21.348 4.80335L19.198 2.65335C19.0076 2.45229 18.7755 2.29529 18.518 2.19335C18.1311 2.03032 17.7042 1.98701 17.2924 2.069C16.8807 2.151 16.5029 2.35454 16.208 2.65335L14.498 4.36335V4.41335L6.758 12.1433C6.36723 12.5368 6.14795 13.0688 6.148 13.6233V15.7933C6.15062 16.3521 6.37377 16.8873 6.76891 17.2824C7.16404 17.6776 7.6992 17.9007 8.258 17.9033H10.428C10.7035 17.9037 10.9764 17.8491 11.2305 17.7426C11.4847 17.6361 11.715 17.48 11.908 17.2833L19.648 9.54335L21.368 7.82335C21.57 7.63335 21.728 7.40135 21.828 7.14335C21.9424 6.88688 22.0016 6.60919 22.0016 6.32835C22.0016 6.04751 21.9424 5.76981 21.828 5.51335L21.808 5.48335ZM20.428 6.53335C20.3955 6.6089 20.3479 6.67698 20.288 6.73335L19.068 7.95335L16.068 4.95335L17.298 3.72335C17.4161 3.60908 17.5737 3.54463 17.738 3.54335C17.8172 3.54467 17.8954 3.56166 17.968 3.59335C18.0453 3.62601 18.112 3.67268 18.168 3.73335L20.328 5.88335C20.3825 5.94209 20.4264 6.00973 20.458 6.08335C20.4728 6.1593 20.4728 6.2374 20.458 6.31335C20.4618 6.38791 20.4516 6.46252 20.428 6.53335Z"
        fill={fill}
      />
    </svg>
  );
});
