import { useTeamsLeaderboard } from '@/features/teams/api/use-teams-leaderboard';
import { useLeaderboardAroundUser } from '@/hooks/use-leaderboard-around-user';
import { usePartnerGroups } from '@/hooks/use-partner-groups';
import { useUserAccount } from '@/hooks/use-user-account';
import { LeaderboardType } from '@/types/leaderboard';

export const useP2PGameOver = () => {
  const { userAccount } = useUserAccount();
  const { partnerGroups } = usePartnerGroups();
  const { data: leaderboardAroundUser } = useLeaderboardAroundUser({
    leaderboardId: LeaderboardType.COMMUNITY_REPRESENTATION,
  });

  // We need to figure out which leaderboard to show in the game over screen.
  // If the user already has a score set, we use the metadata from that leaderboard record.
  // Else, we check their user metadata for the community
  // If neither of those are set, then we return isNotPartOfCommunity as true
  const leaderboardRecord = leaderboardAroundUser?.find((player) => player.owner_id === userAccount?.id);
  const communityId = leaderboardRecord?.metadata.tgPartnerGroupId || userAccount?.community?.id || '';

  const { records } = useTeamsLeaderboard();

  return {
    community: partnerGroups?.find((group) => group.telegramId === communityId.toString()),
    totalScore: leaderboardRecord?.score,
    teamLeaderboards: records,
    partnerGroups,
  };
};
