import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNakama } from '../../contexts/NakamaProvider';
import { equippedItemsKey, InventoryCategories, inventoryCollection } from '../../utils/constants';
import { Analytics } from '../../utils/analytics';
import { EquippedItems, OwnedItems, ProfileInventory } from '@/types/inventory';
import { EconomyList, EconomyListStoreItem } from '@metatheoryinc/hiro-js';
import { Cost, Currency } from '@/types/cost';

export const equippedItemsDefault: EquippedItems = {
  [InventoryCategories.Costumes]: '',
  [InventoryCategories.Environments]: '',
};

const profileInventoryDefault: ProfileInventory = {
  [InventoryCategories.Costumes]: {},
  [InventoryCategories.Environments]: {},
};

export function useInventory() {
  const { client, session, hiroClient, satoriClient, satoriSession } = useNakama();
  const queryClient = useQueryClient();

  const {
    data: equippedItems,
    isFetched: hasFetchedEquippedItems,
    isLoading: isLoadingEquippedItems,
    refetch: refetchEquippedItems,
  } = useQuery(
    'equippedItems',
    async () => {
      let equippedItems: EquippedItems = equippedItemsDefault;
      if (client && session) {
        const equippedItemResponse = await client.readStorageObjects(session, {
          object_ids: [
            {
              collection: inventoryCollection,
              key: equippedItemsKey,
              user_id: session.user_id,
            },
          ],
        });

        if ((equippedItemResponse?.objects?.length || 0) > 0) {
          const response = equippedItemResponse.objects[0].value as {
            items: EquippedItems;
          };
          equippedItems = response.items;
        }
      }
      return equippedItems;
    },
    {
      refetchOnMount: false,
      enabled: !!session,
    }
  );

  const { mutate: changeEquippedItem, isLoading: isChangingEquippedItem } = useMutation(
    async ({ itemId, category }: { itemId: string; category: InventoryCategories }) => {
      if (!client || !session || !equippedItems) return;

      if (!userOwnsItem(itemId)) {
        console.error('User does not own item', itemId);
        return;
      }

      const value = {
        items: {
          ...equippedItems,
          [category]: itemId,
        },
      };

      await client.writeStorageObjects(session, [
        {
          collection: inventoryCollection,
          key: equippedItemsKey,
          value,
        },
      ]);

      return value.items;
    },
    {
      onSuccess: () => {
        // Refetch equipped items after successful mutation
        refetchEquippedItems();
      },
    }
  );

  const { data: storeItems } = useQuery(
    'storeItems',
    async () => {
      if (session && hiroClient) {
        return await hiroClient.economyStoreGet(session, {});
      }
    },
    { refetchOnMount: true }
  );

  const { data: userInventory, refetch: refetchUserInventory } = useQuery(
    'userInventory',
    async () => {
      if (session && hiroClient) {
        const userInventory = await hiroClient.inventoryListInventory(session, {});

        if (!userInventory.items) return {};
        const parsedInventory: OwnedItems = {};
        const inventory = userInventory.items;

        for (const item of Object.values(inventory)) {
          if (item.id) {
            parsedInventory[item.id] = item;
          }
        }

        return parsedInventory;
      }
    },
    {}
  );

  async function userOwnsItem(itemId: string) {
    if (!client || !session || !hiroClient) return false;

    const userInventory = await hiroClient.inventoryListInventory(session, {});
    for (const item of Object.values(userInventory.items || {})) {
      if (item.id === itemId) {
        return true;
      }
    }

    return false;
  }

  const { mutateAsync: purchaseItem } = useMutation(
    async ({ itemId, cost }: { itemId: string; cost?: number }) => {
      if (!session || !hiroClient) {
        return;
      }

      if (await userOwnsItem(itemId)) {
        console.log('user already owns item');
        return;
      }

      await hiroClient.economyPurchaseItem(session, { item_id: itemId });

      Analytics.recordImpression('store-purchase-item', {
        itemId,
        cost: cost?.toString() || '',
      });

      try {
        if (satoriClient && satoriSession) {
          await satoriClient.event(satoriSession, {
            name: 'costumePurchase',
            value: itemId,
            metadata: {
              cost: cost?.toString() || '',
            },
          });
        }
      } catch {
        console.log('failed to record event');
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  function makeProfileInventory(
    storeItems?: EconomyList,
    ownedItems?: OwnedItems,
    fetchedEquippedItems?: EquippedItems
  ) {
    const profileInventory: ProfileInventory = profileInventoryDefault;
    if (ownedItems) {
      // Add owned items that are not in the store
      for (const item of Object.values(ownedItems)) {
        if (!item.id || !item.name || !(item.category ?? '' in InventoryCategories)) continue;
        const category = item.category as InventoryCategories;
        profileInventory[category][item.id] = {
          id: item.id,
          instanceId: item.instance_id,
          name: item.name,
          category: category,
          owned: true,
          equipped: fetchedEquippedItems ? item.id === fetchedEquippedItems[category] : false,
          description: item.description,
        };
      }

      const getCost = (item: EconomyListStoreItem) => {
        if (item.cost?.currencies?.points) {
          return {
            amount: Number(item.cost?.currencies?.points),
            currency: Currency.Paws,
          } as Cost;
        }
        if (item.cost?.currencies?.xtr) {
          return {
            amount: Number(item.cost?.currencies?.xtr),
            currency: Currency.Xtr,
          } as Cost;
        }
        return undefined;
      };

      if (storeItems) {
        // Add store items
        for (const item of storeItems.store_items ?? []) {
          if (!item.id || !(item.category ?? '' in InventoryCategories)) continue;
          const category = item.category as InventoryCategories;
          if (!profileInventory[category][item.id]) {
            profileInventory[category][item.id] = {
              id: item.id,
              name: item.name ?? '',
              category: category,
              owned: !!ownedItems[item.id],
              equipped: fetchedEquippedItems ? item.id === fetchedEquippedItems[category] : false,
              description: item.description,
              cost: ownedItems[item.id] ? undefined : !ownedItems[item.id] ? getCost(item) : undefined,
            };
          }
        }
      }
    }

    return profileInventory;
  }

  return {
    equippedItems,
    hasFetchedEquippedItems,
    isLoadingEquippedItems,
    refetchEquippedItems,
    changeEquippedItem,
    isChangingEquippedItem,
    storeItems,
    userInventory,
    refetchUserInventory,
    purchaseItem,
    profileInventory: makeProfileInventory(storeItems, userInventory, equippedItems),
  };
}
