import { Container } from 'pixi.js';
import { Particle, ParticleType } from './particle';

export class Particles extends Container {
  active: Particle[];
  reserve: Particle[];

  // for wow text
  randAngles: number[] = [];

  constructor() {
    super();
    this.active = [];
    this.reserve = [];

    const l = 8;
    const step = (Math.PI * 2) / l;
    for (let i = 0; i < l; i++) {
      this.randAngles.push(i * step);
    }
  }

  public addParticles(x: number, y: number, type: ParticleType, count: number): void {
    for (let i = 0; i < count; i++) {
      const particle: Particle = this.reserve.pop() ?? new Particle();
      particle.init(type, x, y);
      this.addChild(particle);
      this.active.push(particle);
    }
  }

  public addFloatText(x: number, y: number, text: string): void {
    const particle: Particle = this.reserve.pop() ?? new Particle();
    particle.init(ParticleType.FloatText, x, y, text);
    this.addChild(particle);
    this.active.push(particle);
  }

  public addPopText(x: number, y: number, text: string): void {
    const particle: Particle = this.reserve.pop() ?? new Particle();
    particle.init(ParticleType.PopText, x, y, text);
    this.addChild(particle);
    this.active.push(particle);
  }

  public addWowText(x: number, y: number, callouts: string[]): void {
    shuffle(this.randAngles);
    const numCallouts = 4;
    for (let i = 0; i < numCallouts; i++) {
      const particle: Particle = this.reserve.pop() ?? new Particle();
      const rand = Math.floor(Math.random() * callouts.length);
      const posX = x + Math.cos(this.randAngles[i]) * 200;
      const posY = y + Math.sin(this.randAngles[i]) * 200;
      particle.init(ParticleType.WowText, posX, posY, callouts[rand]);
      this.addChild(particle);
      this.active.push(particle);
    }
  }

  public update(dt: number): void {
    let l = this.active.length;
    for (let i = 0; i < l; i++) {
      const particle = this.active[i];
      particle.update(dt);

      if (particle.lifetime <= 0) {
        // Move particle back into the reserve pool
        const temp = this.active[l - 1];
        this.active[l - 1] = particle;
        this.active[i] = temp;
        l--;
        i--;
        this.active.pop();
        this.removeChild(particle);
        this.reserve.push(particle);
      }
    }
  }

  public clear(): void {
    this.active.forEach((particle) => {
      particle.deInit();
    });
    this.reserve.push(...this.active);
    this.active.length = 0;
  }
}

// Fisher-Yates shuffle
function shuffle(arr: number[]) {
  let index = arr.length;

  while (index != 0) {
    const rand = Math.floor(Math.random() * index);
    index--;
    [arr[index], arr[rand]] = [arr[rand], arr[index]];
  }
}
