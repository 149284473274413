import { InitializedNakamaClients } from '@/lib/arcade-sdk/loaders/nakama-loader';
import { AppProvider } from './provider';
import { AppRouter } from './router';

type AppProps = {
  nakamaClients: InitializedNakamaClients;
};

export const App = ({ nakamaClients }: AppProps) => {
  return (
    <AppProvider nakamaClients={nakamaClients}>
      <AppRouter />
    </AppProvider>
  );
};
