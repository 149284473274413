import { clientVersion } from '@/version';

export function getTelegramVerificationHeaders(): Record<string, string> {
  const tg = window.Telegram.WebApp;
  /**
   *
   * Server will need to validate the contents of initData
   * https://core.telegram.org/bots/webapps#validating-data-received-via-the-mini-app
   */
  return tg ? { 'init-data': tg.initData } : {};
}

export function getVersionHeaders(): Record<string, string> {
  return {
    'x-client-version': clientVersion,
  };
}

export function getApiHeaders(): Record<string, string> {
  return {
    ...getTelegramVerificationHeaders(),
    ...getVersionHeaders(),
  };
}
