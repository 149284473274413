import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text/Text';
import styles from '@/styles/error.module.scss';
import cat from '@/assets/game-over-cat.png';
import { root } from '../../app/routes';

export const ErrorFallback = () => {
  return (
    <div className={styles.errorPage}>
      <img src={cat} className={styles.cat} />
      <div className={styles.errorContainer}>
        <Text as={'h1'} type={'heading'} size="xl" className={styles.heading}>
          Oops! Loading Error.
        </Text>
        <Text as={'p'} type={'body'} size="s" className={styles.body}>
          It looks like GimmeCat ran into a problem while loading. Please check your connection and try reloading the
          page.
        </Text>
        <Button
          primary
          onClick={() => {
            window.location.href = root.home.path;
          }}
          className={styles.retry}
        >
          <Text as="span" type="heading">
            Retry
          </Text>
        </Button>
      </div>
    </div>
  );
};
