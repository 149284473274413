import { sdk } from '@/gimmecat';
import { TeamLeaderboardRecordsDto } from './types';

const GET_TEAMS_LEADERBOARD_RPC = 'rpc_list_community_leaderboard_records';

export const getTeamsLeaderboard = async (options: { pastCycle?: number }) => {
  if (!sdk.nakamaClients) {
    throw new Error('getTeamsLeaderboard called before nakama clients initialized');
  }
  const { nakamaClient, nakamaSession } = sdk.nakamaClients;

  const response = await nakamaClient.rpc(nakamaSession, GET_TEAMS_LEADERBOARD_RPC, {
    pastCycle: options.pastCycle,
  });

  return TeamLeaderboardRecordsDto.parse(response.payload);
};
