import { useId } from 'react';

export const InfoIcon = ({ fill = '#342236' }: { fill?: string }) => {
  const id = useId();
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7ZM7.875 3.5C7.875 3.73206 7.78281 3.95462 7.61872 4.11872C7.45462 4.28281 7.23206 4.375 7 4.375C6.76794 4.375 6.54538 4.28281 6.38128 4.11872C6.21719 3.95462 6.125 3.73206 6.125 3.5C6.125 3.26794 6.21719 3.04538 6.38128 2.88128C6.54538 2.71719 6.76794 2.625 7 2.625C7.23206 2.625 7.45462 2.71719 7.61872 2.88128C7.78281 3.04538 7.875 3.26794 7.875 3.5ZM6.125 6.125C5.95095 6.125 5.78403 6.19414 5.66096 6.31721C5.53789 6.44028 5.46875 6.6072 5.46875 6.78125C5.46875 6.9553 5.53789 7.12222 5.66096 7.24529C5.78403 7.36836 5.95095 7.4375 6.125 7.4375H6.34638C6.37911 7.43751 6.41142 7.44487 6.44093 7.45903C6.47044 7.47319 6.4964 7.4938 6.51688 7.51932C6.53737 7.54485 6.55187 7.57465 6.55931 7.60653C6.56675 7.6384 6.56695 7.67154 6.55987 7.7035L6.15825 9.51125C6.10844 9.73516 6.10957 9.9674 6.16156 10.1908C6.21356 10.4142 6.31508 10.6231 6.45863 10.802C6.60218 10.9809 6.78409 11.1253 6.99093 11.2245C7.19777 11.3237 7.42424 11.3751 7.65362 11.375H7.875C8.04905 11.375 8.21597 11.3059 8.33904 11.1828C8.46211 11.0597 8.53125 10.8928 8.53125 10.7188C8.53125 10.5447 8.46211 10.3778 8.33904 10.2547C8.21597 10.1316 8.04905 10.0625 7.875 10.0625H7.65362C7.62089 10.0625 7.58858 10.0551 7.55907 10.041C7.52956 10.0268 7.5036 10.0062 7.48312 9.98068C7.46263 9.95515 7.44813 9.92535 7.44069 9.89347C7.43325 9.8616 7.43305 9.82846 7.44013 9.7965L7.84175 7.98875C7.89156 7.76484 7.89043 7.5326 7.83844 7.30918C7.78644 7.08577 7.68492 6.87689 7.54137 6.69798C7.39782 6.51906 7.21591 6.37469 7.00907 6.27551C6.80223 6.17634 6.57576 6.1249 6.34638 6.125H6.125Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
