import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, { LocizeBackendOptions } from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';

const tg: WebApp | undefined = window.Telegram.WebApp;

const locizeOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECTID,
  apiKey: import.meta.env.VITE_LOCIZE_APIKEY,
  version: 'latest',
  referenceLng: 'en',
  ...(!import.meta.env.PROD && {
    allowedAddOrUpdateHosts: ['localhost', `${import.meta.env.VITE_NGROK_SUBDOMAIN}.ngrok.io`],
  }),
};

i18n
  .use(LastUsed)
  .use(Backend)
  //   .use(LanguageDetector)
  .use(initReactI18next)
  .init<LocizeBackendOptions>({
    // debug: true,
    lng: tg.initDataUnsafe.user?.language_code,
    fallbackLng: 'en',
    ns: ['frontend'],
    defaultNS: 'frontend',
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    saveMissing: !import.meta.env.PROD,
  });

export default i18n;
