import { Assets, Sprite, Texture } from 'pixi.js';
import { PlayerSkin } from './playerSkin';
import gsap from 'gsap';
import { radToDeg } from '../utils';

export class playerSkinSprite extends PlayerSkin {
  textures: Texture[];
  sprite: Sprite;
  animTimer: number = 0;
  loadingCostume: boolean = false;
  frameReset?: gsap.core.Tween;

  constructor() {
    super();
    this.chargeScaleVal.set(1.2, 0.75);

    this.textures = [];
    this.sprite = new Sprite();
  }

  public initSprite() {
    this.sprite = new Sprite(this.textures[0]);
    this.sprite.anchor.set(0.5, 0.66);
    this.addChild(this.sprite);
  }

  public loadSkin(url: string): void {
    const loadNewSkin = async () => {
      this.loadingCostume = true;
      try {
        const newTextures = [];
        newTextures.push(await Assets.load(url + 'idle.png'));
        newTextures.push(await Assets.load(url + 'crouch.png'));
        newTextures.push(await Assets.load(url + 'jump.png'));
        try {
          newTextures.push(await Assets.load(url + 'idleB.png'));
        } catch {
          // no extra idle frame found
        }

        gsap.killTweensOf(this.sprite);
        this.removeChild(this.sprite);
        this.sprite.destroy();

        this.textures.length = 0;
        newTextures.forEach((texture) => {
          this.textures.push(texture);
          texture.source.autoGenerateMipmaps = true;
        });
        this.initSprite();
      } catch (e) {
        if (e instanceof Error) console.log('error loading skin: ' + e.message);
      }
      this.loadingCostume = false;
    };
    if (!this.loadingCostume) loadNewSkin();
  }

  public reset(): void {
    super.reset();
    this.sprite.x = 0;
    this.sprite.y = 0;
    this.sprite.scale.set(1);
    this.animTimer = 0;
  }

  public update(dt: number): void {
    // subtle breathing animation
    this.animTimer += dt * 4;
    this.sprite.scale.y = 0.96 + Math.sin(this.animTimer) * 0.04;

    // if extra idle frame exists, display it after 2 seconds
    if (this.sprite.texture === this.textures[0] && this.textures[3] && this.animTimer > 8) {
      this.sprite.texture = this.textures[3];
    }
  }

  public animateCharge(chargeDuration: number): void {
    this.sprite.scale.y = 1;
    super.animateCharge(chargeDuration);

    if (this.frameReset) {
      this.frameReset.kill();
      this.frameReset = undefined;
    }
    this.sprite.texture = this.textures[1];
  }

  public animateJump(jumpDuration: number): void {
    super.animateJump(jumpDuration);
    this.sprite.texture = this.textures[2];

    this.sprite.rotation = -Math.PI * 0.1;
    gsap.to(this.sprite, {
      pixi: { rotation: radToDeg(Math.PI * 0.3) },
      duration: jumpDuration,
      ease: 'none',
    });

    // sprite y position compensation
    gsap.to(this.sprite, {
      duration: jumpDuration,
      y: 35,
      ease: 'sine.in',
    });
  }

  public animateCancel(): void {
    super.animateCancel();
    this.sprite.texture = this.textures[0];
    this.animTimer = 0;
  }

  public animateLand(): void {
    super.animateLand();
    this.sprite.texture = this.textures[1];
    this.sprite.rotation = 0;
    this.animTimer = 0;

    gsap.to(this.sprite, {
      duration: 0.05,
      y: 0,
      ease: 'sine.out',
    });

    this.frameReset = gsap.delayedCall(0.15, () => {
      this.sprite.texture = this.textures[0];
    });
  }
}
