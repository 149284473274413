import { restApiBase } from './utils/api';
import { InvoiceSchema } from './types/invoice';
import { authenticatedApi } from '@/lib/api';

export const createInvoice = async (itemId: string): Promise<string> => {
  const response = await authenticatedApi.post(`${restApiBase}/order`, {
    itemId,
  });
  return InvoiceSchema.parse(response.data).invoiceUrl;
};
