import { z } from 'zod';
import { sdk } from '@/gimmecat';
import { UserDto } from './types';

export const getUserAccount = async (): Promise<z.infer<typeof UserDto>> => {
  if (!sdk.nakamaClients) {
    throw new Error('getUserAccount called before nakama clients initialized');
  }

  const { nakamaClient, nakamaSession } = sdk.nakamaClients;

  const response = await nakamaClient.getAccount(nakamaSession);

  if (!response.user) {
    throw new Error('User not found');
  }

  return UserDto.parse(response.user);
};
