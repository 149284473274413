import { initializeGame, loadPixiAssets } from './game-raw/StartGame';
import { Arcade } from './lib/arcade-sdk';
import { InitializedNakamaClients } from './lib/arcade-sdk/loaders/nakama-loader';
import { GimmecatBrandingConfig } from '@/types/satori/GimmecatBrandingConfig.gen';
import { gameStore } from './store/gameStore';
import { BrandManager } from './game-raw/brandManager';
import { getPartnerGroupsQueryOptions } from './hooks/use-partner-groups';
import { queryClient } from './api/utils/query-client';
import { GimmecatCommunityBoxDefinitions } from './types/satori/GimmecatCommunityBoxDefinitions.gen';
import { GetPartnerGroupsResponseDto } from './api/domains/groups/types';
import {
  getRecordsWithPartners,
  getRecordsWithPartnersAndBrands,
} from './api/domains/leaderboards/community-leaderboard-utils';
import { computeBrandProbabilityTable } from './api/domains/brands/brand-utils';
import { TeamLeaderboardRecordsDto } from './api/domains/teams/types';
import { getTeamsLeaderboard } from './api/domains/teams/get-teams-leaderboard';

export enum GimmeCatFlag {
  playToPromoteP0 = 'play-to-promote-p0',
  gimmecatBrandingConfig = 'gimmecat-branding-config',
  gimmecatCommunityBoxDefinitions = 'gimmecat-community-box-definitions',
}

async function loadAndInitializeGame(onProgress: (progress: number) => void) {
  // Load PIXI Assets
  await loadPixiAssets(onProgress);
  // Initialize PIXI Application
  await initializeGame();
}

// FIXME(yoh): move this flag getter into the sdk flags getter, and modify that to allow non-boolean schemas
async function getFlag(nakamaPromise: Promise<InitializedNakamaClients>, flagName: GimmeCatFlag) {
  try {
    const { satoriClient, satoriSession } = await nakamaPromise;
    const { flags } = await satoriClient.getFlags(satoriSession, [flagName]);
    if (!flags || !flags[0] || !flags[0].value) {
      throw new Error(`failed to fetch flag ${flagName}`);
    }
    return JSON.parse(flags[0].value);
  } catch (e) {
    console.error(e);
    throw new Error(`something went wrong fetching flag ${flagName}`);
  }
}

async function loadBrandingConfig(nakamaPromise: Promise<InitializedNakamaClients>) {
  try {
    const store = gameStore.getState();
    const playToPromote: boolean = await getFlag(nakamaPromise, GimmeCatFlag.playToPromoteP0);

    if (playToPromote) {
      const [boxDefs, winnerRecords, partners] = await Promise.all([
        getCommunityBoxDefinitions(nakamaPromise),
        getTeamsLeaderboard({ pastCycle: 1 }),
        queryClient.fetchQuery(getPartnerGroupsQueryOptions()),
      ]);
      const brandingConfig = computeBrandingConfig(boxDefs, winnerRecords, partners);
      store.setBrandManager(new BrandManager(brandingConfig));
    } else {
      const legacyBrandingConfig = await getLegacyBrandingConfig(nakamaPromise);
      store.setBrandManager(new BrandManager(legacyBrandingConfig));
    }
  } catch (e) {
    console.error(e);
    throw new Error(`something went wrong fetching branding config`);
  }
}

function computeBrandingConfig(
  boxDefs: GimmecatCommunityBoxDefinitions,
  winnerRecords: TeamLeaderboardRecordsDto,
  partners: GetPartnerGroupsResponseDto
): GimmecatBrandingConfig {
  /**
   *
   * minimumGapBetweenBrandedBlocks maps from Community Box Definitions
   */
  const minimumGapBetweenBrandedBlocks = boxDefs.minimumGapBetweenBrandedBlocks;

  /**
   *
   * brandingDefinitions maps from Community Box Definitions
   */
  const brandingDefinitions = boxDefs.brandingDefinitions.map((def) => {
    return {
      brandingDefinitionId: def.ownerCommunityId,
      darkColor: def.darkColor,
      outlineColor: def.outlineColor,
      sideColor: def.sideColor,
      topColor: def.topColor,
      topImageUrl: def.topImageUrl,
      sideImageUrl: def.sideImageUrl,
    };
  });

  /**
   *
   * probability table is computed
   */
  const records = 'records' in winnerRecords ? winnerRecords.records : [];
  const recordsWithPartners = getRecordsWithPartners(records, partners);
  const recordsWithPartnersAndBrands = getRecordsWithPartnersAndBrands(
    recordsWithPartners,
    boxDefs.brandingDefinitions
  );
  const computedProbabilityTable = computeBrandProbabilityTable(recordsWithPartnersAndBrands);

  /**
   *
   * log debug information to the console
   */
  console.group('Play To Promote Calculations');
  console.log(`%cYesterday's Winning Communities`, 'font-size: 20px; font-weight: bold;');
  console.table(
    recordsWithPartnersAndBrands.map((row) => {
      return {
        rank: row.record.rank,
        score: row.record.score,
        telegramGroupId: row.record.tg_partner_group_id,
        communityId: row.partner ? row.partner.metadata.communityId : 'MISSING',
        brandingDefinitionId: row.brand ? row.brand.ownerCommunityId : 'MISSING',
      };
    })
  );

  console.log(`%cComputed Brand Frequency Table`, 'font-size: 20px; font-weight: bold;');
  console.table(
    computedProbabilityTable.map((row) => {
      return {
        brandingDefinitionId: row.brandingDefinitionId,
        relativeStrength: row.percentage,
      };
    })
  );
  console.groupEnd();

  return {
    minimumGapBetweenBrandedBlocks,
    brandingDefinitions,
    brandingFrequencyTable: computedProbabilityTable,
  };
}

async function getCommunityBoxDefinitions(nakamaPromise: Promise<InitializedNakamaClients>) {
  try {
    const brandingConfig = await getFlag(nakamaPromise, GimmeCatFlag.gimmecatCommunityBoxDefinitions);
    return GimmecatCommunityBoxDefinitions.parse(brandingConfig);
  } catch (e) {
    console.error(e);
    throw new Error(`something went wrong fetching flag gimmecat-community-box-definitions`);
  }
}

async function getLegacyBrandingConfig(nakamaPromise: Promise<InitializedNakamaClients>) {
  try {
    const brandingConfig = await getFlag(nakamaPromise, GimmeCatFlag.gimmecatBrandingConfig);
    return GimmecatBrandingConfig.parse(brandingConfig);
  } catch (e) {
    console.error(e);
    throw new Error(`something went wrong fetching flag gimmecat-branding-config`);
  }
}

export interface GimmeCatGameType {
  GameDataType: void;
  ProgressType: number;
}

export const sdk = new Arcade.Game<GimmeCatGameType>({
  name: 'GimmeCat',
  dataLoader: async (nakamaPromise, onProgress) => {
    const [_, _brandingConfig] = await Promise.all([
      loadAndInitializeGame(onProgress),
      loadBrandingConfig(nakamaPromise),
    ]);
  },
});
