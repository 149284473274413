import React from 'react';

type Props = {
  fill?: string;
};

export const TrophyIcon: React.FC<Props> = React.memo(({ fill = '#202025' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        fill={fill}
        d="M21 4h-3V3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v1H2.998a1 1 0 0 0-1 1v3c0 4.31 1.8 6.91 4.82 7A6 6 0 0 0 11 17.91V20H9v2h6v-2h-2v-2.09A5.998 5.998 0 0 0 17.18 15c3-.1 4.82-2.7 4.82-7V5a1 1 0 0 0-1-1ZM4 8V6h2v6.83C4.22 12.08 3.998 9.3 3.998 8Zm14 4.83V6h2v2c0 1.3-.22 4.08-2 4.83Z"
      />
    </svg>
  );
});
