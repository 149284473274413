import { UserTelemetry } from './types/user-telemetry';
import { restApiBase } from './utils/api';
import { authenticatedApi } from '@/lib/api';

export const postUserTelemetry = async (telemetry: UserTelemetry): Promise<void> => {
  try {
    await authenticatedApi.post(`${restApiBase}/telemetry`, {
      telemetry,
    });
  } catch (_error) {
    throw new Error('Error sending user telemetry data');
  }
};
