export function toIso(x: number, y: number, z: number): { x: number; y: number } {
  const iso_x = x - y;
  const iso_y = (x + y) * 0.5 + z * 1.23;
  return { x: iso_x, y: iso_y };
}

export function toIsoX(x: number, y: number, _z: number): number {
  const iso_x = x - y;
  //const iso_y = (x + y) * 0.5 + z * 1.23;
  return iso_x;
}

export function toIsoY(x: number, y: number, z: number): number {
  //const iso_x = x - y;
  const iso_y = (x + y) * 0.5 + z * 1.23;
  return iso_y;
}

export function distance(x1: number, y1: number, x2: number, y2: number): number {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.sqrt(dx * dx + dy * dy);
}

export function clamp(val: number, min: number, max: number): number {
  return val < min ? min : val > max ? max : val;
}

export function randomRange(min: number, max: number): number {
  return min + Math.random() * (max - min);
}

export function randomRangeInt(min: number, max: number): number {
  return Math.floor(min + Math.random() * (max + 1 - min));
}

export function randomColor(brightness: number): string {
  brightness *= 255;
  function randomChannel(brightness: number) {
    const r = 255 - brightness;
    const n = 0 | (Math.random() * r + brightness);
    const s = n.toString(16);
    return s.length == 1 ? '0' + s : s;
  }
  return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness);
}

export function formatTime(time: number): string {
  // const hrs = Math.floor(time / 60 / 60);
  const mins = Math.floor((time / 60) % 60);
  const secs = Math.floor(time % 60);
  const ms = Math.floor((time % 1) * 100);
  const timeString = mins > 0 ? `${mins}:${format(secs)}:${format(ms)}` : `${format(secs)}:${format(ms)}`;

  function format(x: number) {
    if (x < 10) return '0' + x.toString();
    else return x.toString();
  }

  return timeString;
}

export function radToDeg(val: number): number {
  return (val / Math.PI) * 180;
}

export function assertNever(req: never) {
  throw new Error(`Block Type not implemented: ${req}`);
}

export function weightedRandom(weights: number[]): number {
  const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
  const randomValue = Math.random() * totalWeight;
  let cumulative = 0;
  for (let i = 0; i < weights.length; i++) {
    cumulative += weights[i];
    if (randomValue <= cumulative) {
      return i;
    }
  }
  return -1;
}
