import { sdk } from '@/gimmecat';
import { TournamentListRpcPayloadZod } from './types';
import { z } from 'zod';
import { EventPeriod } from '@/types/event';

const GET_EVENT_LIST_RPC = 'rpc_get_tournaments';
const CONTEST_CATEGORY = 1;

export const getEvents = async (period: EventPeriod): Promise<z.infer<typeof TournamentListRpcPayloadZod>> => {
  if (!sdk.nakamaClients) {
    throw new Error('getEvents called before nakama clients initialized');
  }

  const { nakamaClient, nakamaSession } = sdk.nakamaClients;

  const tournament = await nakamaClient.rpc(nakamaSession, GET_EVENT_LIST_RPC, { period, category: CONTEST_CATEGORY });

  return TournamentListRpcPayloadZod.parse(tournament.payload);
};
