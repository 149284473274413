import { useState } from 'react';
import { ArrowUp } from '../Icons/ArrowUp';
import styles from './Accordion.module.scss';
import cls from 'classnames';

type AccordionProps = {
  header: React.ReactNode;
  content: React.ReactNode;
};

export function Accordion({ header, content }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.accordion}>
      <div onClick={() => setIsOpen(!isOpen)} className={styles.header}>
        {header}
        <div className={cls(styles.chevron, isOpen ? styles.open : styles.close)}>
          <ArrowUp />
        </div>
      </div>
      {isOpen && <div className={styles.content}>{content}</div>}
    </div>
  );
}
