import { useEffect, useRef } from 'react';
import { Analytics } from '../../utils/analytics';

export const usePageViewTracking = (pageName: string) => {
  const lastPageRef = useRef<string | null>(null);

  useEffect(() => {
    if (pageName !== lastPageRef.current) {
      Analytics.recordPageView(pageName);
      lastPageRef.current = pageName;
    }
  }, [pageName]);

  return lastPageRef.current;
};
