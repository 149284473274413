import { gsap } from 'gsap';
import { ShakingBlock } from './shakingBlock';
import { BlockShape } from '../engine/block-model';

export class BridgeBlock extends ShakingBlock {
  constructor() {
    super();
    this.chargeEasing = 'power2.out';
  }

  public init(x: number, y: number, _z: number, size: number, shape: BlockShape): void {
    this.size = size;
    this.shape = shape;
    this.timer = 0;
    this.shakeSpeed = 16;
    this.fallTimer = 1.0;
    this.deltaZ = 0;
    this.playerLanded = false;
    this.isFalling = false;
    this.blockSize.y = this.zHeight = 20;
    this.setPosition(x, y, -80);
    this.startPos.set(x, y);

    this.blockSize.x = size;
    this.blockSize.y = this.zHeight;

    this.redraw();
  }

  public chargeJump(maxHoldTime: number): void {
    gsap.to(this, {
      isoZ: -30,
      duration: maxHoldTime * 0.001,
      ease: 'power2.out',
      onUpdate: () => {
        this.updatePosition();
      },
    });
  }

  public cancelJump(): void {
    gsap.to(this, {
      duration: 0.1,
      isoZ: -80,
      ease: 'back.out',
      overwrite: true,
      onUpdate: () => {
        this.updatePosition();
      },
    });
  }

  public receivePlayer(): void {
    gsap.to(this, {
      isoZ: -70,
      duration: 0.05,
      repeat: 1,
      yoyo: true,
      ease: 'sine.out',
      onUpdate: () => {
        this.updatePosition();
      },
    });
    this.startShaking();
  }

  public sendOffPlayer(): void {
    gsap.killTweensOf(this);
    gsap.to(this, {
      isoZ: -80,
      duration: 0.25,
      ease: 'elastic.out',
      onUpdate: () => {
        this.updatePosition();
      },
    });

    this.fallTimer = 0.05 + Math.random() * 0.05;
  }
}
