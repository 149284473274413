import { sdk } from '@/gimmecat';
import { GetPartnerGroupsResponseDto } from './types';
import { z } from 'zod';

const GET_GROUPS_RPC = 'rpc_groups_list';

export const getPartnerGroups = async (): Promise<z.infer<typeof GetPartnerGroupsResponseDto>> => {
  if (!sdk.nakamaClients) {
    throw new Error('getPartnerGroups called before nakama clients initialized');
  }

  const { nakamaClient, nakamaSession } = sdk.nakamaClients;

  const response = await nakamaClient.rpc(nakamaSession, GET_GROUPS_RPC, {
    isPartner: true,
    isActive: true,
  });

  if (!response.payload) {
    return GetPartnerGroupsResponseDto.parse([]);
  }

  return GetPartnerGroupsResponseDto.parse(response.payload);
};
