export enum JUMP_RATING {
  PURRFECT = 4,
  GREAT = 3,
  GOOD = 2,
  OK = 1,
  ADEQUATE = 0,
}

type JumpPerformance = {
  targetX: number;
  targetY: number;
  actualX: number;
  actualY: number;
};

export class JumpJudger {
  public static judgeJump(performance: JumpPerformance): JUMP_RATING {
    const distance = this.computeDistance(
      performance.actualX,
      performance.actualY,
      performance.targetX,
      performance.targetY
    );
    if (distance <= 10) return JUMP_RATING.PURRFECT;
    else if (distance <= 25) return JUMP_RATING.GREAT;
    else if (distance <= 50) return JUMP_RATING.GOOD;
    else if (distance <= 70) return JUMP_RATING.OK;
    else return JUMP_RATING.ADEQUATE;
  }

  private static computeDistance(x1: number, y1: number, x2: number, y2: number): number {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy);
  }
}
