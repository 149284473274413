import { createPortal } from 'react-dom';
import { Button } from '@/components/Button/Button';
import { CloseIcon } from '@/components/Icons/CloseIcon';
import styles from './ProfileSetUsername.module.scss';
import { useState } from 'react';
import { useNakama } from '@/contexts/NakamaProvider';
import { useToasts } from '@/contexts/ToastProvider';
import { Text } from '@/components/Text/Text';
import { useTranslation } from 'react-i18next';

type SetUsernameProps = {
  onClose: () => void;
  defaultValue: string;
};

export const ProfileSetUsername = ({ onClose, defaultValue }: SetUsernameProps) => {
  const [input, setInput] = useState<string>(defaultValue);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const { changeUsername } = useNakama();
  const { addToast } = useToasts();

  const updateUsernameAndClose = async (): Promise<void> => {
    await changeUsername(input).then(() => {
      addToast(t('Username updated.'), 'info', 'bottom');
    });
    onClose();
  };

  const handleOnKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (e.key === 'Enter') {
      await updateUsernameAndClose();
    }
  };

  return createPortal(
    <div className={styles.setUsernameContainer} onClick={onClose}>
      <div className={styles.setUsername} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <Text as="h3" type="heading" className={styles.title}>
            Choose a Username
          </Text>
          <Button onClick={onClose} className={styles.close}>
            <CloseIcon fill="#FFFFFF" />
          </Button>
        </div>

        <input
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            setError('');
          }}
          onBlur={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className={styles.input}
          onKeyDown={async (e) => handleOnKeyDown(e)}
        />
        {error && <p className={styles.error}>{error}</p>}
        <Button
          onClick={async () => {
            try {
              await updateUsernameAndClose();
            } catch (err) {
              if (err instanceof Error) {
                setError(err.message);
              }
            }
          }}
          className={styles.button}
          primary
          fullWidth
          disabled={!input || error !== '' || input === defaultValue}
        >
          <Text as="span" type="button">
            Save
          </Text>
        </Button>
      </div>
    </div>,
    document.body
  );
};
