import { sdk } from '@/gimmecat';
import { getRankFrom, getScoreFrom, LeaderboardItem, LeaderboardType } from '@/types/leaderboard';
import { getUserIds, transformLeaderboardRecord } from './leaderboard-utils';

export async function getLeaderboardData(leaderboardId: string = LeaderboardType.ALL, pageParam?: string) {
  if (!sdk.nakamaClients) return;
  const { nakamaClient: client, nakamaSession: session } = sdk.nakamaClients;
  const limit = 10;
  const cursor = pageParam ? pageParam : undefined;

  const result = await client.listLeaderboardRecords(session, leaderboardId, [session!.user_id!], limit, cursor);

  const userIds = getUserIds(result);

  const getUsersResponse = await client.getUsers(session, userIds);

  const leaderboardItems: LeaderboardItem[] =
    result.records?.map((record) => {
      return transformLeaderboardRecord(record, getUsersResponse.users || []);
    }) || [];

  return {
    playerResult: {
      score: getScoreFrom(result.owner_records?.[0]),
      position: getRankFrom(result.owner_records?.[0]),
    },
    results: leaderboardItems,
    nextCursor: result.next_cursor,
  };
}
