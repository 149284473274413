import { Block } from './block';
import { BridgeBlock } from './bridgeBlock';
import { MovingBlock } from './movingBlock';
import { ShakingBlock } from './shakingBlock';

class Pool<T extends Block> {
  objects: T[] = [];

  constructor(private factory: () => T) {}

  pop(): T {
    if (this.objects.length > 0) {
      return this.objects.pop()!;
    }
    return this.factory();
  }

  push(obj: T): void {
    this.objects.push(obj);
  }
}

export class BlockPools {
  normal: Pool<Block> = new Pool(() => {
    return new Block();
  });
  shaking: Pool<ShakingBlock> = new Pool(() => {
    return new ShakingBlock();
  });
  bridge: Pool<BridgeBlock> = new Pool(() => {
    return new BridgeBlock();
  });
  moving: Pool<MovingBlock> = new Pool(() => {
    return new MovingBlock();
  });
}
