import { EventPeriod } from '@/types/event';
import { useQuery } from 'react-query';
import { queryKeys } from '@/api/queryKeys';
import { getEvents } from '@/api/domains/events/get-events';

export const getEventListQueryOptions = (period: EventPeriod) => {
  return {
    queryKey: queryKeys.getEventList(period),
    queryFn: () => getEvents(period),
  };
};

export function useEventsList({ period }: { period: EventPeriod }) {
  const query = useQuery(getEventListQueryOptions(period));

  switch (query.status) {
    case 'success': {
      const { data } = query;

      const events = data.tournaments.map((item) => ({
        id: item.id,
        title: item.title,
        type: item.contest_type,
        endTime: new Date(item.end_time),
        organizers: item.metadata.communityIds,
        splashImage: item.metadata.splashImage,
        description: item.metadata.description,
        prize: {
          title: item.metadata.prizeTitle,
          description: item.metadata.prizeDescription,
        },
        startTime: new Date(item.start_time),
      }));

      return {
        events,
      };
    }
    case 'loading':
    case 'error':
    default:
      return {
        events: null,
      };
  }
}
