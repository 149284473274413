export const TelegramRoundIcon = ({ fill = '#fff' }: { fill?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
      <path
        fill={fill}
        d="M10 .925C4.825.925.625 5.125.625 10.3s4.2 9.375 9.375 9.375 9.375-4.2 9.375-9.375S15.175.925 10 .925ZM14.35 7.3c-.14 1.48-.75 5.08-1.06 6.74-.13.703-.393.938-.637.966-.544.047-.956-.356-1.481-.703-.825-.544-1.294-.882-2.09-1.407-.929-.609-.329-.946.205-1.49.141-.14 2.541-2.325 2.588-2.522a.189.189 0 0 0-.047-.169c-.056-.047-.131-.028-.197-.019-.084.02-1.397.891-3.956 2.616-.375.253-.712.384-1.013.375-.337-.01-.974-.187-1.453-.347-.59-.187-1.05-.29-1.012-.619.019-.168.253-.337.694-.515 2.737-1.19 4.556-1.978 5.465-2.353 2.607-1.088 3.14-1.275 3.497-1.275.075 0 .253.018.366.112a.373.373 0 0 1 .131.253c-.01.057.01.225 0 .357Z"
      />
    </svg>
  );
};
