import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useNakama } from './NakamaProvider';

import { setBgmVolume, setAllSoundVolume, initializeSound } from '../game-raw/StartGame';

import * as API from '../types/nakama-api';

type UserSettings = {
  soundVolume: number;
  musicVolume: number;
};

type SettingsContextType = {
  settings: UserSettings;
  setMusicVolume: (volume: number) => void;
  setSoundVolume: (volume: number) => void;
  isSettingReady: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isUserSettings = (obj: any): obj is API.UserSettingsPayload => {
  return (
    Object.prototype.hasOwnProperty.call(obj, 'sound_volume') &&
    Object.prototype.hasOwnProperty.call(obj, 'music_volume')
  );
};

// We store the volume as a number between 0 and 100, but the game expects a
// value between 0 and 1
const convertToGameVolume = (volume: number) => {
  return volume / 100;
};

const SettingsContext = createContext<SettingsContextType>({
  settings: {
    soundVolume: 50,
    musicVolume: 50,
  },
  setMusicVolume: () => {},
  setSoundVolume: () => {},
  isSettingReady: false,
});

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const { client, session, isReady } = useNakama();
  const [settings, setSettings] = useState<UserSettings>({
    soundVolume: 50,
    musicVolume: 50,
  });
  const [isSettingReady, setIsSettingsReady] = useState(false);

  const fetchUserSettings = useCallback(async () => {
    if (!client || !session) {
      return;
    }

    const response = await client.rpc(session, 'rpc_get_user_settings', {});
    const settings = response.payload;

    if (!isUserSettings(settings)) {
      return;
    }

    // Set initial values for local state and in the game
    initializeSound({
      soundVolume: convertToGameVolume(settings.sound_volume),
      musicVolume: convertToGameVolume(settings.music_volume),
    });
    setSettings({
      soundVolume: settings.sound_volume,
      musicVolume: settings.music_volume,
    });
    setIsSettingsReady(true);
  }, [client, session]);

  const persistUserSettings = useCallback(
    async (newSettings: UserSettings) => {
      if (!client || !session) {
        return;
      }
      await client.rpc(session, 'rpc_save_user_settings', {
        sound_volume: newSettings.soundVolume,
        music_volume: newSettings.musicVolume,
      });
    },
    [client, session]
  );

  useEffect(() => {
    if (isReady) {
      fetchUserSettings();
    }
  }, [isReady, fetchUserSettings]);

  const setMusicVolume = useCallback(
    (volume: number) => {
      setSettings((prev) => {
        const newSettings = {
          ...prev,
          musicVolume: volume,
        };
        setBgmVolume(convertToGameVolume(volume));
        persistUserSettings(newSettings);
        return newSettings;
      });
    },
    [persistUserSettings]
  );

  const setSoundVolume = useCallback(
    (volume: number) => {
      setSettings((prev) => {
        const newSettings = {
          ...prev,
          soundVolume: volume,
        };
        setAllSoundVolume(convertToGameVolume(volume));
        persistUserSettings(newSettings);
        return newSettings;
      });
    },
    [persistUserSettings]
  );

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setMusicVolume,
        setSoundVolume,
        isSettingReady,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
