import { useNakama } from '../../contexts/NakamaProvider';
import { useQuery } from 'react-query';
import { useNakamaApi } from '../../contexts/useNakamaApi';
import { AchievementId } from '../../types/nakama-api';
import { Achievement } from '@metatheoryinc/hiro-js';

class DailyCheckInAchievement extends Achievement {
  constructor(achievement: Achievement, streak: number, claimFn: () => Promise<void>) {
    super();
    Object.assign(this, achievement);
    this._streak = streak;
    this._claim = claimFn;
  }

  private readonly _streak: number;
  public get streak(): number {
    return this._streak;
  }

  public get pointsReward(): number {
    const points = this.available_rewards?.guaranteed?.currencies!['points'];
    if (points?.count?.min === undefined) {
      throw new Error('Reward not found');
    }

    const multipliers = this.additional_properties!['multipliers'].split(',').map((m) => Number(m));
    const multiplier = multipliers[Math.min(this._streak ?? 0, multipliers.length - 1)];

    return parseInt(points.count?.min) * multiplier;
  }

  private readonly _claim: () => Promise<void>;
  public claim(): Promise<void> {
    return this._claim();
  }
}

function useDailyCheckInAchievement() {
  const { session, client } = useNakama();
  const { getAchievement, updateAchievement } = useNakamaApi();

  const getStreak = async (): Promise<number> => {
    const response = await client?.readStorageObjects(session!, {
      object_ids: [
        {
          collection: 'streaks',
          key: 'daily_check_in',
          user_id: session?.user_id,
        },
      ],
    });

    if (response === undefined || response.objects.length === 0) {
      return 0;
    }

    const data = response.objects[0].value as {
      consecutive_check_ins: number;
      last_check_in: string;
    };

    const limitForNextCheckIn = new Date(data.last_check_in);
    limitForNextCheckIn.setUTCHours(0, 0, 0, 0);
    limitForNextCheckIn.setUTCDate(limitForNextCheckIn.getUTCDate() + 2);

    const now = new Date();

    if (now < limitForNextCheckIn) {
      return data.consecutive_check_ins;
    }

    return 0;
  };

  const getAchievementInternal = async (): Promise<DailyCheckInAchievement> => {
    const baseAchievement = await getAchievement(AchievementId.DailyCheckIn);
    if (baseAchievement === undefined) {
      throw new Error('Achievement not found');
    }
    return new DailyCheckInAchievement(baseAchievement, await getStreak(), claimAchievement);
  };

  const claimAchievement = async (): Promise<void> => {
    /**
     * This achievement is configured to auto claim when count gets to 1.
     * Therefore, we only need to update it.
     */
    await updateAchievement(AchievementId.DailyCheckIn, 1);
  };

  const { data: achievement } = useQuery('dailyCheckInAchievement', getAchievementInternal);

  return { achievement };
}

export default useDailyCheckInAchievement;
