import { getEvent } from '@/api/domains/events/get-event';
import { queryKeys } from '@/api/queryKeys';
import { usePartnerGroups } from '@/hooks/use-partner-groups';
import { CommunityEvent } from '@/types/event';
import { useQuery } from 'react-query';

export function useEvent({ id }: { id: string }): { event: CommunityEvent | null } {
  const { partnerGroups } = usePartnerGroups();

  const query = useQuery(queryKeys.getEvent(id), () => {
    return getEvent(id);
  });

  switch (query.status) {
    case 'success': {
      const { data } = query;

      const organizers =
        partnerGroups?.filter((group) => data.metadata.communityIds.includes(Number(group.telegramId))) ?? [];

      const event = {
        organizers: organizers,
        title: data.title,
        type: data.contest_type,
        endTime: new Date(data.end_time),
        splashImage: data.metadata.splashImage,
        description: data.metadata.description,
        prize: {
          title: data.metadata.prizeTitle,
          description: data.metadata.prizeDescription,
        },
        startTime: new Date(data.start_time),
        id: data.id,
      };

      return {
        event,
      };
    }
    case 'loading':
    case 'error':
    default:
      return {
        event: null,
      };
  }
}
