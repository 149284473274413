import React from 'react';

type Props = {
  fill?: string;
};

export const TelegramIcon: React.FC<Props> = React.memo(({ fill = '#202025' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
      <path
        fill={fill}
        d="m2.6 13.583 3.452 1.51 7.555-4.12c.488-.267.98.342.62.764L10 16.667l8.6 3.916a1 1 0 0 0 1.4-.85l1-15a1.001 1.001 0 0 0-1.425-.972l-17 8a1.002 1.002 0 0 0 .025 1.822ZM8 21.869a.5.5 0 0 0 .719.45l3.112-1.51a.5.5 0 0 0-.007-.903l-3.112-1.451a.5.5 0 0 0-.712.453v2.96Z"
      />
    </svg>
  );
});

export default TelegramIcon;
