const tg = window.Telegram.WebApp;

export function openTelegramLink(url: string) {
  if (tg.isVersionAtLeast('7.1')) {
    // This function closes the App up to version 7.0
    // https://core.telegram.org/bots/webapps#initializing-mini-apps
    tg.openTelegramLink(url);
  } else {
    tg.openLink(url, { try_instant_view: true });
  }
}
