import React from 'react';
import { ArcadeGame, ArcadeGameType, ArcadeSdkLoadingState } from './../arcade-sdk';
import { assertNever } from '@/game-raw/utils';
import { useHeroicClients } from '@/store/heroic-clients-store';

interface ArcadeReactLoaderProps<T extends ArcadeGameType> {
  sdk: ArcadeGame<T>;
  children: (game: ArcadeGame<T>) => React.ReactNode;
}

function getSuspensePromise() {
  return new Promise((resolve) =>
    setTimeout(() => {
      console.log('Waiting for Arcade.Game to initialize...');
      resolve('');
    }, 500)
  );
}

export function ArcadeReactLoader<T extends ArcadeGameType>({ children, sdk }: ArcadeReactLoaderProps<T>) {
  switch (sdk.state) {
    case ArcadeSdkLoadingState.NotReady:
      sdk.initialize(); // <-- if initialize was not called yet, start the initialize sequence
      throw getSuspensePromise();
    case ArcadeSdkLoadingState.Loading:
      throw getSuspensePromise();
    case ArcadeSdkLoadingState.Error:
      throw 'something went wrong!';
    case ArcadeSdkLoadingState.Ready:
      if (!sdk.nakamaClients || !sdk.flags) throw 'something went wrong!';
      useHeroicClients.getState().loadClients(sdk.nakamaClients);
      // FIXME: We need to narrow the type here so that the game knows that
      // nakamaClients and flags are not null
      return <>{children(sdk)}</>;
    default:
      assertNever(sdk.state);
  }
}
