export enum CatCostume {
  Default = 'costume_default',
  Pajamas = 'costume_pajamas',
  CoinEasy = 'costume_coineasy',
  Pinky = 'costume_pinky',
  Blueberry = 'costume_blueberry',
  Bubblegum = 'costume_bubblegum',
  Collar = 'costume_collar',
  Lucky = 'costume_lucky',
  Mint = 'costume_mint',
  Music01 = 'costume_music01',
  Music02 = 'costume_music02',
  Nebula = 'costume_nebula',
  Legacy = 'costume_legacy',
  LuckyStrong = 'costume_lucky_strong',
  MrMiggles = 'costume_miggles',
  Doge = 'costume_doge',
}
