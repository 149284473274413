import styles from './SetUsername.module.scss';
import gimmeCat from '../../assets/game-over-cat.png';
import { useNakama } from '../../contexts/NakamaProvider';
import { useEffect, useState } from 'react';
import { useTelegramData } from '../../game-react/hooks/use-telegram-data';
import { Button } from '../Button/Button';
import { useUserFlags } from '../../contexts/UserFlagsProvider';
import { Text } from '../Text/Text';

type SetUsernameProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDone: () => Promise<any>;
};

export const SetUsername = ({ onDone }: SetUsernameProps) => {
  const { changeUsername } = useNakama();
  const { setHasChangedUsernameFlag } = useUserFlags();
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const telegram = useTelegramData();

  useEffect(() => {
    setInput(telegram.displayName);
  }, [setInput, telegram.displayName]);

  return (
    <div className={styles.setUsernameContainer}>
      <div className={styles.setUsername}>
        <img src={gimmeCat} className={styles.catIllustration} />
        <Text as="h3" type="heading">
          Set a Username
        </Text>
        <Text as="p" type="body">
          Choose a custom username, or use your Telegram name. You can always change this later.
        </Text>
        <div className={styles.inputContainer}>
          <input
            type="text"
            onChange={(e) => {
              setInput(e.target.value);
              setError('');
            }}
            value={input}
          />
          {error && <p className={styles.error}>{error}</p>}
        </div>
        <Button
          onClick={async () => {
            try {
              await changeUsername(input);
              await setHasChangedUsernameFlag(true);

              await onDone();
            } catch (err) {
              if (err instanceof Error) {
                setError(err.message);
              }
            }
          }}
          disabled={!input || error !== ''}
          primary
          fullWidth
        >
          <Text type="button" as="span">
            Done
          </Text>
        </Button>
      </div>
    </div>
  );
};
