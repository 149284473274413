import { GimmecatCommunityBoxDefinitions } from '@/types/satori/GimmecatCommunityBoxDefinitions.gen';
import { PartnerGroupDto } from '../groups/types';
import { TeamLeaderboardRecordDto } from '../teams/types';

type Record = TeamLeaderboardRecordDto;
type Partner = PartnerGroupDto;
type Brand = GimmecatCommunityBoxDefinitions['brandingDefinitions'][number];

type RecordAndPartner = {
  record: Record;
  partner: Partner | undefined;
};

export type RecordAndPartnerAndBrand = {
  record: Record;
  partner: Partner | undefined;
  brand: Brand | undefined;
};

export type RecordAndPartnerAndBrandAssured = {
  record: Record;
  partner: Partner;
  brand: Brand;
};

export function getRecordsWithPartners(records: Record[], partners: Partner[]): RecordAndPartner[] {
  return records.map((record) => {
    const partner = partners.find((partner) => {
      return partner.name === `${record.tg_partner_group_id}`;
    });
    return {
      record,
      partner,
    };
  });
}

export function getRecordsWithPartnersAndBrands(
  recordsWithPartners: RecordAndPartner[],
  brands: Brand[]
): RecordAndPartnerAndBrand[] {
  return recordsWithPartners.map((recordWithPartner) => {
    const { record, partner } = recordWithPartner;
    const brand = findBrandForPartner(partner, brands);
    return {
      record,
      partner,
      brand,
    };
  });
}

function findBrandForPartner(partner: Partner | undefined, brands: Brand[]): Brand | undefined {
  return (
    partner &&
    brands.find((b) => {
      return b.ownerCommunityId === partner.metadata.communityId;
    })
  );
}
