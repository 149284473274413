import React, { useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { GameEntryContainer } from './routes/app/GameEntryContainer';
import { Profile } from './routes/app/Profile';
import { root } from '@/app/routes';
import { Currency } from '../components/Currency/Currency';
import Options from '../components/Options/Options';

import { useNakama } from '../contexts/NakamaProvider';
import { useSettings } from '../contexts/SettingsProvider';
import { useTelegramData } from '../game-react/hooks/use-telegram-data';
import { Shop } from './routes/app/Shop';
import { EventDetail } from '@/features/events/EventDetail';
import { InitializedFlags } from '@/lib/arcade-sdk/loaders/satori-flag-loader';
import { useArcadeSdk } from '@/lib/arcade-react-sdk/ArcadeSdkProvider';
import { EventList } from '@/features/events/EventList';
import { ErrorFallback } from '@/components/Error/ErrorFallback';
import { Teams } from './routes/app/Teams';

const createAppRouter = (flags: InitializedFlags) => {
  const defaultRoutes = [
    {
      path: root.home.path,
      element: <GameEntryContainer />,
    },
    {
      path: root.profile.path,
      element: <Profile />,
    },
    {
      path: root.rewards.path,
      element: <Currency />,
    },
    {
      path: root.options.path,
      element: <Options />,
    },
    {
      path: root.shop.path,
      element: <Shop />,
    },
  ];
  const eventRoutes = flags.liveEventContests
    ? [
        { path: root.event.path, element: <EventList /> },
        { path: root.event.Single.path, element: <EventDetail /> },
      ]
    : [];
  const playToPromoteRoutes = flags.playToPromote
    ? [
        {
          path: root.teams.path,
          element: <Teams />,
        },
      ]
    : [];
  return createBrowserRouter([...defaultRoutes, ...eventRoutes, ...playToPromoteRoutes]);
};

export const AppRouter: React.FC = () => {
  const { isReady } = useNakama();
  const { isSettingReady } = useSettings();
  const { tg } = useTelegramData();
  const { flags } = useArcadeSdk();

  if (!isReady || !isSettingReady || !tg.initDataUnsafe.user) {
    throw new Promise((resolve) =>
      setTimeout(() => {
        console.log('APIs Not Yet Loaded');
        resolve('');
      }, 500)
    );
  }

  const router = useMemo(() => createAppRouter(flags), [flags]);
  return <RouterProvider router={router} fallbackElement={<ErrorFallback />} />;
};
