export class DevTools {
  private static devModeEnabled = false;

  // disable context menu conditionally based on devModeEnabled
  static disableContextMenu(): void {
    DevTools.trackKeyPresses();
    DevTools.trackContextMenu();
  }

  // toggle devModeEnabled based on key presses (Ctrl + Shift + D)
  private static trackKeyPresses(): void {
    let ctrlPressed = false;
    let shiftPressed = false;
    let dPressed = false;

    window.addEventListener('keydown', (event: KeyboardEvent) => {
      switch (event.key.toLocaleLowerCase()) {
        case 'control':
          ctrlPressed = true;
          break;
        case 'shift':
          shiftPressed = true;
          break;
        case 'd':
          dPressed = true;
          break;
      }
      if (ctrlPressed && shiftPressed && dPressed) {
        DevTools.toggleDevMode();
      }
    });

    window.addEventListener('keyup', (event: KeyboardEvent) => {
      switch (event.key.toLocaleLowerCase()) {
        case 'control':
          ctrlPressed = false;
          break;
        case 'shift':
          shiftPressed = false;
          break;
        case 'd':
          dPressed = false;
          break;
      }
    });
  }

  private static trackContextMenu(): void {
    window.addEventListener('contextmenu', (event: MouseEvent) => {
      if (!DevTools.devModeEnabled) {
        console.log('Dev mode inactive, preventing context menu.');
        event.preventDefault();
      } else {
        console.log('Dev mode active, allowing context menu.');
      }
    });
  }

  private static toggleDevMode(): void {
    DevTools.devModeEnabled = !DevTools.devModeEnabled;
    console.log(`Dev mode ${DevTools.devModeEnabled ? 'enabled' : 'disabled'}`);
  }
}
