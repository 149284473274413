import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { NAKAMA_ERRORS, useNakama } from '../../contexts/NakamaProvider';
import { Group } from '@metatheoryinc/nakama-js';
import { UserGroup } from '@/types/UserGroup';
import { queryKeys } from '@/api/queryKeys';
import { PartnerGroup } from '@/hooks/use-partner-groups';

type Metadata = {
  title: string;
  isPartner: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMetadata = (obj: any): obj is Metadata => {
  return typeof obj?.title === 'string' && typeof obj?.isPartner === 'boolean';
};

const transformMetadata = (metadata: string) => {
  let title = 'Anonymous Group';
  let isPartner = false;

  if (metadata) {
    try {
      if (isMetadata(metadata)) {
        return metadata;
      }
      const parsedMetadata = JSON.parse(metadata);
      if (isMetadata(parsedMetadata)) {
        title = parsedMetadata.title;
        isPartner = parsedMetadata.isPartner;
      }
    } catch (e) {
      console.error('Error parsing metadata', e);
    }
  }

  return {
    title,
    isPartner,
  };
};

const transformToUserGroups = (group: Group): UserGroup => {
  const { title, isPartner } = transformMetadata(group.metadata as string);

  // This looks strange, but it's because we use the telegram id as the group name in nakama
  const telegramId = group.name || '';

  return {
    isPartner,
    telegramId,
    id: group.id || '',
    displayName: title,
  };
};

export function useGroups() {
  const { client, session } = useNakama();
  const queryClient = useQueryClient();

  const { data, isLoading } = useInfiniteQuery(
    'userGroups',
    async ({ pageParam }) => {
      const limit = 10;
      const cursor = pageParam ? pageParam : null;
      if (session && client) {
        const result = await client.listUserGroups(session, session.user_id!, limit, 2, cursor);

        return { groups: result.user_groups, cursor: result.cursor };
      }
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage && lastPage.cursor ? lastPage.cursor : undefined;
      },
      retryOnMount: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const groupData: UserGroup[] =
    data?.pages
      ?.flatMap((page) => page?.groups ?? [])
      .map((group) => group?.group)
      .filter((g): g is NonNullable<typeof g> => Boolean(g))
      .map(transformToUserGroups) || [];

  const { mutateAsync: updateCommunity } = useMutation(
    async (selectedCommunity: PartnerGroup) => {
      if (client && session) {
        await client.rpc(session, 'rpc_update_user_community', {
          communityTelegramId: Number(selectedCommunity.telegramId),
        });
      }
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (err: any) => {
        const error = await err.json();
        if (error.code === NAKAMA_ERRORS.USER_NOT_GROUP_MEMBER) {
          throw new Error('You’re not a member of this group.');
        }
        throw new Error(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.getUserAccount());
      },
    }
  );

  return {
    groupData,
    isLoadingGroups: isLoading,
    updateCommunity,
  };
}
