import { root } from '@/app/routes';
import { Modal } from '@/components/Modal/Modal';
import { LoaderFunctionArgs, useNavigate } from 'react-router-dom';
import { Text } from '@/components/Text/Text';
import styles from '@/styles/app/Teams.module.scss';
import { usePageViewTracking } from '@/game-react/hooks/use-page-view-tracking';
import { QueryClient } from 'react-query';
import { getTeamsLeaderboardQueryOptions, useTeamsLeaderboard } from '@/features/teams/api/use-teams-leaderboard';
import { ClickableLeaderboardItem } from '@/features/teams/components/ClickableLeaderboardItem';

export const teamsLoader = (queryClient: QueryClient) => async (_args: LoaderFunctionArgs) => {
  const queryOptions = getTeamsLeaderboardQueryOptions();

  const promises = [queryClient.getQueryData(queryOptions.queryKeys) ?? (await queryClient.fetchQuery(queryOptions))];

  const [records] = await Promise.all(promises);

  return {
    records,
  };
};

export const Teams = () => {
  usePageViewTracking('teams');
  const navigate = useNavigate();
  const { records } = useTeamsLeaderboard();

  return (
    <Modal title={'Teams'} onClose={() => navigate(root.home.path)} fullScreen={true}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.infoContainer}>
            <img src="images/teams/arrow.png" />
            <div className={styles.infoText}>
              <Text as={'h2'} type={'body'} size="s" weight="semibold">
                Play to elevate your community!
              </Text>
              <Text as={'p'} type={'body'} size="xs">
                Join the Top 3 for increased visibility.
              </Text>
            </div>
          </div>
        </div>
        <img src="images/teams/top-teams.png" />
        {records &&
          records?.map((item) => {
            return (
              <ClickableLeaderboardItem
                key={`clickable-item-${item.name}`}
                rank={item.rank}
                onClick={() => console.log('click')}
                logo={item.image}
                name={item.name}
                score={item.score}
              />
            );
          })}
      </div>
    </Modal>
  );
};
