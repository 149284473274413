import { ScoreManager } from '../../game-raw/engine/score-manager';
import { GameMode, GameRun } from '../../game-raw/engine/game-run';
import { EventCollector } from '../../game-raw/engine/event-collector';
import { Client as SatoriClient, Session as SatoriSession } from '@metatheoryinc/satori-js';
import { BrandManager } from '../../game-raw/brandManager';

export enum GameplayState {
  PreInitializing = 'PreInitializing',
  Initializing = 'Initializing',
  ReadyToPlay = 'ReadyToPlay',
  Playing = 'Playing',
  GameOver = 'GameOver',
}

export type GameState = {
  highScore: number;
  username: string;
  gameplayState: GameplayState;
  brandManager: BrandManager | undefined;
  setBrandManager: (bm: BrandManager) => void;
  scoreManager: ScoreManager;
  resetScoreManager: () => void;
  gameRun: GameRun | undefined;
  resetGameRun: (telegramUserId: number, gameMode: GameMode, startTime?: number) => void;
  eventCollector: EventCollector;
  setGameplayState: (gameState: GameplayState) => void;
  gameSessionRunCount: number;
  bumpGameSessionRunCount: () => void;
  satoriClient: SatoriClient | undefined;
  setSatoriClient: (s: SatoriClient) => void;
  satoriSession: SatoriSession | undefined;
  setSatoriSession: (s: SatoriSession) => void;
};
