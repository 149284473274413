import { useInfiniteQuery } from 'react-query';
import { useNakama } from '../contexts/NakamaProvider';
import { LeaderboardRecordList } from '@metatheoryinc/nakama-js';
import { getRankFrom, getScoreFrom, LeaderboardItem, LeaderboardType } from '@/types/leaderboard';
import { queryKeys } from '@/api/queryKeys';
import { QueryConfig } from '@/lib/react-query';
import { getUserIds, transformLeaderboardRecord } from '@/api/domains/leaderboards/leaderboard-utils';
import { getLeaderboardData } from '@/api/domains/leaderboards/get-leaderboard-data';

function getLeaderboardDataQueryOptions(leaderboardId: string) {
  return {
    queryKey: queryKeys.getLeaderboardData(leaderboardId),
    queryFn: ({ pageParam }: { pageParam?: string }) => getLeaderboardData(leaderboardId, pageParam),
    options: {
      getNextPageParam: (lastPage?: { nextCursor: string }) => {
        return lastPage?.nextCursor;
      },
      retryOnMount: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  };
}

type UseLeaderboardDataOptions = {
  leaderboardId: string;
  queryConfig?: QueryConfig<typeof getLeaderboardDataQueryOptions>;
};

export function useGenericLeaderboardData(
  { leaderboardId, queryConfig }: UseLeaderboardDataOptions = { leaderboardId: LeaderboardType.ALL }
) {
  return useInfiniteQuery({
    ...getLeaderboardDataQueryOptions(leaderboardId),
    ...queryConfig,
  });
}

export function useGroupLeaderboardData(leaderboardId: string = LeaderboardType.ALL, groupId?: string) {
  const { client, session } = useNakama();

  const {
    data: groupLeaderboardData,
    isLoading: isLoadingGroupLeaderboardData,
    isFetching: isFechingGroupLeaderboardData,
    hasNextPage: hasMoreGroupLeaderboardData,
    fetchNextPage: fetchNextGroupLeaderboardData,
    refetch: refetchGroupLeaderboardData,
    isFetchingNextPage: isFetchingNextGroupLeaderboardPage,
  } = useInfiniteQuery(
    ['groupLeaderboardData', leaderboardId, groupId],
    async ({ pageParam }: { pageParam?: string }) => {
      if (client && session && groupId) {
        const limit = 10;
        const cursor = pageParam ? pageParam : null;

        const groupLeaderboardData = await client.rpc(session, 'rpc_list_group_leaderboard_records_v2', {
          channelId: parseInt(groupId),
          leaderboardId,
          ownerIds: [session.user_id!],
          limit,
          cursor,
        });

        const result = groupLeaderboardData.payload as LeaderboardRecordList;

        const userIds = getUserIds(result);

        const getUsersResponse = await client.getUsers(session, userIds);

        const leaderboardItems: LeaderboardItem[] =
          result.records?.map((record) => {
            return transformLeaderboardRecord(record, getUsersResponse.users || []);
          }) || [];

        return {
          playerResult: {
            score: getScoreFrom(result.owner_records?.[0]),
            position: getRankFrom(result.owner_records?.[0]),
          },
          results: leaderboardItems,
          nextCursor: result.next_cursor,
          pageParam,
        };
      }
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.nextCursor;
      },
      retryOnMount: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  return {
    groupLeaderboardData: {
      playerResult: groupLeaderboardData?.pages?.[0]?.playerResult,
      results: groupLeaderboardData?.pages.flatMap((page) => page?.results ?? []),
    },
    isLoadingGroupLeaderboardData,
    fetchNextGroupLeaderboardData,
    refetchGroupLeaderboardData,
    isFetchingNextGroupLeaderboardPage,
    isFechingGroupLeaderboardData,
    hasMoreGroupLeaderboardData,
  };
}
