import { useGSAP } from '@gsap/react';
import { useRef, useState, useEffect } from 'react';
import JumpJump from '@/game-react/JumpJump';
import { GameOver } from '@/components/GameOver/GameOver';
import { Home } from '@/components/Home';
import {
  startGame,
  initializeNewGameRun,
  setHandlers,
  updateHighScoreUI,
  setEquippedCostume,
  startDailyChallenge,
} from '@/game-raw/StartGame';
import { useInventory } from '@/game-react/hooks/use-inventory';
import { useGameStore, GameState } from '@/store/gameStore';
import { postScore } from '@/api/score';
import { Leaderboard } from '@/components/Leaderboard';
import { SetUsername } from '@/components/Ftue/SetUsername';
import { useToasts } from '@/contexts/ToastProvider';
import { useNakamaApi } from '@/contexts/useNakamaApi';
import { useUserFlags } from '@/contexts/UserFlagsProvider';
import { InventoryCategories } from '@/utils/constants';
import { useNakama } from '@/contexts/NakamaProvider';
import { LeaderboardTabType } from '@/components/Leaderboard/Leaderboard';
import { Events } from '@/components/Events/Events';
import { CatCostume } from '@/types/CatCostume';
import { ChallengeHome } from '@/components/ChallengeHome/ChallengeHome';
import { usePlayerData } from '@/hooks/use-player-leaderboard-data';
import { useArcadeSdk } from '@/lib/arcade-react-sdk/ArcadeSdkProvider';
import { P2PGameOver } from '@/components/GameOver/P2PGameOver';
import { root } from '@/app/routes';
import { useNavigate } from 'react-router-dom';

enum UIElements {
  MainMenu,
  ChallengeMenu,
  Game,
  GameOver,
  Leaderboard,
  ContestLeaderboard,
  SetUsername,
  Events,
}

export const GameEntryContainer = () => {
  const [ui, setUi] = useState(UIElements.MainMenu);
  const { data: playerData } = usePlayerData();
  const { addToast } = useToasts();
  const { getAchievement, updateAchievement } = useNakamaApi();
  const { satoriClient, satoriSession } = useNakama();
  const { userFlags } = useUserFlags();
  const { setSatoriClient, setSatoriSession, scoreManager } = useGameStore<GameState>();
  const { flags } = useArcadeSdk();
  const navigate = useNavigate();

  const [eventGroupId, setEventGroupId] = useState('');

  useEffect(() => {
    if (satoriClient) {
      setSatoriClient(satoriClient);
    }
    if (satoriSession) {
      setSatoriSession(satoriSession);
    }
  }, [satoriClient, satoriSession, setSatoriClient, setSatoriSession]);

  const { equippedItems, hasFetchedEquippedItems } = useInventory();

  useEffect(() => {
    if (hasFetchedEquippedItems) {
      const equippedCostume = equippedItems?.[InventoryCategories.Costumes];
      setEquippedCostume(equippedCostume || CatCostume.Default);
    }
  }, [equippedItems, hasFetchedEquippedItems]);

  const handleGameOver = async () => {
    if (!userFlags.hasChangedUsername) {
      setUi(UIElements.SetUsername);
    } else {
      setUi(UIElements.GameOver);
    }
  };

  const handleHomeButton = async () => {
    setUi(UIElements.MainMenu);

    await postScore(scoreManager.score);
  };

  const handleUpdateAchievement = async (achievementId: string): Promise<void> => {
    const achievement = await getAchievement(achievementId);
    if (achievement === undefined) {
      console.error(`Achievement not found: ${achievementId}`);
      return;
    }

    if (achievement.count !== achievement.max_count) {
      try {
        await updateAchievement(achievementId, 1);
        addToast(`Daily Quest Reward Unlocked!`, 'info');
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleEventClick = (groupId: string) => {
    setEventGroupId(groupId);
    setUi(UIElements.ContestLeaderboard);
  };

  // Set the game's gameOverHandler
  setHandlers(handleGameOver, handleHomeButton, handleUpdateAchievement);

  // Set the in-game highscore text
  const highscore = playerData?.highScore ?? 0;
  updateHighScoreUI(highscore);

  // This unused code shows how you could use the
  // useGSAP to have context safe calls as we switch to components
  const container = useRef();
  const { contextSafe: _contextSafe } = useGSAP({ scope: container });

  const showUI = () => {
    switch (ui) {
      case UIElements.MainMenu:
        return (
          <Home
            onGameStart={async () => {
              setUi(UIElements.Game);
              await startGame();
            }}
            onDailyChallengeStart={async () => {
              setUi(UIElements.ChallengeMenu);
            }}
            onLeaderboard={() => setUi(UIElements.Leaderboard)}
            onContest={() => setUi(UIElements.Events)}
          />
        );
      case UIElements.ChallengeMenu:
        return (
          <ChallengeHome
            onDailyChallengeStart={async () => {
              setUi(UIElements.Game);
              await startDailyChallenge();
            }}
            onMainMenu={async () => {
              setUi(UIElements.MainMenu);
            }}
          />
        );
      case UIElements.GameOver:
        if (flags.playToPromote) {
          return (
            <P2PGameOver
              onBackHome={async () => {
                setUi(UIElements.MainMenu);
                await initializeNewGameRun();
              }}
              onRestart={async () => {
                setUi(UIElements.Game);
                await initializeNewGameRun();
                await startGame();
              }}
              onLeaderboard={async () => {
                // We need to reset the game before leaving this screen
                setUi(UIElements.MainMenu);
                await initializeNewGameRun();

                navigate(root.teams.path);
              }}
              score={scoreManager.score}
            />
          );
        }

        return (
          <GameOver
            onBackHome={async () => {
              setUi(UIElements.MainMenu);
              await initializeNewGameRun();
            }}
            onRestart={async () => {
              setUi(UIElements.Game);
              await initializeNewGameRun();
              await startGame();
            }}
            onLeaderboard={() => setUi(UIElements.Leaderboard)}
            score={scoreManager.score}
          />
        );
      case UIElements.Leaderboard:
        return (
          <Leaderboard
            onBackHome={async () => {
              setUi(UIElements.MainMenu);
              await initializeNewGameRun();
            }}
          />
        );
      case UIElements.ContestLeaderboard:
        return (
          <Leaderboard
            onBackHome={async () => {
              setUi(UIElements.MainMenu);
              await initializeNewGameRun();
            }}
            selectedTab={LeaderboardTabType.Groups}
            eventGroupId={eventGroupId}
          />
        );
      case UIElements.SetUsername:
        return (
          <SetUsername
            onDone={async () => {
              setUi(UIElements.GameOver);
            }}
          />
        );
      case UIElements.Events:
        return <Events onClose={() => setUi(UIElements.MainMenu)} onClickEvent={handleEventClick} />;
      case UIElements.Game:
        return;
    }
  };

  // For now, return a full game app.
  return (
    <>
      {showUI()}
      <JumpJump />
    </>
  );
};
