import { sdk } from '@/gimmecat';
import { z } from 'zod';
import { LeaderboardRecordListDto } from './types';

const DEFAULT_LIMIT = 3;

export async function getLeaderboardAroundUser(
  leaderboardId: string
): Promise<z.infer<typeof LeaderboardRecordListDto>> {
  if (!sdk.nakamaClients) {
    throw new Error('getLeaderboardAroundUser called before nakama clients initialized');
  }

  const { nakamaClient: client, nakamaSession: session } = sdk.nakamaClients;

  const result = await client.listLeaderboardRecordsAroundOwner(
    session,
    leaderboardId,
    session.user_id!,
    DEFAULT_LIMIT
  );

  return LeaderboardRecordListDto.parse(result.records);
}
