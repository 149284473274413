export const ArrowUp = ({ fill = '#202025' }: { fill?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} fill="none">
      <path
        fill={fill}
        fillRule="evenodd"
        stroke={fill}
        strokeWidth={2}
        d="M10.976 4.214a.59.59 0 1 0-.833-.834L6.26 7.262 2.381 3.38a.59.59 0 0 0-.834.834l4.248 4.248a.66.66 0 0 0 .933 0l4.248-4.248Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
