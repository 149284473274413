import gsap from 'gsap';
import { Application, Assets, BitmapText, Container, Graphics, Sprite, TextStyle } from 'pixi.js';
import { ComboMeter } from './comboMeter';
import { ThemeColors } from '../../api/player';
import { GameMode } from '../engine/game-run';
import { gameStore } from '../../store/gameStore';
import { ProgressMeter } from './progressMeter';
import { ScoreManager } from '../engine/score-manager';
import { formatTime } from '../utils';

export class ScoreUI extends Container {
  gameWidth: number;
  gameHeight: number;
  scoreHolder: Container;
  buttonHolder: Container;
  scoreText: BitmapText;
  ptsText: BitmapText;
  countText: BitmapText;
  comboText: BitmapText;
  highScoreText: BitmapText;
  homeButton: Container;
  currentHighscore: number = 0;
  comboMeter: ComboMeter;
  progressMeter: ProgressMeter;

  constructor(gameWidth: number, gameHeight: number, app: Application, homeCallback: () => void) {
    super();
    this.gameWidth = gameWidth;
    this.gameHeight = gameHeight;

    // Combo Meter
    this.comboMeter = new ComboMeter();
    this.comboMeter.x = gameWidth * 0.5;
    this.comboMeter.y = gameHeight * 0.25;
    this.addChild(this.comboMeter);

    // Progress Meter (Daily Challenge)
    this.progressMeter = new ProgressMeter();
    this.progressMeter.x = gameWidth * 0.5;
    this.progressMeter.y = gameHeight * 0.95;
    this.addChild(this.progressMeter);

    this.scoreHolder = new Container();
    this.buttonHolder = new Container();

    // Score text
    this.scoreText = new BitmapText({
      text: '0',
      style: new TextStyle({
        fontFamily: 'Mplus1c-numbers',
        fontSize: 192,
        fill: ThemeColors.uiText,
      }),
    });
    this.scoreText.pivot.set(this.scoreText.width * 0.5, this.scoreText.height * 0.5);
    this.scoreText.x = this.scoreText.width * 0.5;
    this.scoreHolder.addChild(this.scoreText);

    this.ptsText = new BitmapText({
      text: 'PTS',
      style: new TextStyle({
        fontFamily: 'DMMono-Medium',
        fontWeight: 'bold',
        fontSize: 36,
        fill: ThemeColors.uiText,
      }),
    });
    this.ptsText.x = this.scoreText.x + this.scoreText.width * 0.5 + 20;
    this.ptsText.y = -40;
    this.scoreHolder.addChild(this.ptsText);

    this.countText = new BitmapText({
      text: 'BLOCKS: 0',
      style: new TextStyle({
        fontFamily: 'DMMono-Medium',
        fontWeight: 'bold',
        fontSize: 36,
        fill: ThemeColors.uiText,
      }),
    });
    this.countText.x = this.scoreText.x - this.scoreText.width * 0.5 + 20;
    this.countText.y = 100;
    this.scoreHolder.addChild(this.countText);

    // Combo text
    this.comboText = new BitmapText({
      text: 'COMBO x4',
      style: new TextStyle({
        fontFamily: 'Rubik-Bold',
        fontSize: 52,
        fill: ThemeColors.uiAccentText,
      }),
    });
    this.comboText.pivot.set(this.comboText.width * 0.5, this.comboText.height * 0.5);
    this.comboText.x = gameWidth * 0.5;
    this.comboText.y = gameHeight * 0.2;
    //this.addChild(this.comboText);

    // Highscore text
    this.highScoreText = new BitmapText({
      text: 'YOUR HIGHSCORE: 0',
      style: new TextStyle({
        fontFamily: 'DMMono-Medium',
        fontSize: 42,
        letterSpacing: 4,
        fill: ThemeColors.uiText,
      }),
    });
    this.highScoreText.pivot.set(this.highScoreText.width * 0.5, this.highScoreText.height * 0.5);
    this.highScoreText.x = gameWidth * 0.5;
    this.highScoreText.y = gameHeight * 0.95;
    this.addChild(this.highScoreText);

    // Home button
    this.homeButton = new Container();
    const loadHomeButton = async () => {
      const btn = await Assets.load('/images/ui/home_button.png');
      const btnSprite = new Sprite(btn);
      btnSprite.anchor.set(0.5, 0.5);
      btnSprite.tint = ThemeColors.uiText;

      const graphics = new Graphics();
      const size = btn.width * 0.5;
      const radius = 40;
      graphics.roundRect(-size, -size, size * 2, size * 2, radius);
      graphics.fill({ color: 0xffffff, alpha: 0.2 });
      this.homeButton.addChild(graphics);
      this.homeButton.addChild(btnSprite);
    };
    loadHomeButton();
    this.buttonHolder.addChild(this.homeButton);

    // Make home button clickable
    this.buttonHolder.eventMode = 'static';
    this.buttonHolder.on('pointertap', homeCallback);

    this.addChild(this.scoreHolder);
    this.addChild(this.buttonHolder);

    this.resize(app);
  }

  public initGameMode(gameMode: GameMode) {
    this.updateScore(0, 0);
    switch (gameMode) {
      case GameMode.Normal:
        this.scoreText.style.fontSize = 192;
        this.countText.visible = false;
        this.ptsText.visible = true;
        this.highScoreText.visible = true;
        this.progressMeter.visible = false;
        break;
      case GameMode.DailyChallenge:
        this.scoreText.style.fontSize = 128;
        this.countText.visible = true;
        this.ptsText.visible = false;
        this.highScoreText.visible = false;
        this.progressMeter.visible = true;
        break;
    }
  }

  public resize(app: Application) {
    const scale = 1 / app.stage.scale.x;

    this.scoreHolder.x = (-app.stage.x + 25) * scale;
    this.scoreHolder.y = 40 * scale;
    this.scoreHolder.scale = scale * 0.4;

    this.buttonHolder.x = (-app.stage.x + window.innerWidth - 50) * scale;
    this.buttonHolder.y = 48 * scale;
    this.buttonHolder.scale = scale * 0.6;

    this.comboMeter.scale = scale * 0.35;
    this.progressMeter.scale = scale * 0.4;
  }

  public clear() {
    this.scoreText.text = '0';
    this.comboText.text = '';
    this.updateScorePosition();
  }

  public updateScorePosition() {
    this.scoreText.pivot.set(this.scoreText.width * 0.5, this.scoreText.height * 0.5);
    this.scoreText.x = this.scoreText.width * 0.5;
    this.ptsText.x = this.scoreText.x + this.scoreText.width * 0.5 + 16;
  }

  public updateTime(time: number) {
    this.scoreText.text = formatTime(time);
    this.updateScorePosition();
  }

  public updateProgress(val: number) {
    this.progressMeter.setProgress(val);
  }

  public updateScore(score: number, combo: number) {
    if (combo > 0) {
      this.comboText.text = 'COMBO x' + combo;
    } else {
      this.comboText.text = '';
    }

    const blocksCleared = (gameStore.getState().gameRun?.getBlocks().length || 0) - 1;
    switch (gameStore.getState().gameRun?.getGameMode()) {
      case GameMode.Normal:
        this.scoreText.text = score;
        this.updateScorePosition();
        this.setHighscore(score);
        break;
      case GameMode.DailyChallenge:
        this.countText.text = 'BLOCKS LEFT: ' + (ScoreManager.dailyChallengeBlocks - blocksCleared);
        break;
    }

    this.comboText.pivot.set(this.comboText.width * 0.5, this.comboText.height * 0.5);

    this.comboMeter.setCombo(combo);

    gsap.to(this.scoreText.scale, {
      duration: 0.1,
      x: 1.2,
      y: 1.2,
      repeat: 1,
      yoyo: true,
      ease: 'power1.out',
    });

    if (combo > 0) {
      gsap.to(this.comboText.scale, {
        duration: 0.2,
        x: 1.5,
        y: 1.5,
        repeat: 1,
        yoyo: true,
        ease: 'power1.out',
      });
    }
  }

  public setHighscore(val: number) {
    if (val < this.currentHighscore) return;
    this.currentHighscore = val;
  }

  public updateHighscoreText(val: number) {
    this.highScoreText.text = `YOUR HIGHSCORE: ${val}`;
    this.highScoreText.pivot.set(this.highScoreText.width * 0.5, this.highScoreText.height * 0.5);
  }
}
