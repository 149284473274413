import { createContext, ReactNode, useContext } from 'react';
import { defaultFlags, InitializedFlags } from '../arcade-sdk/loaders/satori-flag-loader';
import { ArcadeGame, ArcadeGameType } from '../arcade-sdk';

type ArcadeSdkProviderContextType = {
  flags: InitializedFlags;
};

const ArcadeSdkProviderContext = createContext<ArcadeSdkProviderContextType>({
  flags: defaultFlags,
});

export function ArcadeSdkProvider<T extends ArcadeGameType>({
  children,
  sdk,
}: {
  children: ReactNode;
  sdk: ArcadeGame<T>;
}) {
  // FIXME: We need to narrow the type here so that the passed in game always has a
  // non-null flags property This is currently checked by ArcadeReactLoader, but
  // the type isn't narrowed properly, so we use a ! here
  const flags = sdk.flags!;

  return <ArcadeSdkProviderContext.Provider value={{ flags }}>{children}</ArcadeSdkProviderContext.Provider>;
}

export const useArcadeSdk = () => {
  return useContext(ArcadeSdkProviderContext);
};
