// GimmecatCommunityBoxDefinitions - The configuration of community boxes that appear in Gimmecat
import { z } from 'zod';

export const GimmecatCommunityBoxDefinitions = z.object({
  brandingDefinitions: z.array(
    z.object({
      darkColor: z.string().regex(new RegExp('^#([A-Fa-f0-9]{6})$')),
      name: z.string(),
      outlineColor: z.string().regex(new RegExp('^#([A-Fa-f0-9]{6})$')),
      ownerCommunityId: z.string(),
      sideColor: z.string().regex(new RegExp('^#([A-Fa-f0-9]{6})$')),
      sideImageUrl: z.string().optional(),
      topColor: z.string().regex(new RegExp('^#([A-Fa-f0-9]{6})$')),
      topImageUrl: z.string(),
    })
  ),
  minimumGapBetweenBrandedBlocks: z.number().int().gte(0),
});
export type GimmecatCommunityBoxDefinitions = z.infer<typeof GimmecatCommunityBoxDefinitions>;
