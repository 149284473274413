import React, { createContext, useContext, useState, useCallback } from 'react';
import { ToastProps } from '../components/Toasts/Toast';
import { ToastContainer, ToastItem } from '../components/Toasts/ToastContainer';

export interface ToastContextType {
  addToast: (
    message: string,
    type: ToastProps['type'],
    position?: ToastItem['position'],
    messageRight?: ToastProps['messageRight']
  ) => void;
  removeToast: (id: number) => void;
  toasts: ToastItem[];
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const addToast = useCallback(
    (
      message: string,
      type: ToastProps['type'],
      position: ToastItem['position'] = 'top',
      messageRight?: ToastProps['messageRight']
    ) => {
      if (type !== 'info') {
        throw new Error(`ToastProvider: Toast type ${type} is not yet implemented.`);
      }
      setToasts((prevToasts) => [
        ...prevToasts,
        {
          id: Date.now(),
          message,
          type,
          position,
          messageRight,
          onClose: () => {},
        },
      ]);
    },
    []
  );

  const removeToast = useCallback((id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
};

export const useToasts = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
