import React, { useEffect } from 'react';
import styles from './Toast.module.scss';
import { InfoIcon } from '../Icons/InfoIcon';

export interface ToastProps {
  message: string;
  messageRight?: React.ReactNode;
  type: 'success' | 'error' | 'info';
  onClose: () => void;
}

const toastIcons = {
  info: <InfoIcon />,
  error: <></>,
  success: <></>,
};

export const Toast: React.FC<ToastProps> = ({ message, messageRight, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={styles.toast} onClick={onClose}>
      <div className={styles.messageLeft}>
        {toastIcons[type]}
        {message}
      </div>
      {messageRight && <div>{messageRight}</div>}
    </div>
  );
};
