import classnames from 'classnames';
import { HomeIcon } from '../Icons/HomeIcon';
import { RefreshIcon } from '../Icons/RefreshIcon';
import styles from './GameOver.module.scss';
import { useReferrals } from '../../contexts/ReferralsProvider';
import { ChevronIcon } from '../Icons/ChevronIcon';
import gameOverCat from '../../assets/game-over-cat.png';
import confetti from '../../assets/confetti.png';
import { useEffect, useRef, useState } from 'react';
import { usePageViewTracking } from '../../game-react/hooks/use-page-view-tracking';
import { Button } from '../Button/Button';
import { postScore } from '../../api/score';
import { useQueryClient } from 'react-query';
import loadingGif from '../../assets/loading-paw.gif';
import { LeaderboardType } from '@/types/leaderboard';
import { Text } from '../Text/Text';
import { LeaderboardEntry } from '@/features/leaderboard/LeaderboardEntry';
import { useLeaderboardAroundUser } from '@/hooks/use-leaderboard-around-user';
import { usePlayerData } from '@/hooks/use-player-leaderboard-data';

type GameOverProps = {
  onBackHome: () => void;
  onRestart: () => void;
  onLeaderboard: () => void;
  score: number;
};

export const GameOver = ({ onBackHome, onRestart, onLeaderboard, score }: GameOverProps) => {
  usePageViewTracking('game-over');
  const { data: playerData } = usePlayerData();
  const { data: leaderboardAroundUser, isLoading: isLoadingLeaderboardAroundUser } = useLeaderboardAroundUser({
    leaderboardId: LeaderboardType.DAILY,
  });
  const { share, referralIncentive } = useReferrals();
  const [newHighScore, setNewHighScore] = useState(false);
  const [postingScore, setPostingScore] = useState(true);
  const queryClient = useQueryClient();
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (hasInitialized.current || playerData === undefined) {
      return;
    }

    hasInitialized.current = true;
    setNewHighScore(playerData.highScore ? score > playerData.highScore : true);

    setPostingScore(true);
    // send the game mode through here
    // for now we are disabling auditing for daily challenge mode, later we will want it in

    postScore(score)
      .catch((e) => console.error(e))
      .finally(() => {
        queryClient.invalidateQueries();
        setPostingScore(false);
      });
  }, [playerData, score, queryClient]);

  return (
    <div className={styles.gameover}>
      <section className={styles.scoresSection}>
        <div className={styles.currentScoreContainer}>
          {newHighScore && <img className={styles.confetti} src={confetti} />}
          <Text as="span" type="mono" className={styles.eyebrow}>
            {newHighScore ? 'New Highscore' : 'Your score'}
          </Text>
          <p className={styles.score}>{score}</p>
          {referralIncentive && (
            <button onClick={share} className={styles.shareButton}>
              <Text as="span" type="button">
                Invite
              </Text>
            </button>
          )}
        </div>
        {(!postingScore || !newHighScore) && !isLoadingLeaderboardAroundUser && leaderboardAroundUser ? (
          <>
            <img className={styles.gameOverCat} src={gameOverCat} />
            <div className={classnames(styles.eyebrow, styles.dailyScoreDescription)}>Daily Scores</div>
            <div className={styles.leaderboard}>
              {leaderboardAroundUser.map((player, index) => (
                <LeaderboardEntry
                  key={index}
                  name={player.username!}
                  score={player.score!}
                  rank={player.rank}
                  highlight={player.owner_id === playerData?.userId}
                  className={classnames(styles.leaderboardItem, {
                    [styles.highlightItem]: player.owner_id === playerData?.userId,
                  })}
                />
              ))}
            </div>
            <Button secondary className={styles.leaderboardButton} onClick={onLeaderboard}>
              <Text as="span" type="button">
                See all
              </Text>
              <ChevronIcon />
            </Button>
          </>
        ) : (
          <div>
            <img src={loadingGif} className={styles.loadingGif} />
          </div>
        )}
      </section>
      <section className={styles.bottomSection}>
        <div className={styles.actionContainer}>
          <Button primary fullWidth className={styles.homeButton} onClick={onBackHome}>
            <HomeIcon fill="#ffffff" />
          </Button>
          <Button primary fullWidth className={styles.action} onClick={onRestart} playSound={'menu_item_select'}>
            <RefreshIcon />
            <Text type="button" as="span">
              Play again?
            </Text>
          </Button>
        </div>
        <Text as="p" type="mono" className={styles.eyebrow}>
          Your highscore: {{ highscore: newHighScore ? score : playerData?.highScore }}
        </Text>
      </section>
    </div>
  );
};
