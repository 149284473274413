import React from 'react';
import { useId } from 'react';

type Props = {
  fill?: string;
};

export const FireIcon: React.FC<Props> = React.memo(({ fill = '#FF5546' }) => {
  const id = useId();

  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${id})`}>
        <path
          d="M23.3093 12.7554C22.9515 12.2888 22.5159 11.8843 22.1115 11.4798C21.0692 10.5465 19.887 9.87757 18.8914 8.89754C16.5735 6.62637 16.0602 2.87737 17.538 -0.000488281C16.0602 0.3573 14.769 1.16621 13.6646 2.0529C9.63555 5.28855 8.04884 10.9976 9.94667 15.8977C10.0089 16.0533 10.0711 16.2089 10.0711 16.4111C10.0711 16.7533 9.83778 17.0644 9.52666 17.1889C9.16887 17.3445 8.79553 17.2511 8.49996 17.0022C8.41168 16.9283 8.33784 16.8386 8.28218 16.7378C6.52435 14.5133 6.24434 11.3243 7.4266 8.7731C4.82875 10.8887 3.41315 14.4666 3.61538 17.8422C3.70872 18.62 3.80205 19.3978 4.0665 20.1756C4.28429 21.109 4.7043 22.0424 5.17098 22.8668C6.85103 25.558 9.76 27.487 12.8868 27.8759C16.2157 28.2959 19.7781 27.6892 22.3293 25.3869C25.176 22.8046 26.1716 18.6667 24.7093 15.1199L24.5071 14.7155C24.1804 13.9999 23.3093 12.7554 23.3093 12.7554ZM18.3936 22.5557C17.958 22.9291 17.2424 23.3335 16.6824 23.4891C14.9401 24.1113 13.1979 23.2402 12.1712 22.2135C14.0223 21.7779 15.1268 20.409 15.4535 19.0245C15.7179 17.78 15.2202 16.7533 15.0179 15.5555C14.8313 14.4044 14.8624 13.4243 15.2824 12.351C15.5779 12.9421 15.8891 13.5332 16.2624 13.9999C17.4602 15.5555 19.3425 16.24 19.747 18.3556C19.8092 18.5734 19.8403 18.7912 19.8403 19.0245C19.887 20.3001 19.3269 21.7001 18.3936 22.5557Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="28" height="28" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
});
