// Nakama RPC Return Types

export type PointsNotifierPayload = {
  available_claims: boolean;
};

export enum AchievementId {
  DailyCheckIn = 'daily_check_in',
  Daily4xCombo = 'daily_4x_combo',
  Daily10xCombo = 'daily_10x_combo',
  Daily50Jumps = 'daily_50_jumps',
}

export type UserSettingsPayload = {
  sound_volume: number;
  music_volume: number;
};
