import React from 'react';

type Props = {
  fill?: string;
};

export const Ellipse: React.FC<Props> = React.memo(({ fill = '#606060' }) => {
  return (
    <svg width="3" height="4" viewBox="0 0 2 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="2" r="0.75" fill={fill} />
    </svg>
  );
});
