import { z } from 'zod';

export const UserMetadataSchema = z.object({
  shadowBan: z.boolean().optional(),
  communityId: z.number().optional(),
});

export type UserMetadata = z.infer<typeof UserMetadataSchema>;

export const isUserMetadata = (obj: unknown): obj is UserMetadata => {
  return UserMetadataSchema.safeParse(obj).success;
};

export const TotalHotpotPointsSchema = z.object({
  totalPoints: z.number(),
});

export type TotalHotpotPoints = z.infer<typeof TotalHotpotPointsSchema>;
