import React from 'react';

type Props = {
  fill?: string;
};

export const HomeIcon: React.FC<Props> = React.memo(({ fill = '#606060' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M14.322 3.929a2.426 2.426 0 0 1 3.355 0l10.425 9.982c1.184 1.134.381 3.133-1.259 3.133h-1.801a2.441 2.441 0 0 1-.034.372l-1.555 9.303a2.426 2.426 0 0 1-2.393 2.026h-1.537v-5.21a3.45 3.45 0 1 0-6.898 0v5.21h-1.686a2.426 2.426 0 0 1-2.392-2.026L6.99 17.416a2.458 2.458 0 0 1-.034-.372H5.156c-1.64 0-2.443-1.999-1.258-3.133l10.424-9.982Z"
        clipRule="evenodd"
      />
    </svg>
  );
});
