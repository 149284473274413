import { useEffect, useState } from 'react';
import styles from '@/styles/loading.module.scss';
import gameTitle from '@/assets/game-title-2.png';
import { sdk } from '@/gimmecat';

interface LoadingProps {
  sdk: typeof sdk;
}

export const Loading = ({ sdk }: LoadingProps) => {
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    const handleProgressChange = () => {
      setProgress((sdk.progress || 0) * 100);
    };
    sdk.on('progress-change', handleProgressChange);
    return () => {
      sdk.off('progress-change', handleProgressChange);
    };
  }, [sdk]);

  return (
    <div className={styles.loadingScreen}>
      <img src={gameTitle} className={styles.gameTitle} />
      <div className={styles.progressBarWrapper}>
        <ProgressBar progress={progress} />
      </div>
    </div>
  );
};

const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={{ width: `calc(${progress}% - 16px)` }} />
    </div>
  );
};
