import React, { useEffect, useState } from 'react';
import styles from './FtueStepper.module.scss';
import ArrowLeft from '../Icons/ArrowLeft';
import { ArrowRight } from '../Icons/ArrowRight';
import step1Image from '../../assets/ftuestep1.png';
import step2Image from '../../assets/ftuestep2.png';
import step3Image from '../../assets/ftuestep3.png';
import step4Image from '../../assets/ftuestep4.png';
import gimmeCat from '../../assets/game-over-cat.png';
import { TermsOfService } from '../TermsOfService/TermsOfService';
import { useUserFlags } from '../../contexts/UserFlagsProvider';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';

const steps = [
  {
    title: 'Tap to Jump',
    description: 'Tap and hold the screen to make your cat jump from one platform to the next. Timing is everything!',
    image: step1Image,
  },
  {
    title: 'Purrfect Landing',
    description:
      'Land in the center for bonus points. The more precise, the higher your score. Multiple perfect jumps earn combos!',
    image: step2Image,
  },
  {
    title: 'Platform Variations',
    description: 'Watch out! Some platforms move or disappear. Stay sharp and time your jumps wisely.',
    image: step3Image,
  },
  {
    title: 'Collect Paws',
    description: 'Collect Paws from daily check-ins, referrals, and quests. Use them to unlock fun in-game rewards.',
    image: step4Image,
  },
];

export const FtueStepper = ({ setFtueSeen }: { setFtueSeen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showToS, setShowToS] = useState(false);

  const { userFlags, setHasAcceptedToSFlag, setHasSeenFTUEFlag } = useUserFlags();

  useEffect(() => {
    if (userFlags.hasAcceptedToS) {
      setTermsAccepted(true);
    }
  }, [userFlags.hasAcceptedToS]);

  useEffect(() => {
    setHasAcceptedToSFlag(termsAccepted);
  }, [setHasAcceptedToSFlag, termsAccepted]);

  return (
    <div className={styles.wrapper}>
      {showToS && <TermsOfService onClose={() => setShowToS(false)} />}
      <div className={styles.ftue}>
        <img src={gimmeCat} className={styles.catIllustration} />

        <Text as="h1" type="heading">
          {steps[currentStep].title}
        </Text>
        <Text type="body" as="p" className={styles.stepDescription}>
          {steps[currentStep].description}
        </Text>
        <img src={steps[currentStep].image} className={styles.stepImage} />

        <div className={styles.steps}>
          <button
            onClick={() =>
              setCurrentStep((current) => {
                if (current === 0) return 0;
                return current - 1;
              })
            }
          >
            <ArrowLeft color="#FF5546" />
          </button>
          {Array(steps.length)
            .fill('a')
            .map((_, index) => {
              return (
                <div
                  key={index}
                  className={currentStep === index ? styles.currentStep : styles.notCurrentStep}
                  onClick={() => {
                    setCurrentStep(index);
                  }}
                ></div>
              );
            })}
          <button
            className={styles.nextStep}
            onClick={() =>
              setCurrentStep((current) => {
                if (current === steps.length - 1) return steps.length - 1;
                return current + 1;
              })
            }
          >
            <ArrowRight color="#FF5546" />
          </button>
        </div>

        <label className={styles.terms}>
          <input
            type="checkbox"
            checked={termsAccepted}
            className={styles.checkbox}
            onChange={() => setTermsAccepted((accepted) => !accepted)}
          />
          <Text as="p" type="button">
            I agree to the
            <Button onClick={() => setShowToS(true)} className={styles.tosButton}>
              Terms & Conditions.
            </Button>
          </Text>
        </label>
        <Button
          primary
          fullWidth
          disabled={!termsAccepted}
          onClick={() => {
            setFtueSeen(true);
            setHasSeenFTUEFlag(true);
          }}
        >
          <Text as="span" type="button">
            Let's Play
          </Text>
        </Button>
      </div>
    </div>
  );
};
