import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.scss';
import { root } from '@/app/routes';
import { TrophyIcon } from '../Icons/TrophyIcon';
import { PawsNavIcon } from '../Icons/RewardsIcon';
import { AlertCircle } from '../Icons/AlertCircle';
import { ProfileIcon } from '../Icons/ProfileIcon';
import { GameTitle } from '../GameTitle/GameTitle';
import timer from '../../assets/timer.png';
import { usePageViewTracking } from '../../game-react/hooks/use-page-view-tracking';
import { FtueStepper } from '../Ftue/FtueStepper';
import cls from 'classnames';
import { CogIcon } from '../Icons/CogIcon';
import { useUserFlags } from '../../contexts/UserFlagsProvider';
import { useNakamaApi } from '../../contexts/useNakamaApi';
import { Button } from '../Button/Button';
import { useGenericLeaderboardData } from '../../hooks/use-leaderboard-data';
import { Contest } from '../Icons/Contest';
import { useTranslation } from 'react-i18next';
import { Text } from '../Text/Text';
import { ShopIcon } from '../Icons/ShopIcon';
import TelegramIcon from '../Icons/TelegramIcon';
import { useReferrals } from '@/contexts/ReferralsProvider';
import { Sparkles } from '../Icons/Sparkles';
import { useArcadeSdk } from '@/lib/arcade-react-sdk/ArcadeSdkProvider';
import { LeaderboardType } from '@/types/leaderboard';
import { TeamIcon } from '../Icons/TeamIcon';
import { getPartnerGroupsQueryOptions } from '@/hooks/use-partner-groups';
import { QueryClient } from 'react-query';
import { useLeaderboardAroundUser } from '@/hooks/use-leaderboard-around-user';
import { usePlayerData } from '@/hooks/use-player-leaderboard-data';

type HomeProps = {
  onGameStart: () => void;
  onDailyChallengeStart: () => void;
  onLeaderboard: () => void;
  onContest: () => void;
};

export const homeLoader = (queryClient: QueryClient) => async () => {
  const partnerGroupQueryOptions = getPartnerGroupsQueryOptions();

  const promises = [
    queryClient.getQueryData(partnerGroupQueryOptions.queryKey) ??
      (await queryClient.fetchQuery(partnerGroupQueryOptions)),
  ] as const;

  const [partnerGroups] = await Promise.all(promises);

  return {
    partnerGroups,
  };
};

export const Home = ({
  onGameStart,
  onDailyChallengeStart,
  onLeaderboard: onLeaderboardClick,
  onContest,
}: HomeProps) => {
  usePageViewTracking('home');
  const navigate = useNavigate();
  const { getPointsNotification, isReady } = useNakamaApi();
  const [ftueSeen, setFtueSeen] = useState(false);
  const [pawsNotifier, setPawsNotifier] = useState(false);
  const { data: _ } = useGenericLeaderboardData();
  const { data: _p } = usePlayerData();
  const { data: _l } = useLeaderboardAroundUser({ leaderboardId: LeaderboardType.DAILY });
  const { t } = useTranslation();
  const { userFlags } = useUserFlags();
  const { share, referralIncentive } = useReferrals();
  const { flags } = useArcadeSdk();

  useEffect(() => {
    const ftueSeen = userFlags.hasSeenFTUE;
    if (ftueSeen) {
      setFtueSeen(true);
    }
  }, [setFtueSeen, userFlags]);

  useEffect(() => {
    if (isReady) {
      (async () => {
        // Get which elements need notifications
        const payload = await getPointsNotification();
        if (payload) {
          setPawsNotifier(payload.available_claims);
        }
      })();
    }
  }, [getPointsNotification, isReady]);

  return (
    <div className={styles.home}>
      {!ftueSeen && <FtueStepper setFtueSeen={setFtueSeen} />}
      <div className={styles.gameTitle}>
        <GameTitle />
      </div>
      <div className={styles.actionsContainer}>
        {flags.dailyChallenge && (
          <div className={styles.gameActionsContainer}>
            <Button primary onClick={onGameStart} className={styles.startGame} playSound={'menu_item_select'}>
              {t('Start')}
            </Button>
            <Button
              primary
              onClick={onDailyChallengeStart}
              className={styles.dailyButton}
              playSound={'menu_item_select'}
            >
              <img src={timer} className={styles.dailyButtonImage} />
            </Button>
          </div>
        )}
        {!flags.dailyChallenge && (
          <Button primary onClick={onGameStart} className={styles.startGame} playSound={'menu_item_select'}>
            {t('Start')}
          </Button>
        )}
        <div className={styles.navigationContainer}>
          <NavigationButton text="Profile" onClick={() => navigate(root.profile.path)} icon={<ProfileIcon />} />
          <NavigationButton text="Ranks" onClick={onLeaderboardClick} icon={<TrophyIcon />} />
          {flags.playToPromote && (
            <NavigationButton text="Teams" onClick={() => navigate(root.teams.path)} icon={<TeamIcon />} />
          )}
          <NavigationButton
            text="Events"
            onClick={() => {
              if (flags.liveEventContests) {
                navigate(root.event.path);
              } else {
                onContest();
              }
            }}
            icon={<Contest />}
          />
          <NavigationButton
            text="Earn"
            onClick={() => navigate(root.rewards.path)}
            icon={<PawsNavIcon />}
            alert={pawsNotifier}
            sparkles={pawsNotifier}
          />
          <NavigationButton text="Shop" onClick={() => navigate(root.shop.path)} icon={<ShopIcon />} />
        </div>

        <Text as="div" type="mono" className={styles.privateBeta}>
          Open Beta
        </Text>
      </div>

      <Button
        onClick={share}
        className={cls(styles.navigationButton, styles.shareButton)}
        playSound={false}
        disabled={!referralIncentive}
      >
        <TelegramIcon />
      </Button>

      <Button
        className={cls(styles.navigationButton, styles.optionsButton)}
        onClick={() => navigate(root.options.path)}
      >
        <CogIcon />
      </Button>
    </div>
  );
};

type NavigationButtonProps = {
  onClick: () => void;
  icon: React.ReactElement;
  text: string;
  alert?: boolean;
  sparkles?: boolean;
};

const NavigationButton = ({ onClick, icon, text, alert = false, sparkles = false }: NavigationButtonProps) => {
  return (
    <Button
      className={cls(alert ? styles.alertContainerPulse : styles.alertContainer, styles.navigationButton)}
      onClick={onClick}
    >
      {sparkles && (
        <>
          <Sparkles className={styles.sparklesTop} />
          <Sparkles className={styles.sparklesBottom} />
        </>
      )}
      <div className={cls(styles.iconContainer, { [styles.alertBorder]: alert })}>{icon}</div>
      {alert && (
        <div className={styles.alertCircleWrapper}>
          <AlertCircle />
        </div>
      )}
      <Text as="span" type="mono" className={styles.navButtonText}>
        {text}
      </Text>
    </Button>
  );
};
