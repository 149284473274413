import { z } from 'zod';
import { PakoCompression } from './utils/compression';
import { gameStore } from '../store/gameStore';
import { GameTelemetry } from './types/game-telemetry';
import { restApiBase } from './utils/api';
import { authenticatedApiWithRetries } from '@/lib/api';
import { GameMode } from '@/game-raw/engine/game-run.ts';

const VersionInfoTypeEnum = z.enum([
  'Missing Header',
  'Matching Version',
  'Patch Mismatch',
  'Minor Mismatch',
  'Major Mismatch',
]);

const VersionInfoSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(VersionInfoTypeEnum.Values['Missing Header']),
  }),
  z.object({
    type: z.literal(VersionInfoTypeEnum.Values['Matching Version']),
    clientVersion: z.string(),
    serverVersion: z.string(),
  }),
  z.object({
    type: z.literal(VersionInfoTypeEnum.Values['Patch Mismatch']),
    clientVersion: z.string(),
    serverVersion: z.string(),
  }),
  z.object({
    type: z.literal(VersionInfoTypeEnum.Values['Minor Mismatch']),
    clientVersion: z.string(),
    serverVersion: z.string(),
  }),
  z.object({
    type: z.literal(VersionInfoTypeEnum.Values['Major Mismatch']),
    clientVersion: z.string(),
    serverVersion: z.string(),
  }),
]);

export type VersionInfoType = z.infer<typeof VersionInfoSchema>;

const PostScoreRequestResponseSchema = z.object({
  success: z.boolean(),
  versionInfo: VersionInfoSchema.optional(),
});

export type PostScoreRequestResponse = z.infer<typeof PostScoreRequestResponseSchema>;

export const postScore = async (score: number): Promise<void> => {
  const gameMode = gameStore.getState().gameRun!.getGameMode();

  const telemetry: GameTelemetry = {
    startTime: gameStore.getState().gameRun!.getStartTime(),
    endTime: Date.now(),
    events: gameStore.getState().eventCollector.events,
  };

  const body =
    gameMode === GameMode.Normal
      ? {
          score: score,
          gameMode: gameMode,
          telemetry: PakoCompression.compress(JSON.stringify(telemetry)),
        }
      : {
          time: Math.floor(gameStore.getState().scoreManager.timeScore * 1000),
          gameMode: gameMode,
        };

  try {
    const response = await authenticatedApiWithRetries.post(`${restApiBase}/score`, body);
    const parsedResponse = PostScoreRequestResponseSchema.parse(response.data);
    console.log(parsedResponse);

    // Reload if we have a breaking version mismatch between client and server
    if (
      parsedResponse.versionInfo?.type === 'Major Mismatch' ||
      parsedResponse.versionInfo?.type === 'Patch Mismatch'
    ) {
      window.location.reload();
    }
  } catch (error) {
    console.error('Error saving score: ', error);
    throw new Error('Error saving score');
  }
};
