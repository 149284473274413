import { Block } from './block';

export class MovingBlock extends Block {
  timer: number = 0;
  fromXAxis: boolean = false;

  public setMovementAxis(fromXAxis: boolean): void {
    this.fromXAxis = fromXAxis;
  }

  public update(dt: number) {
    super.update(dt);

    this.timer += dt * 2;
    if (!this.fromXAxis) {
      this.isoX = this.startPos.x + Math.sin(this.timer) * 100;
    } else {
      this.isoY = this.startPos.y + Math.sin(this.timer) * 100;
    }
    this.updatePosition();
  }
}
