import cat from '@/assets/game-over-cat.png';
import { ReactNode } from 'react';
import { Modal } from '../Modal/Modal';
import { usePageViewTracking } from '../../game-react/hooks/use-page-view-tracking';
import styles from './Events.module.scss';
import { Button } from '../Button/Button';
import { ArrowRight } from '../Icons/ArrowRight';
import { format } from 'date-fns';
import { Text } from '@/components/Text/Text';
import cls from 'classnames';

type EventsProps = {
  onClose: () => void;
  onClickEvent: (eventGroup: string) => void;
};

export function Events({ onClose }: EventsProps) {
  usePageViewTracking('events');

  return (
    <Modal onClose={onClose} title="Events">
      <div className={styles.eventsList}>
        <div className={styles.emptyState}>
          <img src={cat} className={styles.heroImage} />
          <Text as="h2" type="heading">
            More Events Coming Soon!
          </Text>
          <Text as="p" type="body">
            New events and prizes are on the way. <br />
            Stay tuned!
          </Text>
        </div>
      </div>
    </Modal>
  );
}

type EventCardProps = {
  img: string;
  title: string;
  endsAt: Date;
  onClick: () => void;
  children?: ReactNode;
};

export function EventCard({ img, title, endsAt, onClick, children }: EventCardProps) {
  const now = new Date();
  return (
    <div className={styles.eventCard}>
      <img src={img} className={styles.cardImg} />
      <div className={cls(styles.cardHeader, { [styles.borderBottom]: children !== undefined })}>
        <div>
          <h3 className={styles.cardTitle}>{title}</h3>
          <div>
            <span className={styles.tag}>contest</span>
            <span className={styles.endDate}>
              {endsAt > now ? 'ends' : 'ended'} {format(endsAt, 'MM/dd')}
            </span>
          </div>
        </div>
        <Button onClick={onClick}>
          <ArrowRight />
        </Button>
      </div>
      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
}
