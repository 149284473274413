import { z } from 'zod';
import { JUMP_RATING } from './jump-judger';

export enum EVENT_TYPE {
  JUMP = 'JUMP',
}

export type JumpEvent = {
  type: EVENT_TYPE.JUMP;
  tapHeldDuration: number;
  ratingEarned: JUMP_RATING;
  pointsEarned: number;
  distanceJumped: number;
  directionJumped: 'LEFT' | 'RIGHT';
};
const JumpEventSchema: z.ZodType<JumpEvent> = z.object({
  type: z.literal(EVENT_TYPE.JUMP),
  tapHeldDuration: z.number(),
  ratingEarned: z.nativeEnum(JUMP_RATING),
  pointsEarned: z.number(),
  distanceJumped: z.number(),
  directionJumped: z.enum(['LEFT', 'RIGHT']),
});

export type Event = JumpEvent;
export const EventSchema: z.ZodType<Event> = JumpEventSchema;

export class EventCollector {
  public events: Event[] = [];
  constructor() {}

  public reset(): void {
    this.events = [];
  }

  public collectEvent(event: Event) {
    this.events.push(event);
  }
}
