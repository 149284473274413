import { stringToJSONSchema } from '../../../utils/zod';
import { z } from 'zod';

export const TeamLeaderboardRecordDto = z.object({
  tg_partner_group_id: z.number(),
  score: z.number(),
  rank: z.number(),
  metadata: stringToJSONSchema.pipe(
    z.object({
      communityId: z.string(),
      type: z.string(),
      title: z.string(),
      logo: z.string(),
      horizontalLogo: z.string(),
      displayName: z.string(),
      description: z.string(),
      telegramLink: z.string(),
      costume: z.string().optional(),
      isActive: z.boolean(),
      isPartner: z.boolean(),
      hasContest: z.boolean(),
      reminderType: z.string().optional(),
    })
  ),
  expiry_time: z.number(),
});
export type TeamLeaderboardRecordDto = z.infer<typeof TeamLeaderboardRecordDto>;

export const TeamLeaderboardRecordsDto = z.union([
  z.object({
    records: z.array(TeamLeaderboardRecordDto),
    rank_count: z.number().int(),
  }),
  z.object({}),
]);
export type TeamLeaderboardRecordsDto = z.infer<typeof TeamLeaderboardRecordsDto>;
