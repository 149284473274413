import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { root } from '@/app/routes';
import { usePageViewTracking } from '../../game-react/hooks/use-page-view-tracking';
import { setBgmVolume, setAllSoundVolume } from '../../game-raw/StartGame';

import styles from './Options.module.scss';
import { MusicIcon } from '../Icons/MusicIcon';
import Slider from '../Slider/Slider';
import { SoundIcon } from '../Icons/SoundIcon';
import { useSettings } from '../../contexts/SettingsProvider';
import { Modal } from '../Modal/Modal';
import { TermsOfService } from '../TermsOfService/TermsOfService';
import { Button } from '../Button/Button';
import { ArrowRight } from '../Icons/ArrowRight';
import { Text } from '../Text/Text';

const Options = () => {
  usePageViewTracking('options');

  const { settings, setMusicVolume, setSoundVolume } = useSettings();

  const navigate = useNavigate();

  const [showToS, setShowToS] = useState(false);

  return (
    <Modal onClose={() => navigate(root.home.path)} title="Game Options" fullScreen={false}>
      <Text as="h2" type="heading" className={styles.smallTitle}>
        Audio Options
      </Text>

      <div className={styles.sliderWrapper}>
        <div className={styles.sliderOption}>
          <div className={styles.labelWrapper}>
            <MusicIcon />
            <Text as="span" type="body" className={styles.label}>
              Music
            </Text>
          </div>

          <Slider
            value={settings.musicVolume}
            onChange={(value) => {
              setMusicVolume(value);
              setBgmVolume(value / 100);
            }}
          />
        </div>

        <div className={styles.sliderOption}>
          <div className={styles.labelWrapper}>
            <SoundIcon />
            <Text as="span" type="body" className={styles.label}>
              Sound
            </Text>
          </div>
          <Slider
            value={settings.soundVolume}
            onChange={(value) => {
              setSoundVolume(value);
              setAllSoundVolume(value / 100);
            }}
          />
        </div>
      </div>

      <h2 className={styles.smallTitle}>General</h2>
      {showToS && <TermsOfService onClose={() => setShowToS(false)} />}
      <Button onClick={() => setShowToS(true)} className={styles.termsButton}>
        <Text as="span" type="button">
          Terms and Conditions
        </Text>
        <ArrowRight />
      </Button>
    </Modal>
  );
};

export default Options;
