import pako from 'pako';

export class PakoCompression {
  public static compress(data: string): string {
    const uint8Array = new TextEncoder().encode(data);
    const compressed = pako.deflate(uint8Array);
    return btoa(String.fromCharCode.apply(null, Array.from(compressed)));
  }

  public static decompress(compressedData: string): string {
    const charData = atob(compressedData)
      .split('')
      .map((x) => x.charCodeAt(0));
    const uint8Array = new Uint8Array(charData);
    const decompressed = pako.inflate(uint8Array);
    return new TextDecoder().decode(decompressed);
  }
}
