import React from 'react';

type Props = {
  fill?: string;
  borderFill?: string;
};

export const Contest: React.FC<Props> = React.memo(({ fill = '#202025', borderFill = '#F0ECE7' }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4402 13.1621L14.3055 13.6199C14.5199 14.1442 14.838 14.5995 15.2604 14.9834L15.2634 14.9862C16.1332 15.7459 17.5242 16.2418 19.4014 16.4986C19.9068 16.5761 20.2089 16.6971 20.3568 16.832C20.492 16.9552 20.5789 17.1698 20.5789 17.5242C20.5789 17.8715 20.457 18.1226 20.2254 18.3202C19.9749 18.534 19.5653 18.7106 18.9573 18.8179C17.9247 18.9999 16.8725 18.9338 15.7986 18.6125C15.4852 18.518 15.1636 18.583 14.8628 18.8078C14.5517 19.0403 14.4631 19.3733 14.3544 19.7139L14.0473 20.8394C13.9489 21.2917 14.1495 21.6511 14.3544 21.9831L14.3587 21.9898C14.5816 22.3223 14.8834 22.5379 15.2635 22.6223C16.3337 22.8739 17.8657 22.8518 19.167 22.6223C21.1864 22.2662 22.7497 21.5962 23.8128 20.5755C24.8889 19.5422 25.4106 18.1906 25.4106 16.5658C25.4106 15.1618 25.0087 14.0813 24.1825 13.3575C23.3644 12.6409 21.9465 12.1791 19.9766 11.9405C19.5344 11.8844 19.2305 11.7615 19.0394 11.5924C18.8542 11.4286 18.7619 11.2108 18.7619 10.923C18.7619 10.5923 18.8736 10.3587 19.0777 10.1786C19.2994 9.98307 19.6651 9.81992 20.2157 9.72282C21.2011 9.54906 22.3046 9.55326 23.5285 9.74217C23.8787 9.80404 24.2194 9.71114 24.5193 9.44888L24.522 9.4465C24.8269 9.17415 24.9912 8.83741 24.9912 8.46154V7.41796C24.9912 6.99393 24.8698 6.6275 24.6287 6.32626C24.3868 6.00976 24.0649 5.82117 23.667 5.77349L23.6643 5.77317C22.5875 5.6569 21.3672 5.72096 20.006 5.96097C18.268 6.26742 16.8667 6.85023 15.8312 7.73431C15.8715 7.8173 15.8949 7.90598 15.9014 8.00036C15.8345 8.05957 15.7695 8.12008 15.7063 8.18189C14.7275 9.14958 14.2381 10.3291 14.2381 11.7206C14.2381 12.2472 14.3054 12.7277 14.4402 13.1621Z"
        fill={fill}
      />
      <path
        d="M10.8389 8.97174L9.16247 16.4944L7.6141 9.72143C7.50339 9.20861 7.21747 8.79744 6.77407 8.53073C6.34491 8.24696 5.84889 8.16271 5.33187 8.25387L3.27643 8.6163C2.74894 8.70932 2.32062 9.01445 2.02242 9.46301L2.02236 9.46297L2.01801 9.46975L2.012 9.47912L2.01194 9.47909L2.00769 9.48595C1.72732 9.9387 1.63577 10.4466 1.79211 10.9482L1.79206 10.9482L1.79441 10.9554L5.90394 23.4735C6.05366 23.9611 6.3525 24.3499 6.78929 24.6056L6.78927 24.6056L6.79522 24.609L6.80022 24.6118L6.80019 24.6119L6.80622 24.6152C7.25547 24.8618 7.75557 24.9294 8.27407 24.838L10.2876 24.4829C10.7954 24.3934 11.2508 24.1561 11.6401 23.7929C12.0388 23.4316 12.3256 22.9922 12.4827 22.4857L12.4828 22.4858L12.4849 22.4787L15.0676 13.7007L15.0722 13.684L16.3546 8.69469C16.6486 7.55087 15.7002 6.4694 14.5278 6.61155C14.5166 6.6129 14.5055 6.61463 14.4945 6.61674L12.9628 6.90833C12.4426 7.00084 11.9817 7.25512 11.5932 7.63865C11.2147 8.00945 10.9601 8.45795 10.8403 8.96567L10.8402 8.96566L10.8389 8.97174Z"
        fill={fill}
        stroke={borderFill}
        strokeLinejoin="round"
      />
    </svg>
  );
});
