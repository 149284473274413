import { clientVersion } from '../version';
import { StoreApi } from 'zustand';
import { GameState } from '../store/gameStore';

if (window.Telegram.WebApp.initDataUnsafe.user?.id) {
  gtag('set', 'user_id', window.Telegram.WebApp.initDataUnsafe.user.id.toString());
}

const baseAnalyticsEventProps = {
  bot_context: import.meta.env.VITE_TELEGRAM_BOT_NAME,
  app_version: clientVersion,
};

type GameEventName = `game_run_${string}`;

export class Analytics {
  public static get gtag(): Gtag.Gtag {
    return gtag;
  }
  public static recordPageView(
    page: string,
    additionalProps?: {
      [key: string]: string;
    }
  ) {
    gtag('event', 'page_view', {
      page_title: page,
      page_location: page,
      page_path: '',
      page_referrer: '',
      ...additionalProps,
      ...baseAnalyticsEventProps,
    });
  }

  public static recordImpression(
    thing: string,
    additionalProps?: {
      [key: string]: string;
    }
  ) {
    gtag('event', 'impression', {
      impression_type: thing,
      ...additionalProps,
      ...baseAnalyticsEventProps,
    });
  }

  public static async recordGameEvent(
    eventName: GameEventName,
    gameStore: StoreApi<GameState>,
    additionalProps?: {
      [key: string]: string;
    }
  ) {
    const gameMode = gameStore.getState().gameRun?.getGameMode();
    const metadata = { gamePlayState: gameMode!.toString() };

    if (eventName === 'game_run_start') {
      await gameStore.getState().satoriClient!.event(gameStore.getState().satoriSession!, {
        name: 'gameStarted',
        value: gameStore.getState().gameSessionRunCount.toString(),
        metadata,
      });
    }
    if (eventName === 'game_run_end') {
      await gameStore.getState().satoriClient!.event(gameStore.getState().satoriSession!, {
        name: 'gameFinished',
        value: gameStore.getState().gameSessionRunCount.toString(),
        metadata,
      });
      await gameStore.getState().satoriClient!.event(gameStore.getState().satoriSession!, {
        name: 'gameFinalScore',
        value: gameStore.getState().scoreManager.score.toString(),
        metadata,
      });
    }
    gtag('event', eventName, {
      ...additionalProps,
      ...baseAnalyticsEventProps,
    });
  }
}
