import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const shouldEnableSentry = import.meta.env.PROD;
if (shouldEnableSentry) {
  Sentry.init({
    dsn: 'https://1d073bafea302c6b76ab91e872d681bd@o1132703.ingest.us.sentry.io/4507889870635008',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // FIXME: I cnanot figure out how to make these headers work so disabling for now
    tracePropagationTargets: [
      // 'localhost',
      // /^https:\/\/tzbqcqx6rk.execute-api\.us-east-1\.amazonaws\.com\/dev/, // Staging
      // /^https:\/\/telegram-jump-game\.us-east1\.nakamacloud\.io/,
      // //    /^https:\/\/telegram-jump-game\.us-east1-b\.satoricloud\.io/,
      // /^https:\/\/j59e1cq8d9\.execute-api\.us-east-1\.amazonaws\.com\/dev/, // Production
      // /^https:\/\/prod-mt-arcade\.us-east1\.nakamacloud\.io/,
      // //    /^https:\/\/prod-mt-liveops\.us-east1-b\.satoricloud\.io/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  if (Telegram.WebApp.initDataUnsafe.user?.id) {
    Sentry.setUser({ id: Telegram.WebApp.initDataUnsafe.user.id });
  }
}

export function captureMessage(message: string, extra?: Record<string, unknown>) {
  if (shouldEnableSentry) {
    logLarge(`Sending Sentry info message: ${message}`);
    Sentry.captureMessage(message, {
      extra,
    });
  }
}

const styledLog = (message: string, style: string) => {
  console.log(`%c${message}`, style);
};

const logLarge = (message: string) => {
  styledLog(message, 'font-size: 13px; color: #000; font-weight: bold;');
};
