import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '@/styles/app/Profile.module.scss';
import background from '@/assets/profile-background.png';
import { useNakama } from '@/contexts/NakamaProvider.tsx';
import { usePageViewTracking } from '@/game-react/hooks/use-page-view-tracking';
import { EditCommunity } from '@/features/profile/EditCommunity';
import { Modal } from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { EditIcon } from '@/components/Icons/EditIcon';
import { Text } from '@/components/Text/Text';
import { ProfileSetUsername } from '@/features/profile/components/ProfileSetUsername';
import { PawsIcon } from '@/components/Icons/PawsIcon';
import { useTotalHotpotPoints, getTotalHotpotPointsQueryOptions } from '@/features/profile/api/get-total-points';
import { QueryClient } from 'react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { root } from '@/app/routes';
import { useUserAccount } from '@/hooks/use-user-account';
import { usePlayerData } from '@/hooks/use-player-leaderboard-data';

export const profileLoader = (queryClient: QueryClient) => async (_args: LoaderFunctionArgs) => {
  //const userId = params.userId as string;

  const totalHotpotPointsQuery = getTotalHotpotPointsQueryOptions();

  const promises = [
    queryClient.getQueryData(totalHotpotPointsQuery.queryKey) ?? (await queryClient.fetchQuery(totalHotpotPointsQuery)),
  ] as const;

  const [totalHotpotPoints] = await Promise.all(promises);

  return {
    totalHotpotPoints,
  };
};

export const Profile: React.FC = () => {
  usePageViewTracking('profile');
  const { session, client, hiroClient } = useNakama();
  const [editUsernameOpen, setEditUsernameOpen] = useState(false);
  const { data: playerData } = usePlayerData();
  const navigate = useNavigate();
  const [communityModal, setCommunityModal] = useState(false);
  const totalPointsQuery = useTotalHotpotPoints();
  const { userAccount } = useUserAccount();

  if (!session || !client || !hiroClient) {
    return;
  }

  const hasLeaderboardScore = () => playerData?.highScore && playerData.highScore > 0;

  return communityModal ? (
    <EditCommunity onClose={() => setCommunityModal(false)} />
  ) : (
    <Modal
      title={session.username || 'Anonymous'}
      onClose={() => navigate(root.home.path)}
      fullScreen={false}
      attachment={
        <Button className={styles.editIcon} onClick={() => setEditUsernameOpen(true)}>
          <EditIcon />
        </Button>
      }
    >
      {editUsernameOpen ? (
        <ProfileSetUsername onClose={() => setEditUsernameOpen(false)} defaultValue={session.username || ''} />
      ) : (
        <></>
      )}

      <div className={styles.personalBestContainer}>
        <img src={background} className={styles.background} />
        <Text as="h3" type="mono" className={styles.title}>
          Personal Best
        </Text>
        <div className={styles.personalBestNumber}>
          {hasLeaderboardScore() ? `#${playerData?.leaderboardPosition}` : '-'}
        </div>
        <span className={styles.points}>{hasLeaderboardScore() ? `${playerData?.highScore}` : '-'}</span>
      </div>
      <div className={styles.totalPointsContainer}>
        <Text as="h3" type="mono" className={styles.title}>
          TOTAL PAWS COLLECTED
        </Text>
        <div className={styles.totalPointsHorizontal}>
          <div className={styles.pawsWrapper}>
            <PawsIcon />
          </div>
          <span className={styles.totalPoints}>{totalPointsQuery.data?.totalPoints}</span>
        </div>
      </div>

      <div>
        <Text as="h3" type="mono" className={styles.title}>
          Your community
        </Text>
        <div className={styles.community}>
          <p className={styles.communityName}>{userAccount?.community?.displayName}</p>
          <Button secondary onClick={() => setCommunityModal(true)}>
            <Text as="span" type="button">
              Edit
            </Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
