import React, { Suspense } from 'react';
import { QueryClientProvider } from 'react-query';
import { Loading } from '@/app/routes/loading';

import { NakamaProvider } from '@/contexts/NakamaProvider.tsx';
import { ReferralsProvider } from '@/contexts/ReferralsProvider.tsx';
import { ToastProvider } from '@/contexts/ToastProvider.tsx';
import { SettingsProvider } from '@/contexts/SettingsProvider.tsx';
import { UserFlagsProvider } from '@/contexts/UserFlagsProvider';
import { InitializedNakamaClients } from '@/lib/arcade-sdk/loaders/nakama-loader';
import { queryClient } from '@/api/utils/query-client';

type AppProviderProps = {
  children: React.ReactNode;
  nakamaClients: InitializedNakamaClients;
};

export const AppProvider = ({ children, nakamaClients }: AppProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <NakamaProvider nakamaClients={nakamaClients}>
        <ToastProvider>
          <ReferralsProvider>
            <SettingsProvider>
              <UserFlagsProvider>
                <Suspense fallback={<Loading />}>{children}</Suspense>
              </UserFlagsProvider>
            </SettingsProvider>
          </ReferralsProvider>
        </ToastProvider>
      </NakamaProvider>
    </QueryClientProvider>
  );
};
