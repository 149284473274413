export function ArrowRight({ color = '#342236' }: { color?: string }) {
  return (
    <svg width="16" height="17" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.20273 10.9587C3.97206 11.1883 3.59892 11.1875 3.36929 10.9568V10.9568C3.13966 10.7262 3.14049 10.353 3.37116 10.1234L7.26106 6.2509L3.38856 2.361C3.15893 2.13034 3.15976 1.75719 3.39042 1.52756V1.52756C3.62109 1.29793 3.99423 1.29877 4.22386 1.52943L8.46249 5.7871C8.71955 6.04531 8.71861 6.46302 8.4604 6.72008L4.20273 10.9587Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}
