import { Application, Container, Graphics } from 'pixi.js';

// This class creates a transparent box around the game that receives mouse/touch input
export class InputCatcher extends Container {
  public graphics: Graphics = new Graphics();

  constructor(app: Application) {
    super();
    this.eventMode = 'static';
    this.addChild(this.graphics);
    this.resize(app);
  }

  public resize(app: Application) {
    const g: Graphics = this.graphics;
    g.clear();
    g.rect(0, 0, app.canvas.width, app.canvas.height);
    g.fill({ color: 0x00ff00, alpha: 0 });

    const scale = 1 / app.stage.scale.x;
    this.scale.set(scale);
    this.x = -app.stage.x * scale;
  }
}
