import { getPartnerGroups } from '@/api/domains/groups/get-partner-groups';
import { queryKeys } from '@/api/queryKeys';
import { useQuery } from 'react-query';

export const getPartnerGroupsQueryOptions = () => {
  return {
    queryKey: queryKeys.getPartnerGroups(),
    queryFn: getPartnerGroups,
  };
};

export type PartnerGroup = {
  id: string;
  telegramId: string;
  type: string;
  displayName: string;
  description: string;
  isActive: boolean;
  isPartner: boolean;
  hasContest: boolean;
  telegramLink?: string;
  logo?: string;
  horizontalLogo?: string;
  costume?: string;
};

type UsePartnerGroup = {
  partnerGroups: PartnerGroup[] | null;
  getPartnerGroup: (id?: string) => PartnerGroup | null;
};

export const usePartnerGroups = (): UsePartnerGroup => {
  const query = useQuery({ ...getPartnerGroupsQueryOptions() });

  switch (query.status) {
    case 'success': {
      const partnerGroups = query.data.map((group) => ({
        id: group.id,
        telegramId: group.name,
        type: group.metadata.type,
        displayName: group.metadata.displayName,
        description: group.metadata.description,
        telegramLink: group.metadata.telegramLink,
        logo: group.metadata.logo,
        horizontalLogo: group.metadata.horizontalLogo,
        costume: group.metadata.costume,
        isActive: group.metadata.isActive,
        isPartner: group.metadata.isPartner,
        hasContest: group.metadata.hasContest,
      }));

      return {
        partnerGroups,
        getPartnerGroup: (id?: string) => partnerGroups.find((group) => group.telegramId === id) || null,
      };
    }
    case 'loading':
    case 'error':
    default:
      return { partnerGroups: null, getPartnerGroup: () => null };
  }
};
