import { debounce, throttle } from 'lodash';
import { postUserTelemetry } from '@/api/user-telemetry';

export class UserTelemetry {
  private eventQueue: EventSummary[] = [];
  private sendOnInactivity: () => void;
  private sendOnInterval: () => void;

  public constructor() {
    // Debounce: wait for 2 seconds of inactivity
    this.sendOnInactivity = debounce(() => this.processBatch(), 2000);
    // Throttle: ensure batch is sent every 20 seconds
    this.sendOnInterval = throttle(() => this.processBatch(), 20000);
  }

  public queueEvent = (event: Event) => {
    this.eventQueue.push(this.getSummaryFromEvent(event));
    this.sendOnInactivity();
    this.sendOnInterval();
  };

  private getSummaryFromEvent(event: Event): EventSummary {
    const baseInfo = {
      eventType: event.type,
      timestamp: event.timeStamp,
    };

    if (event instanceof PointerEvent) {
      return {
        ...baseInfo,
        eventType: event.type as 'pointerdown' | 'pointerup' | 'pointermove' | 'pointercancel',
        pointerType: event.pointerType,
        coordinates: {
          x: event.clientX,
          y: event.clientY,
        },
      };
    } else if (event instanceof KeyboardEvent) {
      return {
        ...baseInfo,
        eventType: event.type as 'keydown' | 'keyup',
        key: event.code,
      };
    }
    return baseInfo;
  }

  private processBatch() {
    if (this.eventQueue.length > 0) {
      try {
        // not awaiting on the async postUserTelemetry to never hold up the caller
        postUserTelemetry([...this.eventQueue]);
      } catch (_e) {
        // do nothing, should not break gameplay for telemetry
      }
      this.eventQueue.length = 0;
    }
  }
}

type BaseEvent = {
  eventType: string;
  timestamp: number;
};

type MouseEvent = BaseEvent & {
  eventType: 'pointerdown' | 'pointerup' | 'pointermove' | 'pointercancel';
  pointerType: string;
  coordinates: { x: number; y: number };
};

type KeyEvent = BaseEvent & {
  eventType: 'keydown' | 'keyup';
  key: string;
};

export type EventSummary = MouseEvent | KeyEvent | BaseEvent;
