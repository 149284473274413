export enum BlockType {
  STARTING = 'STARTING',
  NORMAL = 'NORMAL',
  MOVING = 'MOVING',
  SHAKING = 'SHAKING',
  BRIDGE = 'BRIDGE',
}

export enum BlockShape {
  SQUARE = 'SQUARE',
  CIRCLE = 'CIRCLE',
  DONUT = 'DONUT',
}

export enum BlockSpawnDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum MovementAxis {
  X = 'X',
  Y = 'Y',
}

export type StartingBlockModel = {
  type: BlockType.STARTING;
  shape: BlockShape.SQUARE;
  size: number;
  scoreValue: number;
};

export type NormalBlockModel = {
  type: BlockType.NORMAL;
  shape: BlockShape;
  size: number;
  distance: number;
  direction: BlockSpawnDirection;
  longJump: boolean;
  scoreValue: number;
};

export type MovingBlockModel = {
  type: BlockType.MOVING;
  shape: BlockShape;
  size: number;
  distance: number;
  direction: BlockSpawnDirection;
  movementAxis: MovementAxis;
  scoreValue: number;
};

export type ShakingBlockModel = {
  type: BlockType.SHAKING;
  shape: BlockShape;
  size: number;
  distance: number;
  direction: BlockSpawnDirection;
  scoreValue: number;
};

export type BridgeBlockModel = {
  type: BlockType.BRIDGE;
  shape: BlockShape.SQUARE;
  size: number;
  distance: number;
  count: number;
  direction: BlockSpawnDirection;
  scoreValue: number;
};

export type BlockModel =
  | StartingBlockModel
  | NormalBlockModel
  | MovingBlockModel
  | ShakingBlockModel
  | BridgeBlockModel;
