import { PartnerGroup } from '@/hooks/use-partner-groups';

export enum EventType {
  TOURNAMENT,
  CONTEST,
}

export type EventPrize = {
  title: string;
  description: string;
};

export type CommunityEvent = {
  id: string;
  title: string;
  splashImage: string;
  type: string;
  description: string;
  prize: EventPrize;
  startTime: Date;
  endTime: Date;
  organizers: PartnerGroup[];
};

export enum EventPeriod {
  ACTIVE,
  PAST,
  UPCOMING,
}
