import { useNakama } from './NakamaProvider';
import {
  Achievement,
  AchievementsUpdateRequest,
  AchievementList,
  AchievementsClaimRequest,
} from '@metatheoryinc/hiro-js';
import { ApiExperiment } from '@metatheoryinc/satori-js/api';

import * as API from '../types/nakama-api';
import { useCallback } from 'react';
import { Tournament, TournamentList } from '@metatheoryinc/nakama-js';

export const TOURNAMENT_CATEGORY = 1;

export enum TournamentPeriod {
  ACTIVE = 0,
  PAST = 1,
  UPCOMING = 2,
}

export type TournamentExtended = Tournament & {
  contest_type?: string;
};

export function useNakamaApi() {
  const { client, session, hiroClient, satoriClient, satoriSession, isReady } = useNakama();

  const getPointsNotification = useCallback(async (): Promise<API.PointsNotifierPayload | undefined> => {
    if (!client || !session) {
      return undefined;
    }
    const response = await client.rpc(session, 'get_points_notifier', {});
    return response.payload! as API.PointsNotifierPayload;
  }, [client, session]);

  const getPointsReward = useCallback((achievement: Achievement): number => {
    const points = achievement.available_rewards?.guaranteed?.currencies!['points'];
    if (points?.count?.min === undefined) {
      throw new Error('Reward not found');
    }
    return parseInt(points.count?.min);
  }, []);

  const getAchievement = useCallback(
    async (achievement_id: string): Promise<Achievement | undefined> => {
      if (!session || !hiroClient) {
        return undefined;
      }
      const list = await hiroClient.achievementsGet(session);
      const achievement = list?.repeat_achievements![achievement_id];

      return achievement;
    },
    [session, hiroClient]
  );

  const getAchievementList = useCallback(async (): Promise<AchievementList> => {
    if (!session || !hiroClient) {
      return new AchievementList();
    }
    return await hiroClient.achievementsGet(session);
  }, [session, hiroClient]);

  const calculateTimeLeftToReset = useCallback((achievement: Achievement): number => {
    if (achievement.reset_time_sec === undefined) {
      return 0;
    }

    /**
     * The Date constructor expects the unix timestamp in milliseconds. So, we
     * multiply by 1000.
     */
    const resetTime = new Date(parseInt(achievement.reset_time_sec) * 1000);
    const now = new Date();
    const difference = resetTime.getTime() - now.getTime();

    return Math.max(difference, 0);
  }, []);

  const updateAchievement = useCallback(
    async (achievementId: string, amount: number): Promise<void> => {
      if (!session || !hiroClient) {
        return;
      }
      const req = new AchievementsUpdateRequest();
      req.ids = [achievementId];
      req.setAmountNumeric(amount);

      await hiroClient.achievementsUpdate(session, req);
    },
    [session, hiroClient]
  );

  const claimAchievement = useCallback(
    async (achievementId: string): Promise<void> => {
      if (!session || !hiroClient) {
        return;
      }
      const req = new AchievementsClaimRequest();
      req.ids = [achievementId];
      await hiroClient.achievementsClaim(session, req);
    },
    [session, hiroClient]
  );

  const getLiveExperiment = useCallback(
    async (name: string): Promise<ApiExperiment | undefined> => {
      console.log('getting experiments');
      if (!satoriClient || !satoriSession) {
        console.log('got some nulls');
        return undefined;
      }
      const oneExpeiment = await satoriClient.getExperiments(satoriSession, [name]);
      return oneExpeiment.experiments?.[0];
    },
    [satoriClient, satoriSession]
  );

  const getTournaments = useCallback(
    async (period = TournamentPeriod.ACTIVE, category = TOURNAMENT_CATEGORY): Promise<TournamentExtended[]> => {
      if (!client || !session) {
        return [];
      }

      const result = await client.rpc(session, 'rpc_get_tournaments', { period, category });
      const tournamentList = result.payload as TournamentList;
      return tournamentList.tournaments || [];
    },
    [client, session]
  );

  const isClaimed = useCallback((achievement: Achievement): boolean => {
    return achievement.claim_time_sec !== undefined;
  }, []);

  return {
    getPointsNotification,
    getPointsReward,
    getAchievement,
    getAchievementList,
    getTournaments,
    updateAchievement,
    claimAchievement,
    getLiveExperiment,
    calculateTimeLeftToReset,
    isClaimed,
    isReady,
  };
}
