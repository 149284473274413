import { getTeamsLeaderboard } from '@/api/domains/teams/get-teams-leaderboard';
import { queryKeys } from '@/api/queryKeys';
import { useQuery } from 'react-query';
import { LeaderboardItem } from '@/types/leaderboard';
import { usePartnerGroups } from '@/hooks/use-partner-groups';

export const getTeamsLeaderboardQueryOptions = () => {
  return {
    queryKeys: queryKeys.getTeamsLeaderboard(),
    queryFn: () => getTeamsLeaderboard({}),
  };
};

export const useTeamsLeaderboard = (): { records: LeaderboardItem[] | null } => {
  const { getPartnerGroup } = usePartnerGroups();
  const query = useQuery(queryKeys.getTeamsLeaderboard(), () => {
    return getTeamsLeaderboard({});
  });

  switch (query.status) {
    case 'success': {
      const { data } = query;

      const leaderboardItems: LeaderboardItem[] | null =
        'records' in data
          ? data.records.map((item) => {
              const group = getPartnerGroup(item.tg_partner_group_id.toString());
              return {
                id: item.tg_partner_group_id.toString(),
                name: group?.displayName ?? '',
                score: item.score,
                rank: item.rank,
                image: group?.logo,
                communityId: item.tg_partner_group_id,
              };
            })
          : null;

      return {
        records: leaderboardItems,
      };
    }
    default: {
      return {
        records: null,
      };
    }
  }
};
