// GimmecatBrandingConfig - The configuration of branded boxes that appear in Gimmecat
import { z } from 'zod';

export const GimmecatBrandingConfig = z.object({
  brandingDefinitions: z.array(
    z.object({
      brandingDefinitionId: z.string(),
      darkColor: z.string().regex(new RegExp('^#([A-Fa-f0-9]{6})$')),
      outlineColor: z.string().regex(new RegExp('^#([A-Fa-f0-9]{6})$')),
      sideColor: z.string().regex(new RegExp('^#([A-Fa-f0-9]{6})$')),
      sideImageUrl: z.string().optional(),
      topColor: z.string().regex(new RegExp('^#([A-Fa-f0-9]{6})$')),
      topImageUrl: z.string(),
    })
  ),
  brandingFrequencyTable: z.array(
    z.object({ brandingDefinitionId: z.string(), percentage: z.number().int().gte(0).lte(100) })
  ),
  minimumGapBetweenBrandedBlocks: z.number().int().gte(0),
});
export type GimmecatBrandingConfig = z.infer<typeof GimmecatBrandingConfig>;
