import { stringToJSONSchema } from '@/utils/zod';
import { z } from 'zod';

export const PartnerGroupDto = z.object({
  id: z.string(),
  name: z.string(), // beware! this name is actually the telegram id of the group, not the display name
  metadata: stringToJSONSchema.pipe(
    z.object({
      communityId: z.string(),
      type: z.string(),
      title: z.string(),
      logo: z.string(),
      horizontalLogo: z.string(),
      displayName: z.string(),
      description: z.string(),
      telegramLink: z.string(),
      costume: z.string().optional(),
      isActive: z.boolean(),
      isPartner: z.boolean(),
      hasContest: z.boolean(),
    })
  ),
});
export type PartnerGroupDto = z.infer<typeof PartnerGroupDto>;

export const GetPartnerGroupsResponseDto = z.array(PartnerGroupDto);
export type GetPartnerGroupsResponseDto = z.infer<typeof GetPartnerGroupsResponseDto>;
