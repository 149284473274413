import styles from './TelegramButton.module.scss';
import { openTelegramLink } from '@/utils/telegram';
import { Button } from '@/components/Button/Button';
import { TelegramRoundIcon } from '@/components/Icons/TelegramRoundIcon';
import { Analytics } from '@/utils/analytics';
import { useNakama } from '@/contexts/NakamaProvider';

type TelegramButtonProps = {
  telegramLink: string;
  communityName: string;
};

export const TelegramButton = ({ telegramLink, communityName }: TelegramButtonProps) => {
  const { satoriClient, satoriSession } = useNakama();

  const recordEvent = async (community: string) => {
    Analytics.recordImpression('click-telegram-link', {
      community,
    });

    if (satoriClient && satoriSession) {
      await satoriClient.event(satoriSession, {
        name: 'clickTelegramLink',
        value: community,
      });
    }
  };

  return (
    <Button
      onClick={() => {
        recordEvent(communityName);
        openTelegramLink(telegramLink);
      }}
      className={styles.telegramButton}
      secondary
    >
      <TelegramRoundIcon />
    </Button>
  );
};
