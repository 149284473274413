import { sdk } from '@/gimmecat';
import { TournamentRpcPayloadZod } from './types';
import { z } from 'zod';

const GET_TOURNAMENT_RPC = 'rpc_get_tournament';

export const getEvent = async (id: string): Promise<z.infer<typeof TournamentRpcPayloadZod>> => {
  if (!sdk.nakamaClients) {
    throw new Error('getEvent called before nakama clients initialized');
  }

  const { nakamaClient, nakamaSession } = sdk.nakamaClients;

  const tournament = await nakamaClient.rpc(nakamaSession, GET_TOURNAMENT_RPC, {
    id,
  });

  return TournamentRpcPayloadZod.parse(tournament.payload);
};
