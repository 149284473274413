import gsap from 'gsap';
import { BitmapText, Container, Graphics, TextStyle } from 'pixi.js';
import { ScoreManager } from '../engine/score-manager';
import { ThemeColors } from '../../api/player';

export class ComboMeter extends Container {
  readonly barWidth = 512;
  readonly barHeight = 64;
  readonly radius = 24;
  comboBG: Container = new Container();
  comboBar: Container = new Container();
  comboGraphics: Graphics = new Graphics();
  comboGraphics2: Graphics = new Graphics();
  comboText: BitmapText;
  barVal: number = 0;
  barVal2: number = 0;
  prevVal: number = 0;

  constructor() {
    super();

    const initComboBG = () => {
      const graphics = new Graphics();
      graphics.roundRect(0, 0, this.barWidth, this.barHeight, this.radius);
      graphics.fill(0xfffbf4);
      graphics.roundRect(8, 8, this.barWidth - 16, this.barHeight - 16, this.radius - 4);
      graphics.cut();
      this.comboBG.addChild(graphics);
    };

    const initComboBar = () => {
      // yellow effect bar
      const graphics2 = this.comboGraphics2;
      graphics2.roundRect(8, 8, this.barWidth - 16, this.barHeight - 16, this.radius - 4);
      graphics2.fill(0xffff60);
      this.comboBar.addChild(graphics2);

      // red main bar
      const graphics = this.comboGraphics;
      graphics.roundRect(8, 8, this.barWidth - 16, this.barHeight - 16, this.radius - 4);
      graphics.fill(ThemeColors.uiAccentText);
      this.comboBar.addChild(graphics);
    };

    initComboBG();
    initComboBar();

    this.pivot.set(256, 32);
    this.addChild(this.comboBG);
    this.addChild(this.comboBar);

    // Combo text
    this.comboText = new BitmapText({
      text: 'COMBO',
      style: new TextStyle({
        fontFamily: 'Rubik-Bold',
        fontSize: 64,
        fill: ThemeColors.uiAccentText,
      }),
    });
    this.comboText.pivot.set(this.comboText.width * 0.5, this.comboText.height * 0.5);
    this.comboText.x = this.barWidth * 0.5;
    this.comboText.y = -64;
    this.addChild(this.comboText);

    this.visible = false;
    this.scale.set(0);
  }

  public setCombo(val: number): void {
    if (val <= 0) {
      gsap.to(this, {
        duration: 0.5,
        pixi: { scaleX: 0, scaleY: 0 },
        ease: 'back.in',
        overwrite: true,
        onComplete: () => {
          this.visible = false;
        },
      });
    } else if (this.prevVal == 0) {
      this.visible = true;
      gsap.to(this, {
        duration: 0.5,
        pixi: { scaleX: 1, scaleY: 1 },
        ease: 'back.out',
        overwrite: true,
      });
    } else {
      this.scale.set(1.0);
      gsap.to(this, {
        duration: 0.2,
        pixi: { scaleX: 1.2, scaleY: 1.2 },
        repeat: 1,
        yoyo: true,
        ease: 'power1.out',
        overwrite: true,
      });
    }

    this.comboText.text = 'COMBO x' + val;
    this.comboText.pivot.set(this.comboText.width * 0.5, this.comboText.height * 0.5);

    this.barVal = this.prevVal;

    // yellow lead bar
    gsap.to(this, {
      duration: 0.1,
      barVal2: val,
      ease: 'back.out',
      onUpdate: () => {
        this.redrawBar(this.barVal2, this.comboGraphics2, 0xffff60);
      },
    });

    // red main bar
    gsap.to(this, {
      duration: 0.25,
      barVal: val,
      ease: 'sine.inOut',
      onUpdate: () => {
        this.redrawBar(this.barVal, this.comboGraphics, 0xff7360);
      },
    });

    this.prevVal = val;
  }

  public redrawBar(val: number, graphics: Graphics, color: number): void {
    const ratio = val / ScoreManager.maxCombo;
    const barWidth = ratio * (this.barWidth - 16);
    graphics.clear();
    graphics.roundRect(8, 8, barWidth, this.barHeight - 16, this.radius - 4);
    graphics.fill(color);
  }
}
