import { Graphics } from 'pixi.js';
import { isoContainer } from '../isoContainer';
import { Player } from './player';
import { Block } from '../blocks/block';

export class PlayerShadow extends isoContainer {
  player: Player;

  public constructor(player: Player) {
    super(0, 0, 0);
    this.player = player;

    const color = 0x938064;

    const graphics = new Graphics();
    graphics.ellipse(0, 0, 70, 35);
    graphics.alpha = 0.3;
    graphics.fill(color);

    this.addChild(graphics);
  }

  public update(blocks: Block[]) {
    const player = this.player;
    if (player.currentBlock) {
      this.visible = true;
    } else if (blocks.length > 1) {
      for (let i = blocks.length - 2; i < blocks.length - 1; i++) {
        const block = blocks[i];
        if (block.checkHit(player.isoX, player.isoY)) {
          this.visible = true;
        } else {
          this.visible = false;
          return;
        }
      }
    }

    const modZ = player.isoZ < -100 ? -(player.isoZ + 100) : 0;
    this.setPosition(0, 0, modZ);

    this.scale.x = player.scale.x;
    this.scale.y = 1.0;
    let scaleMod = 1.0 - modZ / 150;
    scaleMod = scaleMod < 0 ? 0 : scaleMod > 1.0 ? 1.0 : scaleMod;
    this.scale.x *= scaleMod;
    this.scale.y *= scaleMod;
  }
}
