import { getUserAccount } from '@/api/domains/users/get-user-account';
import { queryKeys } from '@/api/queryKeys';
import { useQuery } from 'react-query';
import { PartnerGroup, usePartnerGroups } from './use-partner-groups';

export type UserAccount = {
  id: string;
  community: UserCommunity;
};

export type UserCommunity = {
  id: string;
  displayName: string;
};

const NONE_COMMUNITY: PartnerGroup = {
  id: '0',
  telegramId: '0',
  type: '',
  displayName: 'HotPot Arcade (Default)',
  description:
    'This community is built for cat frens. We’re spontaneous, honest, playful, great observers, and we always land on our feet.',
  isActive: true,
  isPartner: false,
  hasContest: false,
};

export const useUserAccount = (): { userAccount: UserAccount | null } => {
  const { partnerGroups } = usePartnerGroups();

  const query = useQuery({
    queryKey: queryKeys.getUserAccount(),
    queryFn: getUserAccount,
  });

  switch (query.status) {
    case 'success': {
      const communityId = query.data.metadata?.communityId?.toString();
      let community = partnerGroups?.find((group) => group.telegramId === communityId);

      if (!community) {
        community = NONE_COMMUNITY;
      }

      return {
        userAccount: {
          community,
          id: query.data.id,
        },
      };
    }
    case 'loading':
    case 'error':
    default:
      return { userAccount: null };
  }
};
