import { useQuery } from 'react-query';
import { z } from 'zod';
import { QueryConfig } from '@/lib/react-query';
import { TotalHotpotPoints, TotalHotpotPointsSchema } from '@/api/types/users';
import { useHeroicClients } from '@/store/heroic-clients-store';

export const getTotalHotpotPoints = async (): Promise<TotalHotpotPoints> => {
  console.log('CALLING POINTS API');
  const client = useHeroicClients.getState().clients?.nakamaClient;
  const session = useHeroicClients.getState().clients?.nakamaSession;
  if (client && session) {
    const totalPointsResponse = await client.rpc(session, 'rpc_get_total_collected_points', {});
    try {
      return TotalHotpotPointsSchema.parse(totalPointsResponse.payload);
    } catch (err) {
      if (err instanceof z.ZodError) {
        console.log(err.issues);
      }
    }
  }
  return { totalPoints: 0 };
};

export const getTotalHotpotPointsQueryOptions = () => {
  return {
    queryKey: ['hotpotPoints'],
    queryFn: getTotalHotpotPoints,
  };
};

type UseTotalHotpotPointsOptions = {
  queryConfig?: QueryConfig<typeof getTotalHotpotPointsQueryOptions>;
};

export const useTotalHotpotPoints = ({ queryConfig }: UseTotalHotpotPointsOptions = {}) => {
  return useQuery({
    ...getTotalHotpotPointsQueryOptions(),
    ...queryConfig,
  });
};
