import { Container, DestroyOptions, Point } from 'pixi.js';
import { toIsoX, toIsoY } from './utils';

export class isoContainer extends Container {
  isoX: number = 0;
  isoY: number = 0;
  isoZ: number = 0;
  blockSize: Point = new Point(100, 100);

  public constructor(x: number, y: number, z: number) {
    super();
    this.setPosition(x, y, z);
  }

  public destory(options: DestroyOptions) {
    super.destroy(options);
  }

  public setPosition(x: number, y: number, z: number): void {
    this.isoX = x;
    this.isoY = y;
    this.isoZ = z;
    this.updatePosition();
  }

  public updatePosition() {
    this.x = toIsoX(this.isoX, this.isoY, this.isoZ);
    this.y = toIsoY(this.isoX, this.isoY, this.isoZ);
  }

  public translate(x: number, y: number, z: number): void {
    this.isoX += x;
    this.isoY += y;
    this.isoZ += z;
    this.updatePosition();
  }
}
