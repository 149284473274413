import { useRef } from 'react';

export function useTelegramData() {
  const tg = window.Telegram.WebApp;
  const user = tg.initDataUnsafe.user;

  const dataRef = useRef({
    playerName: user ? `${user.first_name} ${user.last_name}` : 'Anonymous',
    displayName: user ? (user.username ?? user.first_name) : 'Anonymous',
    userId: user?.id || null,
    tg,
  });

  return dataRef.current;
}
