import { PropsWithChildren, useState } from 'react';
import { sound } from '@pixi/sound';
import classNames from 'classnames';

import styles from './Button.module.scss';

type Sound = 'menu_item_select' | 'random';

type ButtonProps = {
  primary?: boolean;
  secondary?: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>;
  className?: string;
  disabled?: boolean;
  playSound?: Sound | false;
  fullWidth?: boolean;
};

const playRandomSound = () => {
  const soundNumber = Math.floor(Math.random() * 3) + 1;
  sound.play(`tab_switch_${soundNumber}`);
};

export const Button = ({
  primary,
  secondary,
  onClick,
  children,
  disabled = false,
  className,
  playSound = 'random',
  fullWidth,
}: PropsWithChildren<ButtonProps>) => {
  const [inactive, setInactive] = useState(false);

  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.primary]: primary,
          [styles.secondary]: secondary,
          [styles.fullWidth]: fullWidth,
        },
        className
      )}
      disabled={disabled || inactive}
      onClick={async (event) => {
        setInactive(true);

        if (playSound === 'random') {
          playRandomSound();
        } else if (playSound) {
          sound.play(playSound);
        }

        await onClick(event);

        setInactive(false);
      }}
    >
      {children}
    </button>
  );
};
