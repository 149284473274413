import { Loader } from './loader';
import { InitializedNakamaClients } from './nakama-loader';

export enum Flag {
  dailyChallenge = 'daily-challenge',
  hideCoinEasyContestEvent = 'hide-coineasy-contest-event',
  liveEventContests = 'live-event-contests',
  dogeCommunity = 'doge-community',
  playToPromote = 'play-to-promote-p0',
}

type FlagKey = keyof typeof Flag;

const isFlagKey = (key: string): key is FlagKey => {
  return Object.keys(Flag).includes(key);
};

export const defaultFlags = Object.keys(Flag).reduce(
  (acc, flag) => {
    if (isFlagKey(flag)) {
      acc[flag] = false;
    }
    return acc;
  },
  {} as Record<FlagKey, boolean>
);

export type InitializedFlags = typeof defaultFlags;

export class FlagLoader implements Loader<InitializedFlags> {
  constructor(private nakamaLoader: Loader<InitializedNakamaClients>) {}
  public async load(): Promise<InitializedFlags> {
    try {
      const { satoriClient, satoriSession } = await this.nakamaLoader.load();
      const { flags: satoriFlags } = await satoriClient.getFlags(satoriSession, Object.values(Flag));

      const initializedFlags: InitializedFlags = defaultFlags;

      Object.keys(Flag).forEach((flagName) => {
        if (isFlagKey(flagName)) {
          const flag = satoriFlags?.find((f) => f.name === Flag[flagName]);
          initializedFlags[flagName] = flag?.value === 'true';
        }
      });

      return initializedFlags;
    } catch (_e) {
      throw 'error fetching feature flags';
    }
  }
}
