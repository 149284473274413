import { useQuery } from 'react-query';
import { useNakamaApi } from '../../contexts/useNakamaApi';
import { Achievement } from '@metatheoryinc/hiro-js';
import { AchievementId } from '../../types/nakama-api';

function useQuestAchievements() {
  const { getAchievementList } = useNakamaApi();

  const getAchievements = async (): Promise<Achievement[]> => {
    const list = await getAchievementList();

    const repeatAchievements = Object.values(list.repeat_achievements ?? [])
      // The daily check-in is dealt with by another hook
      .filter((a) => a.id !== AchievementId.DailyCheckIn);

    const oneTimeAchievements = Object.values(list.achievements ?? []);

    return oneTimeAchievements.concat(repeatAchievements);
  };

  const { data: achievements } = useQuery('dailyAchievements', getAchievements);

  return { achievements };
}

export default useQuestAchievements;
