import { Container } from 'pixi.js';
import { UserTelemetry } from './user-telemetry';

enum KeyCodes {
  Space = 'Space',
}

export class GameInput {
  public static pointerHeld: boolean = false; // pointer down status
  public static pointerDown: boolean = false; // returns true on the first frame the pointer is down
  public static pointerUp: boolean = false; // returns true on the first frame the pointer is up
  public static keyDown: Map<string, boolean> = new Map<string, boolean>();
  private static userTelemetry: UserTelemetry;

  public static addListeners(container: Container): void {
    if (!GameInput.userTelemetry) {
      GameInput.userTelemetry = new UserTelemetry();
    }

    container.on('pointerdown', GameInput.handlePointerDown);
    container.on('pointerup', GameInput.handlePointerUp);
    container.on('pointerleave', GameInput.handlePointerLeave);
    window.addEventListener('keydown', GameInput.handleKeyDown);
    window.addEventListener('keyup', GameInput.handleKeyUp);
    GameInput.keyDown.set(KeyCodes.Space, false);
  }

  public static removeListeners(container: Container): void {
    container.off('pointerdown', GameInput.handlePointerDown);
    container.off('pointerup', GameInput.handlePointerUp);
    container.off('pointerleave', GameInput.handlePointerLeave);
    window.removeEventListener('keydown', GameInput.handleKeyDown);
    window.removeEventListener('keyup', GameInput.handleKeyUp);
  }

  private static handleKeyDown = (e: KeyboardEvent): void => {
    GameInput.userTelemetry.queueEvent(e);
    GameInput.keyDown.set(e.code, true);
    if (GameInput.keyDown.get(KeyCodes.Space)) {
      GameInput.handleInputDown();
    }
  };

  private static handleKeyUp = (e: KeyboardEvent): void => {
    GameInput.userTelemetry.queueEvent(e);
    GameInput.keyDown.set(e.code, false);
    if (!GameInput.keyDown.get(KeyCodes.Space)) {
      GameInput.handleInputUp();
    }
  };

  private static handlePointerDown = (e: PointerEvent): void => {
    GameInput.userTelemetry.queueEvent(e);
    GameInput.handleInputDown();
  };

  private static handlePointerUp = (e: PointerEvent): void => {
    GameInput.userTelemetry.queueEvent(e);
    GameInput.handleInputUp();
  };

  private static handlePointerLeave = (e: PointerEvent): void => {
    GameInput.userTelemetry.queueEvent(e);
    GameInput.handleInputUp();
  };

  private static handleInputDown(): void {
    if (!GameInput.pointerHeld) {
      GameInput.pointerDown = true;
      requestAnimationFrame(() => {
        GameInput.pointerDown = false;
      });
    }
    GameInput.pointerHeld = true;
  }

  private static handleInputUp(): void {
    if (GameInput.pointerHeld) {
      GameInput.pointerUp = true;
      requestAnimationFrame(() => {
        GameInput.pointerUp = false;
      });
    }
    GameInput.pointerHeld = false;
  }
}
