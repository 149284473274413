export const SoundIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.97 7.89723C19.1106 7.75678 19.3012 7.67789 19.5 7.67789C19.6988 7.67789 19.8894 7.75678 20.03 7.89723L20.032 7.89923L20.035 7.90123L20.042 7.90823L20.062 7.92823L20.124 7.99723C20.174 8.05423 20.244 8.13523 20.325 8.23823C21.249 9.4392 21.75 10.912 21.75 12.4272C21.75 13.9425 21.249 15.4153 20.325 16.6162C20.2412 16.7231 20.1532 16.8265 20.061 16.9262L20.041 16.9462L20.035 16.9532L20.032 16.9552L20.031 16.9562L19.521 16.4482L20.031 16.9582C19.8903 17.0989 19.6995 17.178 19.5005 17.178C19.3015 17.178 19.1107 17.0989 18.97 16.9582C18.8293 16.8175 18.7503 16.6267 18.7503 16.4277C18.7503 16.2288 18.8293 16.0379 18.97 15.8972L18.969 15.8982L18.967 15.8992L18.972 15.8942L19.005 15.8582C19.037 15.8236 19.0833 15.7679 19.144 15.6912C19.7766 14.8654 20.1558 13.8735 20.2353 12.8362C20.3148 11.7989 20.0913 10.7609 19.592 9.84823C19.4616 9.60804 19.3118 9.37894 19.144 9.16323C19.0897 9.09305 19.0323 9.02532 18.972 8.96023L18.967 8.95523C18.8276 8.81429 18.7496 8.62387 18.7502 8.42561C18.7507 8.22735 18.8298 8.03738 18.97 7.89723ZM13.037 4.32323C14.2 3.55623 15.75 4.39123 15.75 5.78423V20.0702C15.75 21.4642 14.2 22.2982 13.037 21.5312L7.037 17.5762C6.99641 17.5492 6.94879 17.5346 6.9 17.5342H4C3.27065 17.5342 2.57118 17.2445 2.05546 16.7288C1.53973 16.213 1.25 15.5136 1.25 14.7842V11.0702C1.25 10.3409 1.53973 9.64141 2.05546 9.12569C2.57118 8.60996 3.27065 8.32023 4 8.32023H6.9C6.94903 8.32039 6.99701 8.30613 7.038 8.27923L13.037 4.32323Z"
        fill="#342236"
      />
    </svg>
  );
};
