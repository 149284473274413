import { getLeaderboardAroundUser } from '@/api/domains/leaderboards/get-leaderboard-around-user';
import { queryKeys } from '@/api/queryKeys';
import { QueryConfig } from '@/lib/react-query';
import { LeaderboardType } from '@/types/leaderboard';
import { useQuery } from 'react-query';

function getLeaderboardAroundUserQueryOptions(leaderboardId: string) {
  return {
    queryKey: queryKeys.getLeaderboardAroundUser(leaderboardId),
    queryFn: () => getLeaderboardAroundUser(leaderboardId),
    options: {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      onSuccess: () => {},
    },
  };
}

type UseLeaderboardAroundUserOptions = {
  leaderboardId: string;
  queryConfig?: QueryConfig<typeof getLeaderboardAroundUserQueryOptions>;
};

export function useLeaderboardAroundUser(
  { leaderboardId, queryConfig }: UseLeaderboardAroundUserOptions = { leaderboardId: LeaderboardType.DAILY }
) {
  return useQuery({ ...getLeaderboardAroundUserQueryOptions(leaderboardId), ...queryConfig });
}
