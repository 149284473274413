import { isUserMetadata } from '@/api/types/users';
import { LeaderboardItem } from '@/types/leaderboard';
import { LeaderboardRecord, LeaderboardRecordList, User } from '@metatheoryinc/nakama-js';
import { InfiniteData } from 'react-query';

export const transformLeaderboardRecord = (record: LeaderboardRecord, usersData: User[]): LeaderboardItem => {
  const user = usersData.find((user) => user.id === record.owner_id);

  return {
    id: record.owner_id || '',
    name: record.username || '',
    score: record.score || 0,
    rank: record.rank || -1,
    communityId: isUserMetadata(user?.metadata) ? user.metadata.communityId : -1,
  };
};

export function transformLeaderboardData(
  leaderboardData?: InfiniteData<
    | {
        playerResult: { score: number; position: number };
        results: LeaderboardItem[];
      }
    | undefined
  >
) {
  if (!leaderboardData) return;
  return {
    playerResult: leaderboardData?.pages?.[0]?.playerResult,
    results: leaderboardData?.pages.flatMap((page) => page?.results ?? []),
  };
}

export const getUserIds = (list: LeaderboardRecordList) => {
  if (!list.records) {
    return [];
  }

  return list.records.map((record) => record.owner_id || '').filter(Boolean);
};
