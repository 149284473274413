import React, { createContext, useContext, useCallback, useEffect } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useNakama } from './NakamaProvider';
import { Incentive, IncentiveList } from '@metatheoryinc/hiro-js';
import { Session, User } from '@metatheoryinc/nakama-js';
import { HiroClient } from '@metatheoryinc/hiro-js';
import { Session as SatoriSession, Client as SatoriClient } from '@metatheoryinc/satori-js';
import { initUtils } from '@telegram-apps/sdk';
import { useTelegramData } from '../game-react/hooks/use-telegram-data';
import { useToasts } from './ToastProvider';
import { Analytics } from '../utils/analytics';
import { PawsIcon } from '../components/Icons/PawsIcon';
import styles from './ReferralsStyles.module.scss';
import { useTranslation } from 'react-i18next';

const REFERRAL_PREFIX = 'ref_';
const REMINDER_PREFIX = 'reminder_';

enum CLAIM_STATUS {
  LOADING = 'LOADING',
  INVALID_NO_CODE = 'INVALID_NO_CODE',
  INVALID_SELF_CODE = 'INVALID_SELF_CODE',
  SUCCESS = 'SUCCESS',
  ERROR_ALREADY_CLAIMED = 'ERROR_ALREADY_CLAIMED',
  ERROR_UNKNOWN = 'ERROR_UNKNOWN',
}

interface ReferralsContextType {
  getPlayerReferralCodeQuery: UseQueryResult<Incentive | null, Error>;
  claimStartParamReferralCodeQuery: UseQueryResult<ParamReferralClaim, Error>;
}

const ReferralsContext = createContext<ReferralsContextType | undefined>(undefined);

export const ReferralsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { session, hiroClient, satoriClient, satoriSession } = useNakama();
  const { tg } = useTelegramData();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  /**
   *
   *
   * Get the current player's referral incentive.
   *
   *
   */
  const getPlayerReferralCodeQuery = useQuery<Incentive | null, Error>(
    ['referral'],
    () => fetchOrCreateReferral(session as Session, hiroClient as HiroClient),
    {
      enabled: !!session && !!hiroClient,
    }
  );

  /**
   *
   *
   * Claim the referral code if it comes in through start_param.
   *
   *
   */
  const claimStartParamReferralCodeQuery = useQuery<ParamReferralClaim, Error>(
    ['claimReferral', tg.initDataUnsafe.start_param],
    () => claimReferralFromStartParam(session as Session, hiroClient as HiroClient, tg.initDataUnsafe.start_param),
    {
      enabled: !!session && !!hiroClient && !!tg.initDataUnsafe.start_param,
      retry: false,
      onSuccess: () => {},
    }
  );

  useEffect(() => {
    if (claimStartParamReferralCodeQuery.data) {
      switch (claimStartParamReferralCodeQuery.data.status) {
        case CLAIM_STATUS.SUCCESS:
          Analytics.recordImpression('referral-code-for-recipient-claimed-toast');

          addToast(
            t(`Referral code claimed successfully!`),
            'info',
            'top',
            <div className={styles.pawsAmount}>
              <PawsIcon />
              <p>+{claimStartParamReferralCodeQuery.data.reward}</p>
            </div>
          );
          break;
        case CLAIM_STATUS.INVALID_NO_CODE:
          // do nothing
          break;
        case CLAIM_STATUS.INVALID_SELF_CODE:
          Analytics.recordImpression('referral-code-for-recipient-self-claim-error-toast');
          addToast(t("Sorry, you can't claim your own referral code!"), 'info');
          break;
        case CLAIM_STATUS.ERROR_ALREADY_CLAIMED:
          Analytics.recordImpression('referral-code-for-recipient-cannot-claim-error-toast');
          addToast(t("Sorry, you can't claim another referral code!"), 'info');
          break;
        case CLAIM_STATUS.ERROR_UNKNOWN:
          // do nothing
          break;
      }
    }
  }, [claimStartParamReferralCodeQuery.data, addToast, t]);

  useEffect(() => {
    (async () => {
      if (tg && satoriClient && satoriSession) {
        await registerReminderFromStartParam(satoriClient, satoriSession, tg.initDataUnsafe.start_param);
      }
    })();
  }, [satoriClient, satoriSession, tg]);

  const value = {
    getPlayerReferralCodeQuery,
    claimStartParamReferralCodeQuery,
  };

  return <ReferralsContext.Provider value={value}>{children}</ReferralsContext.Provider>;
};

const useReferralsContext = () => {
  const context = useContext(ReferralsContext);
  if (context === undefined) {
    throw new Error('useReferralsContext must be used within a ReferralsProvider');
  }
  return context;
};

export type ReferralClaim = {
  recipientId: string;
  recipientName: string;
  code: string;
  reward: number;
};

const DEFAULT_REFERRAL_POINTS_REWARD = 100;

export const useReferrals = () => {
  const { getPlayerReferralCodeQuery: referralQuery, claimStartParamReferralCodeQuery: claimQuery } =
    useReferralsContext();

  const { session, client } = useNakama();

  const share = useCallback(() => {
    if (referralQuery.data?.code) {
      openShareModal(referralQuery.data.code);
    } else {
      console.error('Referral code not available');
    }
  }, [referralQuery.data]);

  const claimReferral = useCallback(
    async (session: Session, hiroClient: HiroClient, code: string, recipientId: string) => {
      try {
        await hiroClient.incentivesSenderClaim(session, {
          code,
          recipient_ids: [recipientId],
        });
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const referralIncentive = referralQuery.data;

  let referralClaims: ReferralClaim[] = [];
  const { data: recipients } = useQuery(['referralRecipients'], async () => {
    if (!referralIncentive) {
      return;
    }

    const recipients = await client?.getUsers(session!, referralIncentive.unclaimed_recipients);

    return recipients?.users;
  });

  if (referralIncentive && referralIncentive.unclaimed_recipients) {
    let referralReward = Number(referralIncentive.sender_rewards?.guaranteed?.currencies?.points?.count?.min);

    if (!referralReward) {
      referralReward = DEFAULT_REFERRAL_POINTS_REWARD;
    }

    if (recipients) {
      referralClaims = recipients.map((user: User) => {
        return {
          recipientId: user.id!,
          recipientName: user.username!,
          reward: referralReward,
          code: referralIncentive.code!,
        } satisfies ReferralClaim;
      });
    }
  }

  return {
    referralIncentive,
    isLoading: referralQuery.isLoading,
    error: referralQuery.error,
    refetchReferrals: referralQuery.refetch,
    share,
    claimStatus: claimQuery.data || CLAIM_STATUS.LOADING,
    referralClaims,
    claimReferral,
  };
};

/**
 *
 *
 *
 *
 * Helpers for sharing your referral code
 *
 */
function openShareModal(referralCode: string) {
  const utils = initUtils();
  //  const startParam = encodeReferralCodeParam(referralCode);
  const startParam = REFERRAL_PREFIX + referralCode;
  const text = 'New game! Tournaments coming! Click the link and they gimme paws (and you get paws too).';
  const url = `https://t.me/${import.meta.env.VITE_TELEGRAM_BOT_NAME}/gimmecat?startapp=${startParam}`;
  Analytics.recordImpression('referral-share-popup');
  utils.shareURL(url, text);
}

async function fetchOrCreateReferral(session: Session, hiroClient: HiroClient): Promise<Incentive | null> {
  const incentiveList = await hiroClient.incentivesSenderList(session);
  let referralIncentive = findReferralIncentive(incentiveList);

  if (!referralIncentive) {
    const newIncentives = await hiroClient.incentivesSenderCreate(session, {
      id: 'referral',
    });
    referralIncentive = findReferralIncentive(newIncentives);
  }

  return referralIncentive || null;
}

type ParamReferralClaim = { status: CLAIM_STATUS; reward?: string };

/**
 *
 *
 *
 *
 * Helpers for claiming a referral code
 *
 */
async function claimReferralFromStartParam(
  session: Session,
  hiroClient: HiroClient,
  startParam: string | undefined
): Promise<ParamReferralClaim> {
  try {
    const code = getReferralCodeFromStartParam(startParam);
    if (!code) return { status: CLAIM_STATUS.INVALID_NO_CODE };

    const incentiveStatus = await hiroClient.incentivesRecipientGet(session, {
      code,
    });

    if (!incentiveStatus?.can_claim) {
      return { status: CLAIM_STATUS.ERROR_ALREADY_CLAIMED };
    }

    if (incentiveStatus.sender === session.user_id) {
      return { status: CLAIM_STATUS.INVALID_SELF_CODE };
    }
    if (!incentiveStatus.available_rewards?.guaranteed?.currencies?.points?.count?.min) {
      return { status: CLAIM_STATUS.ERROR_UNKNOWN };
    }
    await hiroClient.incentivesRecipientClaim(session, { code });
    return {
      status: CLAIM_STATUS.SUCCESS,
      reward: incentiveStatus.available_rewards?.guaranteed?.currencies?.points?.count?.min,
    };
  } catch (_e) {
    return { status: CLAIM_STATUS.ERROR_UNKNOWN };
  }
}

// Tracking Launch
async function registerReminderFromStartParam(
  satoriClient: SatoriClient,
  satoriSession: SatoriSession,
  startParam: string | undefined
): Promise<boolean> {
  try {
    const code = getReminderCodeFromStartParam(startParam);
    if (code) {
      return await satoriClient.event(satoriSession, {
        name: 'reminderLaunch',
        value: Date(),
        metadata: { telegram_group: code },
      });
    }
    return false;
  } catch (_e) {
    return false;
  }
}

/**
 *
 *
 *
 *
 * Helpers
 *
 */

function getReferralCodeFromStartParam(startParam: string | undefined): string | undefined {
  if (startParam?.startsWith(REFERRAL_PREFIX)) {
    return startParam.slice(REFERRAL_PREFIX.length);
  }
}

function getReminderCodeFromStartParam(startParam: string | undefined): string | undefined {
  if (startParam?.startsWith(REMINDER_PREFIX)) {
    return startParam.slice(REMINDER_PREFIX.length);
  }
}

/*
function encodeReferralCodeParam(referralCode: string): string {
  return Buffer.from(JSON.stringify({ referralCode }), 'binary').toString('base64url');
}
*/

function findReferralIncentive(incentiveList: IncentiveList): Incentive | undefined {
  return incentiveList?.incentives?.find((incentive) => incentive.id === 'referral');
}
