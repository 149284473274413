import gsap from 'gsap';
import { BitmapText, Container, Graphics, TextStyle } from 'pixi.js';
import { ThemeColors } from '../../api/player';
import { ScoreManager } from '../engine/score-manager';

export class ProgressMeter extends Container {
  readonly barWidth = 920;
  readonly barHeight = 64;
  readonly radius = 24;
  readonly barColor = 0x7b92c9;
  readonly effectColor = 0xffff60;
  bg: Container = new Container();
  bar: Container = new Container();
  barMain: Graphics = new Graphics();
  barEffect: Graphics = new Graphics();
  progressText: BitmapText;
  barVal: number = 0;
  effectVal: number = 0;
  prevVal: number = 0;

  constructor() {
    super();

    const initBG = () => {
      const graphics = new Graphics();
      graphics.roundRect(0, 0, this.barWidth, this.barHeight, this.radius);
      graphics.fill(0xfffbf4);
      graphics.roundRect(8, 8, this.barWidth - 16, this.barHeight - 16, this.radius - 4);
      graphics.cut();
      this.bg.addChild(graphics);
    };

    const initBar = () => {
      // yellow effect bar
      const graphics2 = this.barEffect;
      graphics2.roundRect(8, 8, this.barWidth - 16, this.barHeight - 16, this.radius - 4);
      graphics2.fill(this.effectColor);
      this.bar.addChild(graphics2);

      // main bar
      const graphics = this.barMain;
      graphics.roundRect(8, 8, this.barWidth - 16, this.barHeight - 16, this.radius - 4);
      graphics.fill(this.barColor);
      this.bar.addChild(graphics);
    };

    initBG();
    initBar();

    this.pivot.set(this.barWidth * 0.5, this.barHeight * 0.5);
    this.addChild(this.bg);
    this.addChild(this.bar);

    // progress text
    this.progressText = new BitmapText({
      text: '0 / ' + ScoreManager.dailyChallengeBlocks,
      style: new TextStyle({
        fontFamily: 'DMMono-Medium',
        fontSize: 36,
        letterSpacing: 4,
        fill: ThemeColors.uiText,
      }),
    });
    this.progressText.anchor.set(0.5, 0.65);
    this.progressText.x = this.barWidth * 0.5;
    this.progressText.y = this.barHeight * 0.5;
    this.addChild(this.progressText);

    // this.visible = false;
    // this.scale.set(0);
  }

  public setProgress(val: number): void {
    // this.scale.set(1.0);
    // gsap.to(this, {
    //   duration: 0.2,
    //   pixi: { scaleX: 1.2, scaleY: 1.2 },
    //   repeat: 1,
    //   yoyo: true,
    //   ease: 'power1.out',
    //   overwrite: true,
    // });

    this.progressText.text = val + ' / ' + ScoreManager.dailyChallengeBlocks;
    this.progressText.anchor.set(0.5, 0.65);

    this.barVal = this.prevVal;

    // yellow lead bar
    gsap.to(this, {
      duration: 0.1,
      effectVal: val,
      ease: 'back.out',
      onUpdate: () => {
        this.redrawBar(this.effectVal, this.barEffect, this.effectColor);
      },
    });

    // main bar
    gsap.to(this, {
      duration: 0.25,
      barVal: val,
      ease: 'sine.inOut',
      onUpdate: () => {
        this.redrawBar(this.barVal, this.barMain, this.barColor);
      },
    });

    this.prevVal = val;
  }

  public redrawBar(val: number, graphics: Graphics, color: number): void {
    const ratio = val / ScoreManager.dailyChallengeBlocks;
    const barWidth = ratio * (this.barWidth - 16);
    graphics.clear();
    graphics.roundRect(8, 8, barWidth, this.barHeight - 16, this.radius - 4);
    graphics.fill(color);
  }
}
