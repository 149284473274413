import './instrument';
import ReactDOM from 'react-dom/client';
import React, { Suspense } from 'react';
import { App } from './app';
import './styles.scss';
import { enableMocking } from './testing/mocks';
import { ArcadeReactLoader } from './lib/arcade-react-sdk';
import { ErrorBoundary } from 'react-error-boundary';
import { Loading } from './components/Loading';
import { ErrorFallback } from './components/Error/ErrorFallback';
import './utils/i18next-fe';
import { ArcadeSdkProvider } from './lib/arcade-react-sdk/ArcadeSdkProvider';
import { sdk } from './gimmecat';

function renderReactApp() {
  enableMocking().then(() => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<Loading sdk={sdk} />}>
            <ArcadeReactLoader sdk={sdk}>
              {(sdk) => (
                <ArcadeSdkProvider sdk={sdk}>
                  {/* FIXME: We do a check in ArcadeReactLoader to make sure
                  that game.nakamaClients not null, but the type isn't narrowed
                  properly so we add a ! here */}
                  <App nakamaClients={sdk.nakamaClients!} />
                </ArcadeSdkProvider>
              )}
            </ArcadeReactLoader>
          </Suspense>
        </ErrorBoundary>
      </React.StrictMode>
    );
  });
}

const tg: WebApp | undefined = window.Telegram.WebApp;
if (tg) {
  tg.expand();
  renderReactApp();
  tg.ready();
} else {
  renderReactApp();
}
