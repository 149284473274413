import { stringToJSONArraySchema } from '@/utils/zod';
import { z } from 'zod';

export const TournamentRpcPayloadZod = z.object({
  create_time: z.string().pipe(z.coerce.date()),
  start_time: z.string().pipe(z.coerce.date()),
  end_time: z.string().pipe(z.coerce.date()),
  contest_type: z.string(),
  id: z.string(),
  title: z.string(),
  category: z.number(),
  sort_order: z.number(),
  size: z.number().optional(),
  max_size: z.number(),
  max_num_score: z.number(),
  end_active: z.number(),
  next_reset: z.number(),
  duration: z.number(),
  start_active: z.number(),
  operator: z.number(),
  metadata: z.object({
    communityIds: stringToJSONArraySchema.pipe(z.array(z.number())),
    splashImage: z.string(),
    description: z.string(),
    prizeTitle: z.string(),
    prizeDescription: z.string(),
  }),
});

export const TournamentListRpcPayloadZod = z.object({
  tournaments: z.array(TournamentRpcPayloadZod),
});
