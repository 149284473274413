import { createStore, useStore } from 'zustand';
import { GameplayState, GameState } from '../game-react/models/game-state';
import { GameMode } from '../game-raw/engine/game-run';
import { ScoreManager } from '../game-raw/engine/score-manager';
import { GameRun } from '../game-raw/engine/game-run';
import { EventCollector } from '../game-raw/engine/event-collector';
import { Client as SatoriClient, Session as SatoriSession } from '@metatheoryinc/satori-js';
import { BrandManager } from '../game-raw/brandManager';
import { NormalGameRunStrategy } from './../game-raw/engine/game-modes/normal-game-run-strategy';
import { DailyRunStrategy } from './../game-raw/engine/game-modes/daily-run-strategy';

export const gameStore = createStore<GameState>()((set) => ({
  highScore: 0,
  username: '',
  gameplayState: GameplayState.PreInitializing,
  brandManager: undefined,
  setBrandManager: (bm: BrandManager) => {
    set({ brandManager: bm });
  },
  scoreManager: new ScoreManager(),
  resetScoreManager: () => {
    set({ scoreManager: new ScoreManager() });
  },
  gameRun: undefined,
  resetGameRun: (telegramUserId: number, gameMode: GameMode, startTime?: number) => {
    set({
      gameRun: new GameRun(
        gameMode == GameMode.Normal
          ? new NormalGameRunStrategy(telegramUserId, startTime)
          : new DailyRunStrategy(telegramUserId, startTime)
      ),
    });
  },
  eventCollector: new EventCollector(),
  updateHighScore: (score: number) => {
    set({ highScore: score });
  },
  setGameplayState: (gameState: GameplayState) => {
    set({ gameplayState: gameState });
  },
  gameSessionRunCount: 0,
  bumpGameSessionRunCount: () => {
    set((state) => ({ gameSessionRunCount: state.gameSessionRunCount + 1 }));
  },
  satoriClient: undefined,
  setSatoriClient: (s: SatoriClient) => {
    set({ satoriClient: s });
  },
  satoriSession: undefined,
  setSatoriSession: (s: SatoriSession) => {
    set({ satoriSession: s });
  },
}));

export function useGameStore<T>(selector?: (state: GameState) => T) {
  return useStore(gameStore, selector!);
}

export type { GameState };
export { GameplayState, EventCollector };
