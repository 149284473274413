import { GimmecatBrandingConfig } from '@/types/satori/GimmecatBrandingConfig.gen';
import { RecordAndPartnerAndBrand, RecordAndPartnerAndBrandAssured } from '../leaderboards/community-leaderboard-utils';

type ProbabilityTable = GimmecatBrandingConfig['brandingFrequencyTable'];
export function computeBrandProbabilityTable(recordsWithPartnerAndBrand: RecordAndPartnerAndBrand[]): ProbabilityTable {
  const winnersWithBrands = recordsWithPartnerAndBrand.filter(
    (record): record is RecordAndPartnerAndBrandAssured => record.brand !== undefined
  );
  const topTenWinnersWithBrands = winnersWithBrands.slice(0, 10);
  const count = topTenWinnersWithBrands.length;
  return topTenWinnersWithBrands.map((winner, index) => {
    return {
      brandingDefinitionId: winner.brand.ownerCommunityId,
      percentage: Math.pow(2, count - index),
    };
  });
}
