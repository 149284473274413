export const Checkmark = () => (
  <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0743 0.925929C13.2915 1.1495 13.4183 1.45783 13.4268 1.78314C13.4353 2.10844 13.3247 2.42409 13.1194 2.66067L6.72924 10.0252C6.62567 10.1443 6.50122 10.2396 6.36323 10.3056C6.22524 10.3715 6.07651 10.4066 5.92584 10.4089C5.77516 10.4112 5.62559 10.3806 5.48597 10.3189C5.34634 10.2572 5.21949 10.1657 5.11291 10.0497L1.7299 6.36747C1.53071 6.13479 1.42227 5.82704 1.42742 5.50906C1.43258 5.19107 1.55092 4.88768 1.75753 4.6628C1.96414 4.43791 2.24288 4.3091 2.53502 4.30349C2.82717 4.29788 3.10991 4.41591 3.32367 4.63272L5.88725 7.42141L11.4805 0.975025C11.6859 0.738539 11.9692 0.600519 12.268 0.591312C12.5669 0.582105 12.8569 0.702465 13.0743 0.925929Z"
      fill="#796A5E"
      stroke="#796A5E"
      strokeWidth="1.2"
    />
  </svg>
);
