import { Loader } from './loader';
import { AmplifyJwt } from './amplify-jwt-loader';
import { Client as NakamaClient, Session as NakamaSession, Socket as NakamaSocket } from '@metatheoryinc/nakama-js';
import { HiroClient } from '@metatheoryinc/hiro-js';
import { Client as SatoriClient, Session as SatoriSession } from '@metatheoryinc/satori-js';

export type InitializedNakamaClients = {
  nakamaClient: NakamaClient;
  nakamaSession: NakamaSession;
  hiroClient: HiroClient;
  satoriClient: SatoriClient;
  satoriSession: SatoriSession;
  nakamaSocket: NakamaSocket;
};

export class NakamaLoader implements Loader<InitializedNakamaClients> {
  constructor(private amplifyJwtLoader: Loader<AmplifyJwt>) {}
  public async load(): Promise<InitializedNakamaClients> {
    try {
      // Pre-Requisite: Get JWT from Amplify
      const jwt = await this.amplifyJwtLoader.load();

      // Construct Nakama, Hiro, and Satori Clients
      const nakamaClientConfig = {
        serverKey: import.meta.env.VITE_NAKAMA_PUBLIC_KEY,
        host: import.meta.env.VITE_NAKAMA_SERVER,
        port: '443',
        useSSL: true,
        prefix: undefined,
        timeout: 60000, // default was 7000
        autoRefreshSession: true,
      };
      const nakamaClient = new NakamaClient(
        nakamaClientConfig.serverKey,
        nakamaClientConfig.host,
        nakamaClientConfig.port,
        nakamaClientConfig.useSSL,
        nakamaClientConfig.prefix,
        nakamaClientConfig.timeout,
        nakamaClientConfig.autoRefreshSession
      );
      const hiroClient = new HiroClient(nakamaClient);
      const satoriClient = new SatoriClient(
        import.meta.env.VITE_SATORI_API_KEY,
        import.meta.env.VITE_SATORI_SERVER,
        '443',
        true
      );

      // Get Nakama, Hiro, and Satori Sessions
      const nakamaSession = await nakamaClient.authenticateCustom(jwt, true);
      const satoriSession = await satoriClient.authenticate(nakamaSession.user_id!);

      // Establish nakama socket connection
      const nakamaSocket = await nakamaClient.createSocket(true);
      const appearOnline = true;
      await nakamaSocket.connect(nakamaSession, appearOnline);

      return {
        nakamaClient,
        nakamaSession,
        hiroClient,
        satoriClient,
        satoriSession,
        nakamaSocket,
      };
    } catch (e) {
      console.error(e);
      throw 'error getting nakama session';
    }
  }
}
