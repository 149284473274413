import classnames from 'classnames';
import { HomeIcon } from '../Icons/HomeIcon';
import { RefreshIcon } from '../Icons/RefreshIcon';
import styles from './P2PGameOver.module.scss';
import { ChevronIcon } from '../Icons/ChevronIcon';
import { useEffect, useRef, useState } from 'react';
import { usePageViewTracking } from '../../game-react/hooks/use-page-view-tracking';
import { Button } from '../Button/Button';
import { postScore } from '../../api/score';
import { useQueryClient } from 'react-query';
import loadingGif from '../../assets/loading-paw.gif';
import { Text } from '../Text/Text';
import { LeaderboardEntry } from '@/features/leaderboard/LeaderboardEntry';
import { useP2PGameOver } from '@/features/game-over/hooks/use-p2p-game-over';
import gimmecatLogo from '../../assets/game-title-2.png';
import { usePartnerGroups } from '@/hooks/use-partner-groups';

type P2PGameOverProps = {
  onBackHome: () => void;
  onRestart: () => void;
  onLeaderboard: () => void;
  score: number;
};

export const P2PGameOver = ({ onBackHome, onRestart, onLeaderboard, score }: P2PGameOverProps) => {
  usePageViewTracking('game-over');

  const { totalScore, teamLeaderboards, community } = useP2PGameOver();
  const { getPartnerGroup } = usePartnerGroups();

  const [scorePosted, setScorePosted] = useState(false);
  const queryClient = useQueryClient();
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (hasInitialized.current || scorePosted) {
      return;
    }

    hasInitialized.current = true;

    postScore(score)
      .catch((e) => console.error(e))
      .finally(() => {
        queryClient.invalidateQueries();
        setScorePosted(true);
      });
  }, [score, queryClient, scorePosted]);

  return (
    <div className={styles.gameover}>
      <section className={styles.scoresSection}>
        <img src={gimmecatLogo} className={styles.logo} />
        <div className={styles.currentScoreContainer}>
          <Text as="span" type="mono" className={styles.eyebrow}>
            Your score
          </Text>
          <p className={styles.score}>{score}</p>
          <span className={styles.totalScore}>
            <img className={styles.communityLogo} src={community?.logo} />
            <Text as="span" type="body" className={styles.scoreText}>
              You added {{ totalScore }} points today!
            </Text>
          </span>
        </div>
        {teamLeaderboards ? (
          <>
            <div className={classnames(styles.eyebrow, styles.dailyScoreDescription)}>Community Scores</div>
            <div className={styles.leaderboard}>
              {teamLeaderboards.map((record, index) => (
                <LeaderboardEntry
                  key={index}
                  name={record.name}
                  score={record.score}
                  rank={record.rank}
                  img={getPartnerGroup(record.communityId?.toString())?.logo}
                  highlight={record.communityId === community?.telegramId}
                  className={classnames(styles.leaderboardItem)}
                />
              ))}
            </div>
            <Button secondary className={styles.leaderboardButton} onClick={onLeaderboard}>
              <Text as="span" type="button">
                See all
              </Text>
              <ChevronIcon />
            </Button>
          </>
        ) : (
          <div>
            <img src={loadingGif} className={styles.loadingGif} />
          </div>
        )}
      </section>
      <section className={styles.bottomSection}>
        <div className={styles.actionContainer}>
          <Button primary fullWidth className={styles.homeButton} onClick={onBackHome}>
            <HomeIcon fill="#ffffff" />
          </Button>
          <Button primary fullWidth className={styles.action} onClick={onRestart} playSound={'menu_item_select'}>
            <RefreshIcon />
            <Text type="button" as="span">
              Play again?
            </Text>
          </Button>
        </div>
      </section>
    </div>
  );
};
