import gsap from 'gsap';
import { BitmapText, Container, TextStyle } from 'pixi.js';
import { ThemeColors } from '../../api/player';
import { formatTime } from '../utils';

export class CountdownTimer extends Container {
  gameWidth: number;
  gameHeight: number;
  countdownText: BitmapText;

  constructor(gameWidth: number, gameHeight: number) {
    super();
    this.gameWidth = gameWidth;
    this.gameHeight = gameHeight;

    this.countdownText = new BitmapText({
      text: '3',
      style: new TextStyle({
        fontFamily: 'Mplus1c-numbers',
        fontSize: 192,
        fill: ThemeColors.uiText,
      }),
    });
    this.countdownText.pivot.set(this.countdownText.width * 0.5, this.countdownText.height * 0.5);
    this.countdownText.x = gameWidth * 0.5;
    this.countdownText.y = gameHeight * 0.4;
    this.addChild(this.countdownText);

    this.visible = false;
  }

  public animateCountdown() {
    this.setText('3');
    gsap.delayedCall(1.0, () => {
      this.setText('2');
    });
    gsap.delayedCall(2.0, () => {
      this.setText('1');
    });
    gsap.delayedCall(3.0, () => {
      this.animateGo('GO');
    });
  }

  public setText(text: string) {
    this.countdownText.rotation = 0;
    this.countdownText.scale.set(1);
    this.countdownText.text = text;
    this.countdownText.pivot.set(this.countdownText.width * 0.5, this.countdownText.height * 0.65);
    this.countdownText.scale.set(0);

    gsap.to(this.countdownText.scale, {
      duration: 0.5,
      x: 1.25,
      y: 1.25,
      repeat: 1,
      yoyo: true,
      ease: 'power2.out',
    });
    this.visible = true;
  }

  public animateGo(text: string) {
    this.countdownText.scale.set(1);

    this.countdownText.text = text;
    this.countdownText.pivot.set(this.countdownText.width * 0.5, this.countdownText.height * 0.65);
    this.countdownText.scale.set(0);

    this.countdownText.rotation = -Math.PI * 0.05;
    gsap.to(this.countdownText, {
      rotation: Math.PI * 0.05,
      duration: 0.1,
      repeat: 10,
      yoyo: true,
      ease: 'sine.inOut',
    });

    gsap.to(this.countdownText.scale, {
      duration: 0.5,
      x: 1.25,
      y: 1.25,
      ease: 'back.out',
    });
    gsap.to(this.countdownText.scale, {
      duration: 0.5,
      delay: 0.5,
      x: 0,
      y: 0,
      ease: 'back.in',
      onComplete: () => {
        this.visible = false;
      },
    });
    this.visible = true;
  }

  public animateResult(time: number) {
    this.countdownText.rotation = 0;
    this.countdownText.scale.set(1);

    this.countdownText.text = formatTime(time);
    this.countdownText.pivot.set(this.countdownText.width * 0.5, this.countdownText.height * 0.65);
    this.countdownText.scale.set(0);

    gsap.to(this.countdownText.scale, {
      duration: 1.0,
      x: 0.8,
      y: 0.8,
      ease: 'back.out',
    });
    this.visible = true;
  }
}
