import { Modal } from '@/components/Modal/Modal';
import styles from './EventList.module.scss';
import { LoaderFunctionArgs, useNavigate } from 'react-router-dom';
import { root } from '@/app/routes';
import { Tab, Tabs } from '@/components/Tabs/Tabs';
import { useState } from 'react';
import { getEventListQueryOptions, useEventsList } from './hooks/use-event-list';
import { EventCard } from '@/components/Events/Events';
import { EventPeriod } from '@/types/event';
import { QueryClient } from 'react-query';
import { usePageViewTracking } from '@/game-react/hooks/use-page-view-tracking';

const tabs: Tab[] = [
  {
    name: 'Active',
    controls: 'all',
    id: 'active',
  },
  {
    name: 'Past',
    controls: 'all',
    id: 'past',
  },
];

export const eventsListLoader =
  (queryClient: QueryClient, period: EventPeriod) => async (_args: LoaderFunctionArgs) => {
    const eventsListQueryOptions = getEventListQueryOptions(period);

    const promises = [
      queryClient.getQueryData(eventsListQueryOptions.queryKey) ??
        (await queryClient.fetchQuery(eventsListQueryOptions)),
    ] as const;

    const [eventsList] = await Promise.all(promises);

    return {
      eventsList,
    };
  };

export function EventList() {
  usePageViewTracking('events');
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('active');
  const { events } = useEventsList({ period: selectedTab === 'active' ? EventPeriod.ACTIVE : EventPeriod.PAST });

  if (!events) {
    return null;
  }

  return (
    <Modal
      onClose={() => {
        navigate(root.home.path);
      }}
      title={'Events'}
    >
      <>
        <div className={styles.eventList}>
          <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <div className={styles.divider}></div>
          {events.map((item) => (
            <EventCard
              key={`event-car-${item.title}`}
              img={item.splashImage}
              title={item.title}
              endsAt={item.endTime}
              onClick={() => navigate(root.event.Single.buildPath({ id: item.id }))}
            />
          ))}
        </div>
      </>
    </Modal>
  );
}
