import { create } from 'zustand';
import { InitializedNakamaClients } from '../lib/arcade-sdk/loaders/nakama-loader';

type HeroicClientStore = {
  clients: InitializedNakamaClients | undefined;
  loadClients: (c: InitializedNakamaClients) => void;
};

export const useHeroicClients = create<HeroicClientStore>((set) => ({
  clients: undefined,
  loadClients: (c) => set(() => ({ clients: c })),
}));
