import { useEffect, useRef } from 'react';
import loadingGif from '../../assets/loading-paw.gif';
import { Button } from '@/components/Button/Button';
import styles from './LeaderboardTable.module.scss';
import { AlertCircle } from '@/components/Icons/AlertCircle';
import cls from 'classnames';
import React from 'react';
import { LeaderboardEntry, LeaderboardScoreType } from './LeaderboardEntry';
import { LeaderboardError } from './LeaderboardError';
import { LeaderboardItem, LeaderboardType } from '@/types/leaderboard';
import { LeaderboardTabType } from '@/components/Leaderboard/Leaderboard';
import { usePartnerGroups } from '@/hooks/use-partner-groups';

export type LeaderboardTab = {
  name: string;
  controls: string;
  id: LeaderboardType;
  alert?: boolean;
  featureFlag?: string;
};

export type LeaderboardTableProps = {
  items?: LeaderboardItem[];
  isFetchingData: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  error?: boolean;
  onError: () => void;
  tabs?: LeaderboardTab[];
  tabType?: LeaderboardTabType;
  setSelectedTab?: (id: LeaderboardType) => void;
  selectedTab?: LeaderboardType;
  className?: string;
};

export const LeaderboardTable = ({
  items,
  isFetchingData,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  error = false,
  onError,
  tabs,
  tabType,
  setSelectedTab,
  selectedTab,
  className,
}: LeaderboardTableProps) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  const { getPartnerGroup } = usePartnerGroups();

  useEffect(() => {
    // Disconnect the existing observer
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0] && entries[0].isIntersecting && !isFetchingData && hasNextPage) {
        fetchNextPage();
      }
    });

    if (lastItemRef.current) observer.current.observe(lastItemRef.current);

    return () => {
      observer.current?.disconnect();
    };
  }, [fetchNextPage, hasNextPage, isFetchingData]);

  if (error) {
    return <LeaderboardError onTryAgain={onError} />;
  }

  const hasTabs = tabs !== undefined && tabs.length > 0;

  return (
    <div
      className={cls(className, styles.leaderboardTable, {
        [styles.withoutTabs]: !hasTabs,
      })}
    >
      {hasTabs && (
        <div className={styles.timeTabs}>
          {tabs.map((item, i) => {
            return (
              <React.Fragment key={item.id}>
                <Button
                  key={item.id}
                  onClick={() => setSelectedTab?.(item.id)}
                  className={cls(styles.timeTab, {
                    [styles.selectedTime]: item.id === selectedTab,
                  })}
                >
                  <span>
                    <span className={styles.timePeriodLabel}>{item.name}</span>
                  </span>
                  {item.alert && (
                    <div className={styles.alertCircleWrapper}>
                      <AlertCircle />
                    </div>
                  )}
                </Button>
                {i < tabs.length - 1 && <div className={styles.separator} />}
              </React.Fragment>
            );
          })}
        </div>
      )}
      <div className={styles.leaderboardItemsContainer}>
        {items?.map((player, index) => {
          const community = getPartnerGroup(player.communityId?.toString());

          return (
            <LeaderboardEntry
              key={player.id}
              name={player.name}
              score={player.score}
              scoreType={
                tabType === LeaderboardTabType.DailyChallenge
                  ? LeaderboardScoreType.timeScore
                  : LeaderboardScoreType.numberScore
              }
              rank={player.rank}
              fowardedRef={items?.length === index + 1 ? lastItemRef : undefined}
              img={community?.logo}
              className={styles.playerHighScore}
            />
          );
        })}
      </div>
      {(isFetchingData || isFetchingNextPage) && (
        <div
          className={cls(styles.loadingOverlay, {
            [styles.withoutTabs]: !hasTabs,
          })}
        >
          <img src={loadingGif} className={styles.loadingGif} />
        </div>
      )}
    </div>
  );
};
