import { getTelegramVerificationHeaders, getVersionHeaders } from '@/api/utils/headers';
import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

export function getApi(options: ApiOptions = {}): AxiosInstance {
  const axiosInstance = axios.create();
  if (options.middleware) {
    for (const middleware of options.middleware) {
      middleware(axiosInstance);
    }
  }
  return axiosInstance;
}
type ApiMiddleware = (axiosInstance: AxiosInstance) => void;

interface ApiOptions {
  middleware?: ApiMiddleware[];
}

function withHeaders(axiosInstance: AxiosInstance, headers: Record<string, string>) {
  axiosInstance.interceptors.request.use(
    (config) => {
      for (const [id, value] of Object.entries(headers)) {
        config.headers[id] = value;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export const withTelegramAuthHeaders: ApiMiddleware = (axiosInstance: AxiosInstance) => {
  return withHeaders(axiosInstance, getTelegramVerificationHeaders());
};

export const withVersionHeaders: ApiMiddleware = (axiosInstance: AxiosInstance) => {
  return withHeaders(axiosInstance, getVersionHeaders());
};

export const withRetries: ApiMiddleware = (axiosInstance: AxiosInstance) => {
  axiosRetry(axiosInstance, {
    retries: 5,
    retryDelay: (retryCount) => Math.pow(2, retryCount) * 1000,
    retryCondition: (error) => axiosRetry.isNetworkOrIdempotentRequestError(error),
  });
};

export const unauthenticatedApi = getApi({ middleware: [withVersionHeaders] });
export const authenticatedApi = getApi({ middleware: [withTelegramAuthHeaders, withVersionHeaders] });
export const authenticatedApiWithRetries = getApi({
  middleware: [withTelegramAuthHeaders, withVersionHeaders, withRetries],
});
