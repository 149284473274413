import challengeTitle from '../../assets/DailyChallengeLogo.png';
import gameTitleBg from '../../assets/game-title-bg.png';
import miniTitle from '../../assets/miniTitle.png';
import { QuestionIcon } from '../Icons/QuestionIcon';
import styles from './ChallengeTitle.module.scss';
import ArrowLeft from '../Icons/ArrowLeft';
import { Button } from '../Button/Button';

type challengeProps = {
  onMainMenu: () => void;
};

export const ChallengeTitle = ({ onMainMenu }: challengeProps) => {
  return (
    <div className={styles.topSectionContainer}>
      <div className={styles.topElementsContainer}>
        <Button className={styles.backButton} onClick={onMainMenu}>
          <div className={styles.backIcon}>
            <ArrowLeft></ArrowLeft>
          </div>
        </Button>
        <img className={styles.miniTitle} src={miniTitle} />
        <div className={styles.ftueButton}>
          <QuestionIcon />
        </div>
      </div>
      <div className={styles.gameTitleContainer}>
        <img className={styles.gameTitle} src={challengeTitle} />
        <img className={styles.gameTitleBg} src={gameTitleBg} />
      </div>
    </div>
  );
};
