import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import { Player } from './player/player';
import { Block } from './blocks/block';

import { gameStore, GameplayState } from '../store/gameStore';
import { EVENT_TYPE } from './engine/event-collector';

import { assertNever, clamp, randomRangeInt } from './utils';
import { ShakingBlock } from './blocks/shakingBlock';
import { MovingBlock } from './blocks/movingBlock';
import { Particles } from './particles/particles';
import { ScoreUI } from './gameUI/scoreUI';
import { ParticleType } from './particles/particle';
import { Sound, sound } from '@pixi/sound';
import { BlockModel, BlockSpawnDirection, BlockType, MovementAxis } from './engine/block-model';
import { JUMP_RATING, JumpJudger } from './engine/jump-judger';

import { Analytics } from '../utils/analytics';
import { AchievementId } from '../types/nakama-api';
import { BridgeBlock } from './blocks/bridgeBlock';
import { ScrollingWorld } from './scrollingWorld';
import { BlockPools } from './blocks/blockPools';
import { GameMode } from './engine/game-run';
import { CountdownTimer } from './gameUI/countdownTimer';
import { ScoreManager } from './engine/score-manager';
import { getGameplayState, handleGameOver, homeButtonClicked, setAllSoundVolume, updateAchievement } from './StartGame';
import { CatCostume } from '@/types/CatCostume';
import { InputCatcher } from './gameUI/inputCatcher';
import { GameInput } from './gameInput';

export async function initGame(app: PIXI.Application) {
  // Initialize PIXI Application
  await app.init({
    backgroundAlpha: 0,
    resizeTo: window,
    resolution: window.devicePixelRatio,
    autoDensity: true,
    antialias: true,
  });

  // Register gsap and pixi
  gsap.registerPlugin(PixiPlugin);
  PixiPlugin.registerPIXI(PIXI);

  // Initialize music object
  const bgm: Sound = PIXI.Assets.get('bgm01');
  const bgmContext = sound.context.audioContext;

  bgmContext.onstatechange = () => {
    //when switching apps on ios, the context continues suspended after coming back to the app
    if (bgmContext.state === 'suspended' && document.hasFocus()) {
      bgmContext.resume();
    }
  };

  function setBgmVolume(val: number) {
    bgm.volume = val;
  }
  function playBgm(play: boolean) {
    if (play && bgm.paused) {
      bgm.resume();
    } else bgm.pause();
  }

  function startBgm() {
    bgm.play({
      singleInstance: true,
      loop: true,
    });
  }

  // game constants
  const gameWidth = 1024;
  const gameHeight = 2048;
  const centerX = 1536; // iso coords
  const centerY = 1024;
  const maxHoldTime = 2000;

  // game entities
  const blocks: Block[] = [];
  const blockPool: BlockPools = new BlockPools();
  const particles: Particles = new Particles();
  const world = new ScrollingWorld(0, 0, 0);
  const player = new Player(0, 0, -100);
  const inputCatcher = new InputCatcher(app);
  const scoreUI = new ScoreUI(gameWidth, gameHeight, app, homeButtonClicked);
  const countdownUI = new CountdownTimer(gameWidth, gameHeight);

  // game state
  let startTime: number = 0;
  let dailyChallengeSubtractedTime: number = 0;
  let downTimer: number = 0;
  let currentHoldTime: number = 0;
  let lockInput: boolean = false;
  let pointerDown: boolean = false;
  let jumpCount: number = 0;

  // Let us begin
  onFirstLoad();

  function onFirstLoad() {
    app.stage.addChild(world);
    app.stage.addChild(inputCatcher);
    app.stage.addChild(scoreUI);
    app.stage.addChild(countdownUI);
    resizeStage();
    app.ticker.add(update);
  }

  function resizeStage() {
    const screen_ratio = window.innerHeight / gameHeight;
    app.stage.scale = screen_ratio;
    app.stage.x = window.innerWidth * 0.5 - gameWidth * screen_ratio * 0.5;
    inputCatcher.resize(app);
    scoreUI.resize(app);
  }
  window.onresize = resizeStage;

  function addListeners() {
    GameInput.addListeners(inputCatcher);
    scoreUI.visible = true;
  }

  function removeListeners() {
    GameInput.removeListeners(inputCatcher);
    scoreUI.visible = false;
  }

  function stopUpdateLoop() {
    app.ticker.remove(update);
  }

  function resumeUpdateLoop() {
    app.ticker.add(update);
  }

  // update loop
  async function update(ticker: PIXI.Ticker) {
    const dt = ticker.elapsedMS * 0.001;

    if (gameStore.getState().gameRun?.getGameMode() === GameMode.DailyChallenge) {
      const elapsedTime = (Date.now() - startTime) * 0.001;
      scoreUI.updateTime(elapsedTime);
    }

    // handle input
    if (GameInput.pointerDown) {
      handleInputDown();
    } else if (GameInput.pointerUp) {
      handleInputUp();
    }

    checkJumpCancel(dt);
    blocks.forEach((block) => block.update(dt));
    player.update(dt, blocks, particles);
    particles.update(dt);

    // player is on a falling block
    if (player.isOnFallingBlock && !player.isFalling) {
      if (player.currentBlock) {
        player.clearBlock();
        world.updatePlayerBlockIndex(player, player.currentBlock);
      }
      lockInput = true;
      pointerDown = false;
      randomPlayerCallout();
      await gameOver();
    }
  }

  // Input Handlers
  function handleInputDown() {
    if (lockInput || pointerDown) return;
    lockInput = true;
    pointerDown = true;

    downTimer = Date.now();
    currentHoldTime = 0;

    player.chargeJump(maxHoldTime);
  }

  function handleInputUp() {
    const jumpLeft = gameStore.getState().gameRun!.getNextBlockDirection() === BlockSpawnDirection.LEFT;
    if (!pointerDown) return;
    pointerDown = false;

    downTimer = Date.now() - downTimer;
    downTimer = clamp(downTimer, 0, maxHoldTime);

    const jumpDist = 100 + (downTimer / maxHoldTime) * 1000;
    const jumpDistX = jumpLeft ? jumpDist : 0;
    const jumpDistY = !jumpLeft ? jumpDist : 0;
    const jumpDuration = 0.3 + (downTimer / maxHoldTime) * 0.4;

    player.reset();
    player.jumpTo(-jumpDistX, -jumpDistY, jumpDuration);

    const targetBlock = blocks[blocks.length - 1];
    if (targetBlock instanceof MovingBlock) {
      world.scrollTo(targetBlock.startPos.x, targetBlock.startPos.y);
    } else {
      world.scrollTo(player.isoX - jumpDistX, player.isoY - jumpDistY);
    }

    gsap.delayedCall(
      jumpDuration,
      processMove.bind(null, {
        tapHeldDuration: downTimer,
        distanceJumped: jumpDist,
        directionJumped: gameStore.getState().gameRun!.getNextBlockDirection(),
      })
    );
  }

  async function processMove(args: {
    tapHeldDuration: number;
    distanceJumped: number;
    directionJumped: 'LEFT' | 'RIGHT';
  }) {
    const targetBlock: Block = blocks[blocks.length - 1];
    const targetBlockModel: BlockModel = gameStore.getState().gameRun!.lastBlock();
    if (targetBlock.checkHit(player.isoX, player.isoY)) {
      // success!
      targetBlock.receivePlayer();
      player.placeOnBlock(targetBlock);

      jumpCount++;

      if (jumpCount === 50) {
        updateAchievement(AchievementId.Daily50Jumps);
      }

      const jumpRating = JumpJudger.judgeJump({
        targetX: targetBlock.isoX,
        targetY: targetBlock.isoY,
        actualX: player.isoX,
        actualY: player.isoY,
      });
      const {
        points: pointsAdded,
        newTotalScore,
        currentCombo,
      } = gameStore.getState().scoreManager.increaseScoreFromJump(jumpRating, targetBlockModel);
      gameStore.getState().eventCollector.collectEvent({
        type: EVENT_TYPE.JUMP,
        tapHeldDuration: args.tapHeldDuration,
        ratingEarned: jumpRating,
        pointsEarned: pointsAdded,
        distanceJumped: args.distanceJumped,
        directionJumped: args.directionJumped,
      });
      let pointText = '';
      switch (jumpRating) {
        case JUMP_RATING.PURRFECT:
          if (player.currentCostume === CatCostume.Doge) {
            pointText = 'PAWFECT ';
          } else {
            pointText = 'PURRFECT ';
          }
          sound.play('land_perfect');
          sound.play('coins_more');
          particles.addParticles(player.x, player.y, ParticleType.Confetti, 8);
          break;
        case JUMP_RATING.GREAT:
          pointText = randGoodCallout() + ' ';
          sound.play('land_great');
          sound.play('coins_normal');
          particles.addParticles(player.x, player.y, ParticleType.Confetti, 4);
          break;
        case JUMP_RATING.GOOD:
          pointText = 'OK ';
          sound.play('land_normal');
          sound.play('coins_normal');
          break;
        case JUMP_RATING.OK:
          pointText = 'MEH ';
          sound.play('land_normal');
          sound.play('coins_less');
          break;
        case JUMP_RATING.ADEQUATE:
          sound.play('land_normal');
          sound.play('coins_less');
          break;
        default:
          assertNever(jumpRating);
      }
      const numCoinParticles = currentCombo > 0 ? pointsAdded / currentCombo : pointsAdded;
      particles.addParticles(player.x, player.y, ParticleType.Coin, numCoinParticles);

      // custom branding popups
      if (targetBlock.currentBrand === 'doge') {
        particles.addWowText(player.x, player.y - 50, randWow);
      } else {
        particles.addPopText(player.x, player.y - 250, pointText);
      }

      //gameStateService.scoreChanged(); // <-- notify React app
      scoreUI.updateScore(newTotalScore, currentCombo);

      if (currentCombo === 4) {
        updateAchievement(AchievementId.Daily4xCombo);
      }

      if (currentCombo === 10) {
        updateAchievement(AchievementId.Daily10xCombo);
      }

      // During a daily run, you get a time bonus instead of points
      switch (gameStore.getState().gameRun?.getGameMode()) {
        case GameMode.Normal:
          particles.addFloatText(player.x, player.y - 300, '+' + pointsAdded);
          break;
        case GameMode.DailyChallenge:
          if (currentCombo > 0) {
            // subtract 10ms x combo to your total time
            dailyChallengeSubtractedTime += currentCombo * 10;
            particles.addFloatText(player.x, player.y - 300, `-${currentCombo / 100}s`);
          }
          scoreUI.updateProgress((gameStore.getState().gameRun?.getBlocks().length || 0) - 1);
          if ((gameStore.getState().gameRun?.getBlocks().length || 0) - 1 >= ScoreManager.dailyChallengeBlocks) {
            // Daily run completed
            // TODO: Submit Daily run score and show result
            removeListeners();
            scoreUI.updateScore(0, 0);
            const elapsedTime = (Date.now() - startTime - dailyChallengeSubtractedTime) * 0.001;
            gameStore.getState().scoreManager.updateDailyChallengeTimeScore(elapsedTime); //TODO: refactor scoring system so time doesn't get sent to ScoreManager
            countdownUI.animateResult(elapsedTime);
            // FIXME: unify the concept of the end of a game run

            gameStore.getState().setGameplayState(GameplayState.GameOver);
            await Analytics.recordGameEvent('game_run_end', gameStore, {
              score: gameStore.getState().scoreManager.score.toString(),
            });

            gsap.delayedCall(3.0, () => {
              homeButtonClicked();
            });
            lockInput = true;
          }
          break;
      }

      addNextBlock();
      adjustCameraPosition();
      lockInput = false;
      player.faceDir(gameStore.getState().gameRun!.getNextBlockDirection() === BlockSpawnDirection.LEFT);
      vibrate(50);
    } else {
      // fail!

      // fell into donut hole
      if (targetBlock.checkDonutHole(player)) {
        world.updatePlayerBlockIndex(player, targetBlock);
        player.isoX = targetBlock.isoX;
        player.isoY = targetBlock.isoY;
        player.jumpDelta.set(0, 0);
      }
      // overshot
      else if (player.isoX < targetBlock.isoX || player.isoY < targetBlock.isoY) {
        world.setChildIndex(player, 0);
        // undershot
      } else {
        world.updatePlayerBlockIndex(player, targetBlock);

        // smacked into block
        if (targetBlock.getPlayerDist(player) < targetBlock.size + 60) {
          player.jumpDelta.set(0, 0);
        }
      }
      randomPlayerCallout();

      await gameOver();
    }
  }

  function adjustCameraPosition(respawning: boolean = false) {
    const nextBlock = blocks[blocks.length - 1];
    // If the next block is very far, we must adjust the camera position
    const screenDist = world.getScreenDistance(player, nextBlock, app.stage.scale.x);
    if (screenDist > window.innerWidth * 0.5 || respawning) {
      const playerPos = player.getIdealCameraPos();
      world.scrollTo((playerPos.x + nextBlock.startPos.x) * 0.5, (playerPos.y + nextBlock.startPos.y) * 0.5, true);
    }
    world.adjustZoom(player, nextBlock, app.stage.scale.x);
  }

  function checkJumpCancel(dt: number) {
    if (!pointerDown) return;

    // cancel jump if held for max time
    currentHoldTime += dt;
    if (currentHoldTime > maxHoldTime * 0.001) {
      player.cancelJump();
      lockInput = false;
      pointerDown = false;
      addPlayerCallout('-_-');
    }
  }

  // just for fun
  const randEmojis = ['*o*', 'x_x', 'O_o', 'T_T', 'v_v', '@_@', ';^;'];
  function randomPlayerCallout() {
    addPlayerCallout(randEmojis[randomRangeInt(0, randEmojis.length - 1)]);
  }
  const randCallouts = ['GREAT', 'NICE', 'AWESOME', 'GOOD'];
  function randGoodCallout() {
    return randCallouts[randomRangeInt(0, randCallouts.length - 1)];
  }
  const randWow = ['wow', 'wow', 'such jump', 'much doge', 'ew cats'];

  function addPlayerCallout(text: string) {
    particles.addFloatText(player.x, player.y - 200, text);
  }

  async function gameOver() {
    player.fall();
    vibrate(500);

    // Cat respawns on last block in Daily Challenge mode
    if (gameStore.getState().gameRun?.getGameMode() === GameMode.DailyChallenge) {
      gsap.delayedCall(0.5, () => {
        const lastBlock = blocks[blocks.length - 2];
        player.respawn();
        lastBlock.receivePlayer();
        if (lastBlock instanceof MovingBlock) {
          player.setPosition(lastBlock.isoX, lastBlock.isoY, player.isoZ);
        }
        player.placeOnBlock(lastBlock);
        world.updatePlayerBlockIndex(player, lastBlock);
        adjustCameraPosition(true);
        const combo = gameStore.getState().scoreManager.clearCombo();
        scoreUI.updateScore(0, combo);
        lockInput = false;
      });
      return;
    }

    player.playFailSound();
    await Analytics.recordGameEvent('game_run_end', gameStore, {
      score: gameStore.getState().scoreManager.score.toString(),
    });

    gsap.delayedCall(0.5, () => {
      gameStore.getState().setGameplayState(GameplayState.GameOver);
      removeListeners();
      handleGameOver();
      scoreUI.updateScore(gameStore.getState().scoreManager.score, 0);
      scoreUI.updateHighscoreText(gameStore.getState().scoreManager.score);
      lockInput = true;
    });
  }

  function getNewBlockPosition(blockModel: BlockModel, playerPosition: { isoX: number; isoY: number }) {
    if (blockModel.type === BlockType.STARTING) {
      return {
        x: playerPosition.isoX,
        y: playerPosition.isoY,
      };
    } else if (blockModel.direction === BlockSpawnDirection.LEFT) {
      return {
        x: playerPosition.isoX - blockModel.distance,
        y: playerPosition.isoY,
      };
    } else {
      return {
        x: playerPosition.isoX,
        y: playerPosition.isoY - blockModel.distance,
      };
    }
  }

  function addBlockFromBlockModel(blockModel: BlockModel, context: { isBlockVisibleBeforePlaying: boolean }): void {
    let block;
    const size = blockModel.size;
    const shape = blockModel.shape;
    const prevBlock = blocks[blocks.length - 1];
    const blockCoords =
      prevBlock instanceof MovingBlock || blockModel.type == BlockType.MOVING
        ? getNewBlockPosition(blockModel, {
            isoX: prevBlock.startPos.x,
            isoY: prevBlock.startPos.y,
          })
        : getNewBlockPosition(blockModel, {
            isoX: player.isoX,
            isoY: player.isoY,
          });

    switch (blockModel.type) {
      case BlockType.STARTING:
        block = blockPool.normal.pop();
        break;
      case BlockType.NORMAL:
        block = blockPool.normal.pop();
        if (blockModel.longJump) {
          // place a bullseye on long jump blocks
          block.addBranding('none', '/images/decals/bullseye_top.png');
        }
        break;
      case BlockType.MOVING:
        block = blockPool.moving.pop();
        (block as MovingBlock).setMovementAxis(blockModel.movementAxis === MovementAxis.X);
        break;
      case BlockType.SHAKING:
        block = blockPool.shaking.pop();
        break;
      case BlockType.BRIDGE:
        block = blockPool.bridge.pop();
        break;
      default:
        assertNever(blockModel);
        break;
    }

    if (block) {
      block.init(blockCoords.x, blockCoords.y, 0, size, shape);

      gameStore.getState().brandManager!.processBlockForBranding(blockModel, block, context);

      blocks.push(block);
      world.addChildAt(block, 0);
      block.animateIn();
    }

    if (blocks.length > 8) {
      const lastBlock = blocks.shift();
      if (lastBlock) {
        removeBlock(lastBlock);
      }
    }
  }

  function removeBlock(block: Block) {
    block.deInit();
    world.removeChild(block);
    //lastBlock.destory({ children: true, texture: true });

    // return the block to its pool instead of destroying it
    // TODO: find a cleaner implementation of this
    if (block instanceof BridgeBlock) {
      blockPool.bridge.push(block);
    } else if (block instanceof ShakingBlock) {
      blockPool.shaking.push(block);
    } else if (block instanceof MovingBlock) {
      blockPool.moving.push(block);
    } else {
      blockPool.normal.push(block);
    }
  }

  function addNextBlock(): void {
    const blockModel = gameStore.getState().gameRun!.getNextBlock();
    addBlockFromBlockModel(blockModel, { isBlockVisibleBeforePlaying: false });
  }

  /**
   *
   *
   * Initializes a new game run. Called the first time as well as on restart.
   */
  function initializeNewGameRun(gameMode: GameMode = GameMode.Normal): void {
    gameStore.getState().resetGameRun(Telegram!.WebApp!.initDataUnsafe!.user!.id, gameMode);
    gameStore.getState().resetScoreManager();
    gameStore.getState().eventCollector.reset();

    particles.clear();
    blocks.forEach((b) => removeBlock(b));
    blocks.length = 0;
    world.reset(centerX, centerY);

    downTimer = 0;
    lockInput = false;
    pointerDown = false;
    jumpCount = 0;
    startTime = Date.now();
    dailyChallengeSubtractedTime = 0;

    world.addChild(player);
    world.addChild(particles);
    scoreUI.clear();
    scoreUI.visible = false;
    scoreUI.initGameMode(gameMode);

    player.reset();
    player.setPosition(0, 0, -100);

    addBlockFromBlockModel(gameStore.getState().gameRun!.getBlocks()[0], {
      isBlockVisibleBeforePlaying: true,
    });
    addBlockFromBlockModel(gameStore.getState().gameRun!.getBlocks()[1], {
      isBlockVisibleBeforePlaying: true,
    });

    player.faceDir(gameStore.getState().gameRun!.getNextBlockDirection() === BlockSpawnDirection.LEFT);

    // add bullseye graphic
    blocks[0].addBranding('none', '/images/decals/bullseye_top.png');

    player.placeOnBlock(blocks[0]);
    player.update(0.001, blocks);

    if (gameMode === GameMode.DailyChallenge) {
      countdownUI.animateCountdown();
      scoreUI.updateProgress(0);
      gsap.delayedCall(3.0, () => {
        startTime = Date.now();
        dailyChallengeSubtractedTime = 0;
        addListeners();
      });
    } else {
      countdownUI.visible = false;
    }

    gameStore.getState().setGameplayState(GameplayState.ReadyToPlay);
  }

  function vibrate(time: number) {
    if ('vibrate' in navigator) {
      navigator.vibrate(time);
    }
  }

  function getCanvas() {
    return app.canvas;
  }

  function updateHighScoreUI(val: number) {
    scoreUI.setHighscore(val);
    scoreUI.updateHighscoreText(val);
  }

  function setPlayerCostume(equippedCostume?: CatCostume) {
    if (equippedCostume) {
      player.selectEquippedCostume(equippedCostume);
    } else {
      player.selectEquippedCostume(CatCostume.Default);
    }
  }

  function loadPlayerCostume(url: string) {
    player.loadSkin(url);
  }

  // expose these functions
  return {
    addListeners,
    removeListeners,
    stopUpdateLoop,
    resumeUpdateLoop,
    initializeNewGameRun,
    getCanvas,
    updateHighScoreUI,
    setPlayerCostume,
    loadPlayerCostume,
    setBgmVolume,
    setAllSoundVolume,
    playBgm,
    startBgm,
    getGameplayState,
  };
}
