import { z } from 'zod';
import { stringToJSONSchema } from '@/utils/zod';

export const UserDto = z.object({
  id: z.string(),
  metadata: stringToJSONSchema.pipe(
    z.object({
      communityId: z.number(),
    })
  ),
});
