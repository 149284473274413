import { Toast, ToastProps } from './Toast';
import { useToasts } from '../../contexts/ToastProvider';
import { groupBy } from 'lodash';
import styles from './ToastContainer.module.scss';
import cls from 'classnames';

export interface ToastItem extends ToastProps {
  id: number;
  position: 'top' | 'bottom';
}

export const ToastContainer: React.FC = () => {
  const { toasts, removeToast } = useToasts();
  const toastsByPosition = groupBy(toasts, 'position');

  return (
    <>
      {Object.entries(toastsByPosition).map(([position, toasts]) => (
        <div
          key={`toasts-${position}`}
          className={cls(styles.toastContainer, position === 'top' ? styles.top : styles.bottom)}
        >
          {toasts.map((toast, index) => (
            <Toast
              key={index}
              message={toast.message}
              type={toast.type}
              messageRight={toast.messageRight}
              onClose={() => removeToast(toast.id)}
            />
          ))}
        </div>
      ))}
    </>
  );
};
