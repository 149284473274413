import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Client, Session, Socket } from '@metatheoryinc/nakama-js';
import { Client as SatoriClient, Session as SatoriSession } from '@metatheoryinc/satori-js';
import { HiroClient } from '@metatheoryinc/hiro-js';
import { NakamaContextType } from './types/nakama';
import { InitializedNakamaClients } from '@/lib/arcade-sdk/loaders/nakama-loader';

export enum NAKAMA_ERRORS {
  USERNAME_TAKEN = 3,
  USER_NOT_GROUP_MEMBER = 7,
}

const NakamaContext = createContext<NakamaContextType | undefined>(undefined);

export const useNakama = (): NakamaContextType => {
  const context = useContext(NakamaContext);
  if (context === undefined) {
    throw new Error('useNakama must be used within an NakamaProvider');
  }
  return context;
};

interface NakamaProviderProps {
  children: ReactNode;
  nakamaClients: InitializedNakamaClients;
}

export const NakamaProvider: React.FC<NakamaProviderProps> = ({ children, nakamaClients }) => {
  const [client] = useState<Client>(nakamaClients.nakamaClient);
  const [session, setSession] = useState<Session>(nakamaClients.nakamaSession);
  const [hiroClient] = useState<HiroClient>(nakamaClients.hiroClient);
  const [satoriClient] = useState<SatoriClient>(nakamaClients.satoriClient);
  const [satoriSession] = useState<SatoriSession>(nakamaClients.satoriSession);
  const [socket] = useState<Socket>(nakamaClients.nakamaSocket);

  const [isReady] = useState(true);
  const [error] = useState<Error | undefined>(undefined);

  // todo: this should be handled higher up in the arcade sdk
  const changeUsername = async (input: string) => {
    try {
      await client?.updateAccount(session!, {
        username: input,
      });
      setSession(await client.sessionRefresh(session!));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error = await err.json();
      if (error.code === NAKAMA_ERRORS.USERNAME_TAKEN) {
        throw new Error('This username is already taken.');
      }
      throw new Error('Something went wrong.');
    }
  };

  const value: NakamaContextType = {
    client,
    hiroClient,
    satoriClient,
    satoriSession,
    session,
    socket,
    isReady,
    error,
    changeUsername,
  };

  return <NakamaContext.Provider value={value}>{children}</NakamaContext.Provider>;
};

export default NakamaProvider;
