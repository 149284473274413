import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../styles/app/Shop.module.scss';
import { usePageViewTracking } from '@/game-react/hooks/use-page-view-tracking';
import { Modal } from '@/components/Modal/Modal';
import { root } from '@/app/routes';
import { PawsIcon } from '@/components/Icons/PawsIcon';
import { useInventory } from '@/game-react/hooks/use-inventory';
import { InventoryCategories } from '@/utils/constants';
import { useToasts } from '@/contexts/ToastProvider';
import { ProfileInventory, ProfileInventoryItem } from '@/types/inventory';
import { ShopItem } from '@/features/shop/ShopItem';
import { ShopItemModal } from '@/features/shop/ShopItemModal';
import { Currency } from '@/types/cost';
import { createInvoice } from '@/api/order';
import { useArcadeSdk } from '@/lib/arcade-react-sdk/ArcadeSdkProvider';
import { useTranslation } from 'react-i18next';
import { CatCostume } from '@/types/CatCostume';
import { usePlayerData } from '@/hooks/use-player-leaderboard-data';

const profileInventoryDefault: ProfileInventory = {
  [InventoryCategories.Costumes]: {},
  [InventoryCategories.Environments]: {},
};

const filterInventory = (inventory: ProfileInventory, filter: (item: [string, ProfileInventoryItem]) => boolean) => {
  return {
    ...inventory,
    [InventoryCategories.Costumes]: Object.fromEntries(
      Object.entries(inventory[InventoryCategories.Costumes]).filter(filter)
    ),
  };
};

export const Shop: React.FC = () => {
  usePageViewTracking('shop');
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { data: playerData } = usePlayerData();
  const { t } = useTranslation();
  const {
    equippedItems,
    changeEquippedItem,
    purchaseItem,
    refetchUserInventory,
    profileInventory: apiInventory,
  } = useInventory();

  const [itemDetailModal, setItemDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ProfileInventoryItem | null>(null);

  const [profileInventory, setProfileInventory] = useState<ProfileInventory>(profileInventoryDefault);
  const [selectedCategory, _setSelectedCategory] = useState(InventoryCategories.Costumes);

  const { flags } = useArcadeSdk();

  useEffect(() => {
    const setApiInventory = () => {
      let inventory = apiInventory;

      if (!flags.dogeCommunity) {
        inventory = filterInventory(inventory, ([_, item]) => {
          return item.id !== CatCostume.Doge;
        });
      }

      setProfileInventory(inventory);
    };
    setApiInventory();
  }, [setProfileInventory, apiInventory, flags]);

  async function tryToPurchaseOrEquipItem(item: ProfileInventoryItem) {
    try {
      // Equip or unequip the item
      if (item.owned) {
        const itemId = equippedItems?.[item.category] === item.id ? '' : item.id;
        return await handleChangeEquippedItem(itemId, item.category);
      }
      // Purchase the item
      if (item.cost) {
        if (item.cost?.currency === Currency.Xtr) {
          const invoiceUrl = await createInvoice(item.id);
          window.Telegram.WebApp.openInvoice(invoiceUrl, (status) => {
            if (status === 'paid') {
              item.owned = true;
              refetchUserInventory();
            }
          });
        } else if (item.cost?.currency === Currency.Paws) {
          if ((playerData?.currencyAmount || 0) < item.cost.amount) {
            addToast(t('Oops! You don’t have enough Paws.'), 'info', 'bottom');
            return;
          }
          await purchaseItem(
            { itemId: item.id, cost: item.cost.amount },
            {
              onSuccess: () => setItemDetailModal(false),
            }
          );
        }
      }
    } catch {
      addToast(t('Oops! Something went wrong. Please try again.'), 'info', 'bottom');
    }
  }

  async function handleChangeEquippedItem(itemId: string, category: InventoryCategories) {
    changeEquippedItem(
      { itemId, category },
      {
        onSuccess: () => {
          // Update the profile inventory to reflect the change in equipped items only if the mutation is successful
          setProfileInventory((prev) => {
            const updatedInventory = { ...prev };
            const item = updatedInventory[category][itemId];
            if (item) {
              item.equipped = true;
            }
            for (const key in updatedInventory[category]) {
              if (key !== itemId) {
                updatedInventory[category][key].equipped = false;
              }
            }
            setItemDetailModal(false);
            return updatedInventory;
          });
        },
        onError: (error) => {
          console.error('Failed to change equipped item:', error);
          addToast(t('Oops! Something went wrong. Please try again.'), 'info', 'bottom');
        },
      }
    );
  }

  return (
    <Modal
      title="Shop"
      onClose={() => navigate(root.home.path)}
      fullScreen={false}
      attachment={
        <div className={styles.currencyWrapper}>
          <div className={styles.pawsWrapper}>
            <PawsIcon fill="#FFFFFF" />
          </div>
          {playerData && <span className={styles.paws}>{playerData.currencyAmount}</span>}
        </div>
      }
    >
      <div className={styles.skinsContainer}>
        <div className={styles.itemList}>
          {selectedCategory === InventoryCategories.Costumes &&
            profileInventory &&
            profileInventory[InventoryCategories.Costumes] &&
            Object.values(profileInventory[InventoryCategories.Costumes]).map((item) => {
              return (
                <ShopItem
                  key={item.id}
                  item={item}
                  onClick={() => {
                    setSelectedItem(item);
                    setItemDetailModal(true);
                  }}
                />
              );
            })}
        </div>

        {itemDetailModal && selectedItem && (
          <ShopItemModal
            item={selectedItem}
            onClose={() => setItemDetailModal(false)}
            unavailable={
              selectedItem.cost?.currency === Currency.Paws &&
              selectedItem.cost?.amount >= (playerData?.currencyAmount ?? 0)
            }
            onConfirm={async () => {
              await tryToPurchaseOrEquipItem(selectedItem);
            }}
          />
        )}
      </div>
    </Modal>
  );
};
