import { transformLeaderboardRecord } from '@/api/domains/leaderboards/leaderboard-utils';
import { queryKeys } from '@/api/queryKeys';
import { useNakama } from '@/contexts/NakamaProvider';
import { LeaderboardItem } from '@/types/leaderboard';
import { useInfiniteQuery } from 'react-query';

export const useEventLeaderboard = ({ id }: { id: string }) => {
  const { client, session } = useNakama();

  const { data, fetchNextPage, isLoading, isFetching, isFetchingNextPage, error, hasNextPage, refetch } =
    useInfiniteQuery(
      queryKeys.getEventLeaderboard(id),
      async ({ pageParam }) => {
        if (client && session) {
          const limit = 10;
          const cursor = pageParam ? pageParam : null;
          const result = await client.listTournamentRecords(session, id, [session!.user_id!], limit, cursor);

          const userIds = result.records?.map((record) => record.owner_id || '').filter(Boolean); //getUserIds(result);

          const getUsersResponse = await client.getUsers(session, userIds);

          const leaderboardItems: LeaderboardItem[] =
            result.records?.map((record) => {
              return transformLeaderboardRecord(record, getUsersResponse.users || []);
            }) || [];

          return {
            playerResult: (result.owner_records
              ? transformLeaderboardRecord(result.owner_records[0], getUsersResponse.users || [])
              : {}) as LeaderboardItem,
            results: leaderboardItems,
            nextCursor: result.next_cursor,
          };
        }
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage?.nextCursor;
        },
        retryOnMount: true,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      }
    );

  return {
    leaderboardData: {
      playerResult: data?.pages?.[0]?.playerResult,
      results: data?.pages.flatMap((page) => page?.results ?? []),
    },
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    error,
    hasNextPage,
    refetch,
  };
};
