import { z } from 'zod';
import { GimmecatBrandingConfig } from '@/types/satori/GimmecatBrandingConfig.gen';
import { Block } from './blocks/block';
import { BlockModel, BlockSpawnDirection, BlockType } from './engine/block-model';
import { weightedRandom } from './utils';

// Here we can reach into the type if we have a function that want's more immediate local access
type BrandingDefinitions = z.infer<typeof GimmecatBrandingConfig.shape.brandingDefinitions.element>;

type BrandingContext = {
  isBlockVisibleBeforePlaying: boolean;
};

export class BrandManager {
  public static readonly minBlockSize = 80;

  private unbrandedBoxesSeen = 0;
  constructor(private brandingConfig: GimmecatBrandingConfig) {}

  /**
   *
   * function processBlockForBranding will process all blocks rendered in a game run.
   *    1.) It will decide if it is time to see another branded block based on minGapBetweenBrandedBlocks.
   *    2.) It will compute whether branding is allowed based on the block model and other context.
   *    3.) It will try to apply branding to the box based on the above two conditions.
   *
   * Note:
   * When it's time to see a branded block, that specific block may not be compatible.
   * This function runs on each block so it will brand the next compatible block before resetting the count.
   */
  public processBlockForBranding(blockModel: BlockModel, block: Block, context: BrandingContext): void {
    const shouldBrandNextCompatibleBlock =
      this.unbrandedBoxesSeen >= this.brandingConfig.minimumGapBetweenBrandedBlocks;
    const isBoxCompatible = this.canBoxBeBranded(blockModel, context);
    const brand = this.selectBrandFromFrequencyTable();

    if (!shouldBrandNextCompatibleBlock || !isBoxCompatible || !brand) {
      this.unbrandedBoxesSeen++;
    } else {
      this.brandBox(blockModel, block, brand);
      this.unbrandedBoxesSeen = 0;
    }
  }

  private canBoxBeBranded(blockModel: BlockModel, context: BrandingContext) {
    const { isBlockVisibleBeforePlaying } = context;
    if (isBlockVisibleBeforePlaying) {
      return false;
    }
    switch (blockModel.type) {
      case BlockType.STARTING:
        return false;
      case BlockType.BRIDGE:
        return false;
      case BlockType.MOVING:
        return false;
      case BlockType.SHAKING:
        return false;
      case BlockType.NORMAL:
        if (blockModel.size < BrandManager.minBlockSize) return false;
        if (blockModel.longJump) return false;
        return true;
    }
  }

  private brandBox(blockModel: BlockModel, block: Block, brand: BrandingDefinitions) {
    if (blockModel.type !== BlockType.STARTING && blockModel.direction == BlockSpawnDirection.LEFT) {
      block.topPlaneRotated = true;
    }
    block.addBranding(
      brand.brandingDefinitionId,
      brand.topImageUrl,
      brand.sideImageUrl,
      brand.sideImageUrl,
      brand.topColor,
      brand.sideColor,
      brand.darkColor,
      brand.outlineColor
    );
  }

  private selectBrandFromFrequencyTable(): BrandingDefinitions | null {
    try {
      const selectedBrandIndex = weightedRandom(
        this.brandingConfig.brandingFrequencyTable.map((frequency) => {
          return frequency.percentage;
        })
      );
      const selectedBrandDefinitionId =
        this.brandingConfig.brandingFrequencyTable[selectedBrandIndex].brandingDefinitionId;
      const selectedBrand = this.brandingConfig.brandingDefinitions.find((brand) => {
        return brand.brandingDefinitionId === selectedBrandDefinitionId;
      });
      return selectedBrand || null;
    } catch {
      // it is possible to have no brands represented in the probability table as they are dynamically generated
      return null;
    }
  }
}
