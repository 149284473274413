import { EventPeriod } from '@/types/event';

export const queryKeys = {
  getEvent: (id: string) => ['get-events', id],
  getEventLeaderboard: (id: string) => ['get-event-leaderboard', id],
  getEventList: (period: EventPeriod) => ['get-event-list', period],
  getUserCommunity: () => ['get-user-community'],
  getPlayerData: () => ['get-player-data'],
  getLeaderboardData: (leaderboardId: string) => ['get-leaderboard-data', leaderboardId],
  getLeaderboardAroundUser: (leaderboardId: string) => ['get-leaderboard-around-user', leaderboardId],
  getPartnerGroups: () => ['get-partner-groups'],
  getUserAccount: () => ['get-user-account'],
  getTeamsLeaderboard: (pastCycle?: number) => ['get-teams-leaderboard', pastCycle],
};
