import { CatCostume } from '@/types/CatCostume';

export const costumes = Object.values(CatCostume);

export const getCostumeOrder = (costume: CatCostume): number => {
  return costumes.indexOf(costume);
};

export const inventoryCollection = 'inventory';
export const equippedItemsKey = 'equipped_items';

export enum InventoryCategories {
  Costumes = 'costumes',
  Environments = 'environments',
}

export const LOCAL_STORAGE_LAST_VIEWED_GROUP_LEADERBOARD_KEY = 'last_viewed_group_leaderboard';

export const getCostumeImg = (id: string) => `images/cat-skins/${id}/display.png`;
