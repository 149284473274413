export const QuestionIcon = () => {
  return (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.14773 12.0397C4.14773 12.8535 4.86336 13.5147 5.74461 13.5147C6.62586 13.5147 7.34211 12.8535 7.34211 12.0335V10.9691C7.34211 10.8997 7.38773 10.8385 7.45461 10.8178C9.54711 10.1566 10.9996 8.32471 10.9996 6.22659V5.44284C10.9996 2.79346 8.69336 0.584712 5.85773 0.529712C4.43273 0.498462 3.08773 0.994712 2.06711 1.91221C1.05336 2.83034 0.490234 4.06659 0.490234 5.38221C0.490234 6.20159 1.21211 6.86284 2.09398 6.86284C2.97586 6.86284 3.69086 6.20159 3.69086 5.38784C3.69086 4.87409 3.90961 4.39034 4.30711 4.02971C4.71337 3.66386 5.24501 3.46867 5.79148 3.48471C6.90398 3.50971 7.80586 4.39096 7.80586 5.44909V6.22659C7.80586 7.14409 7.10336 7.93346 6.12899 8.09909C4.98274 8.29471 4.14773 9.21846 4.14773 10.2953V12.0397ZM5.68711 17.7503C6.10151 17.7503 6.49894 17.5857 6.79196 17.2927C7.08499 16.9997 7.24961 16.6022 7.24961 16.1878C7.24961 15.7734 7.08499 15.376 6.79196 15.083C6.49894 14.79 6.10151 14.6253 5.68711 14.6253C5.27271 14.6253 4.87528 14.79 4.58225 15.083C4.28923 15.376 4.12461 15.7734 4.12461 16.1878C4.12461 16.6022 4.28923 16.9997 4.58225 17.2927C4.87528 17.5857 5.27271 17.7503 5.68711 17.7503Z"
        fill="#202025"
      />
    </svg>
  );
};

export default QuestionIcon;
