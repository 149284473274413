import { BlockModel, StartingBlockModel } from './block-model';
import { GameRunStrategy } from './game-modes/game-run-strategy';

export enum GameMode {
  Normal = 'Normal',
  DailyChallenge = 'DailyChallenge',
}

/**
 *
 * GameRun is responsible for the deterministic generation of blocks based on a seed.
 *   !!! All randomness that affects gameplay should come from here. (e.g. next block direction, size, and type)
 *   !!! Any randomness that doesn't affect gameplay can be elsewhere (e.g. confetti, emote selection, etc)
 *
 */
export class GameRun {
  // TODO(yoh): detect version mismatch due to API deploy timing

  /**
   *
   * @param startTime can be supplied on the server to replay the sequence
   */
  constructor(private strategy: GameRunStrategy) {}

  /**
   *
   * Public functions for fetching the sequence of blocks.
   * Note that these functions should not accept any outside
   * arguments so the blocks are a function of the random seed.
   *
   */

  getStartTime(): number {
    return this.strategy.startTime;
  }

  getGameMode(): GameMode {
    return this.strategy.gameMode;
  }

  getBlocks(): BlockModel[] {
    return this.strategy.blocks;
  }

  public getNextBlockDirection() {
    return (this.strategy.blocks[this.strategy.blocks.length - 1] as Exclude<BlockModel, StartingBlockModel>).direction;
  }
  public getNextBlock(): BlockModel {
    const previousBlock = this.strategy.blocks.length
      ? this.strategy.blocks[this.strategy.blocks.length - 1]
      : undefined;
    const nextBlock = this.strategy.computeNextBlock(previousBlock!, this.strategy.blocks.length - 1);
    this.strategy.blocks.push(nextBlock);
    return nextBlock;
  }

  public lastBlock() {
    return this.strategy.blocks[this.strategy.blocks.length - 1];
  }
}
