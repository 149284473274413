import { Button } from '@/components/Button/Button';
import { ProfileInventoryItem } from '@/types/inventory';
import cls from 'classnames';
import { InfoIcon } from '@/components/Icons/InfoIcon';
import { PawsIcon } from '@/components/Icons/PawsIcon';
import { StarIcon } from '@/components/Icons/StarIcon';
import styles from './ShopItem.module.scss';
import { getCostumeImg } from '@/utils/constants';
import { Currency } from '@/types/cost';

type ItemProps = {
  item: ProfileInventoryItem;
  onClick: (item: ProfileInventoryItem) => void;
};

export const ShopItem = ({ item, onClick }: ItemProps) => {
  return (
    <Button
      className={cls(styles.item, {
        [styles.selected]: item.equipped,
      })}
      onClick={() => onClick(item)}
    >
      <img src={getCostumeImg(item.id)} className={styles.itemImg} />
      <div className={styles.itemNameContainer}>
        <p>{item.name}</p>
        <div>
          {!item.owned && item.cost ? (
            <div className={styles.itemCost}>
              {item.cost.currency === Currency.Paws ? (
                <PawsIcon />
              ) : item.cost.currency === Currency.Xtr ? (
                <StarIcon fill="#FD8F20" />
              ) : null}
              {item.cost.amount}
            </div>
          ) : (
            <InfoIcon fill="#606060" />
          )}
        </div>
      </div>
    </Button>
  );
};
