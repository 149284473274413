import { useEffect, useState } from 'react';
import styles from './ChallengeHome.module.scss';
import { ChallengeTitle } from '../ChallengeTitle/ChallengeTitle';
import { usePageViewTracking } from '../../game-react/hooks/use-page-view-tracking';
import { FtueStepper } from '../Ftue/FtueStepper';
import { useUserFlags } from '../../contexts/UserFlagsProvider';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { PawsIcon } from '../Icons/PawsIcon';

type HomeProps = {
  onDailyChallengeStart: () => void;
  onMainMenu: () => void;
};

export const ChallengeHome = ({ onDailyChallengeStart, onMainMenu }: HomeProps) => {
  usePageViewTracking('challenge-home');
  const [ftueSeen, setFtueSeen] = useState(false);
  const { userFlags } = useUserFlags();

  useEffect(() => {
    const ftueSeen = userFlags.hasSeenFTUE;
    if (ftueSeen) {
      setFtueSeen(true);
    }
  }, [setFtueSeen, userFlags]);

  return (
    <div className={styles.challengeHome}>
      {!ftueSeen && <FtueStepper setFtueSeen={setFtueSeen} />}
      <div className={styles.gameTitle}>
        <ChallengeTitle onMainMenu={onMainMenu} />
      </div>
      <div className={styles.actionsContainer}>
        <Button primary onClick={onDailyChallengeStart} className={styles.dailyButton} playSound={'menu_item_select'}>
          <Text as="span" type="mono" className={styles.leftText}>
            Purchase Run
          </Text>
          <div className={styles.rightContainer}>
            <div className={styles.paw}>
              <PawsIcon />
            </div>
            <Text as="span" type="mono" className={styles.rightText}>
              5000
            </Text>
          </div>
        </Button>
        <Text as="span" type="mono" className={styles.dailyTime}>
          TODAY'S CHALLENGE ENDS IN 00H 00M
        </Text>
      </div>
    </div>
  );
};
