import gameTitle from '../../assets/game-title.png';
import gameTitleBg from '../../assets/game-title-bg.png';
import styles from './GameTitle.module.scss';

export const GameTitle = () => {
  return (
    <div className={styles.gameTitleContainer}>
      <img className={styles.gameTitle} src={gameTitle} />
      <img className={styles.gameTitleBg} src={gameTitleBg} />
    </div>
  );
};
