import { BottomSheet } from '@/components/BottomSheet/BottomSheet';
import { PawsIcon } from '@/components/Icons/PawsIcon';
import { StarIcon } from '@/components/Icons/StarIcon';
import { ProfileInventoryItem } from '@/types/inventory';
import { getCostumeImg } from '@/utils/constants';
import { CommunityDetail } from '../profile/CommunityDetail';
import { Text } from '@/components/Text/Text';
import { Button } from '@/components/Button/Button';
import styles from './ShopItemModal.module.scss';
import { Currency } from '@/types/cost';
import { usePartnerGroups } from '@/hooks/use-partner-groups';

type ShopItemModalProps = {
  item: ProfileInventoryItem;
  unavailable?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ShopItemModal = ({ item, onClose, onConfirm, unavailable = false }: ShopItemModalProps) => {
  const { partnerGroups } = usePartnerGroups();

  if (!partnerGroups) {
    return null;
  }

  const community = partnerGroups.find((group) => group.costume === item.id);

  return (
    <BottomSheet
      onClose={onClose}
      title={item.name}
      action={
        <div className={styles.modalActionContainer}>
          {!item.owned && item.cost && (
            <div className={styles.modalItemCost}>
              {item.cost.currency === Currency.Paws ? (
                <PawsIcon />
              ) : item.cost.currency === Currency.Xtr ? (
                <StarIcon fill="#FD8F20" />
              ) : null}
              <span>{item.cost.amount}</span>
            </div>
          )}
          <Button fullWidth onClick={onConfirm} className={styles.button} primary disabled={unavailable}>
            {item.owned ? 'Equip Costume' : 'Buy Costume'}
          </Button>
        </div>
      }
    >
      <div className={styles.modalContent}>
        {/** Translation Note: item.description maybe shouldn't be translated? */}
        {item.description && (
          <Text as={'p'} type={'body'} size="s" className={styles.itemDescription}>
            {item.description}
          </Text>
        )}
        <img src={getCostumeImg(item.id)} className={styles.modalImage} />
      </div>

      {community && <CommunityDetail community={community} />}
    </BottomSheet>
  );
};
