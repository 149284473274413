import { route, string } from 'react-router-typesafe-routes/dom';

export const root = {
  home: route(''),
  game: route('game'),
  leaderboard: route('leaderboard'),
  profile: route('profile'),
  rewards: route('rewards'),
  options: route('options'),
  shop: route('shop'),
  event: route(
    'event',
    {},
    {
      Single: route(':id', { params: { id: string() } }),
    }
  ),
  teams: route('teams'),
};
