import { useId } from 'react';

export const MusicIcon = () => {
  const id = useId();
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#{id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.671 4.13516C18.9575 4.08738 19.2509 4.10257 19.5309 4.17966C19.8109 4.25675 20.0707 4.39389 20.2924 4.58156C20.514 4.76923 20.6921 5.00291 20.8143 5.26637C20.9365 5.52983 20.9999 5.81674 21 6.10716V17.9272C20.9998 18.8077 20.7091 19.6635 20.1729 20.3619C19.6368 21.0604 18.8852 21.5624 18.0346 21.7901C17.1841 22.0179 16.2822 21.9586 15.4687 21.6216C14.6553 21.2845 13.9758 20.6885 13.5357 19.9259C13.0955 19.1633 12.9193 18.2768 13.0343 17.4038C13.1493 16.5309 13.5491 15.7202 14.1717 15.0977C14.7944 14.4751 15.605 14.0754 16.478 13.9605C17.351 13.8456 18.2375 14.0219 19 14.4622V10.1072L9 11.7742V18.9272C9 18.9872 8.995 19.0452 8.985 19.1012C9.05679 19.8701 8.87222 20.6413 8.46005 21.2944C8.04787 21.9475 7.43124 22.446 6.70622 22.7121C5.9812 22.9783 5.18852 22.9971 4.45166 22.7658C3.71481 22.5344 3.07518 22.0658 2.63242 21.433C2.18965 20.8002 1.96863 20.0387 2.00378 19.2672C2.03893 18.4957 2.32828 17.7575 2.82676 17.1675C3.32523 16.5776 4.00482 16.1691 4.75966 16.0057C5.5145 15.8423 6.30219 15.9332 7 16.2642V7.77416C6.99994 7.30072 7.16784 6.84261 7.47381 6.48133C7.77979 6.12006 8.20401 5.87904 8.671 5.80116L18.671 4.13516ZM9 9.74716L19 8.08016V6.10716L9 7.77416V9.74716Z"
          fill="#342236"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" transform="translate(0 0.927246)" />
        </clipPath>
      </defs>
    </svg>
  );
};
