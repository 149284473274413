import React from 'react';
import cls from 'classnames';
import styles from './Text.module.scss';
import { Trans, useTranslation } from 'react-i18next';

type ReactI18NextChild = React.ReactNode | Record<string, unknown>;

type TextProps = {
  as: React.ElementType;
  className?: string;
  type: 'mono' | 'body' | 'heading' | 'button';
  size?: 'xxs' | 'xs' | 's' | 'md' | 'lg' | 'xl';
  weight?: 'light' | 'regular' | 'medium' | 'semibold' | 'bold' | 'black';
  translate?: boolean;
  uppercase?: boolean;

  children: ReactI18NextChild | ReactI18NextChild[];
};

export function Text({ children, as, className, size, type, weight, uppercase, translate = true }: TextProps) {
  const Component = as;
  const { t } = useTranslation();

  return (
    <Component
      className={cls(styles[size || ''], styles[type], styles[weight || ''], uppercase && styles.uppercase, className)}
    >
      {translate ? <Trans t={t}>{children}</Trans> : <>{children as React.ReactNode}</>}
    </Component>
  );
}
