import { useEffect } from 'react';
import styles from '@/styles/loading.module.scss';
import gameTitle from '@/assets/game-title-2.png';
import { initAfterBrandingData } from '@/game-raw/StartGame';
import { useGameStore, GameState } from '@/store/gameStore';

// TODO: deprecate this Loading component in favor of the root level loading pattern with the arcade sdk
export const Loading = () => {
  const { setBrandManager } = useGameStore<GameState>();

  // Note: This boot sequence is fragile and perilous.
  // Note: Layout.tsx waits for BrandManager to be set before lifting suspense, which is set here, ensuring that initAfterTelegramAndBrandingData fires.
  // TODO: Express the boot sequence declaratively to ensure the prerequisites for initializing are completed.
  useEffect(() => {
    (async () => {
      //setBrandManager(new BrandManager(getBrandingData()));
      await initAfterBrandingData();
    })();
  }, [setBrandManager]);

  return (
    <div className={styles.loadingScreen}>
      <img src={gameTitle} className={styles.gameTitle} />
      <div className={styles.progressBarWrapper}>
        <ProgressBar progress={100} />
      </div>
    </div>
  );
};

const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={{ width: `calc(${progress}% - 16px)` }} />
    </div>
  );
};
