import { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './Modal.module.scss';
import { BackButton } from '../BackButton/BackButton';

type Props = {
  onClose: () => void;
  title: string;
  fullScreen?: boolean;
  attachment?: ReactNode;
  children: ReactNode;
};

export const Modal = ({ onClose, title, attachment, children, fullScreen = true }: Props) => {
  return (
    <div className={styles.modalOverlay}>
      <div
        className={classnames(styles.modalContent, {
          [styles.fullScreen]: fullScreen,
        })}
      >
        <div className={styles.modalHeader}>
          <BackButton onClick={onClose} />

          <h1 className={styles.modalTitle}>{title}</h1>

          {attachment && <div className={styles.attachment}>{attachment}</div>}
        </div>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
};
