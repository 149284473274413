export const TeamIcon = ({ fill = '#202025' }: { fill?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={20} fill="none">
      <g fill={fill}>
        <circle cx={23.923} cy={5.418} r={3.106} />
        <circle cx={5.819} cy={5.418} r={3.106} />
        <circle cx={14.871} cy={5.399} r={4.476} />
        <path d="M6.964 16.873a5.376 5.376 0 0 1 5.376-5.376h5.061a5.376 5.376 0 0 1 5.377 5.376v.412c0 .99-.803 1.792-1.793 1.792H8.756c-.99 0-1.792-.803-1.792-1.792v-.412Z" />
        <path
          fillRule="evenodd"
          d="M19.003 11.826c-.14.389-.216.808-.216 1.245 0 .68.551 1.23 1.231 1.23h1.977a5.703 5.703 0 0 0-2.992-2.475Zm4.214 2.476h4.61c.68 0 1.231-.551 1.231-1.231a3.692 3.692 0 0 0-3.692-3.693H22.48a3.687 3.687 0 0 0-2.96 1.486 6.79 6.79 0 0 1 3.697 3.438ZM4.376 9.378a3.692 3.692 0 0 0-3.693 3.693c0 .68.551 1.23 1.231 1.23h4.61a6.79 6.79 0 0 1 3.698-3.437 3.687 3.687 0 0 0-2.96-1.486H4.376Zm6.363 2.448a5.703 5.703 0 0 0-2.992 2.476h1.976c.68 0 1.231-.551 1.231-1.231 0-.437-.076-.856-.215-1.245Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
};
