import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { root } from '@/app/routes';
import styles from './Currency.module.scss';
import catImage from '../../assets/points_cat.png';
import { FireIcon } from '../Icons/FireIcon';
import { usePageViewTracking } from '../../game-react/hooks/use-page-view-tracking';
import { useNakama } from '../../contexts/NakamaProvider';
import { useNakamaApi } from '../../contexts/useNakamaApi';
import { useQueryClient } from 'react-query';
import useDailyCheckInAchievement from '../../game-react/hooks/use-daily-check-in-achievement';
import { useReferrals } from '../../contexts/ReferralsProvider';
import { PawsIcon } from '../Icons/PawsIcon';
import useQuestAchievements from '../../game-react/hooks/use-daily-achievements';
import { ArrowRight } from '../Icons/ArrowRight';
import { Achievement } from '@metatheoryinc/hiro-js';
import { Button } from '../Button/Button';
import { Checkmark } from '../Icons/Checkmark';
import { Ellipse } from '../Icons/Ellipse';
import { Modal } from '../Modal/Modal';

import coinsBackground from '../../assets/currency-coins-background.png';
import { Text } from '../Text/Text';
import { usePlayerData } from '@/hooks/use-player-leaderboard-data';

export function Currency() {
  const { calculateTimeLeftToReset, isClaimed, getPointsReward, updateAchievement, claimAchievement } = useNakamaApi();
  const { session, hiroClient } = useNakama();
  const queryClient = useQueryClient();
  const [isClaiming, setIsClaiming] = useState<boolean>(false);
  const { achievement: dailyCheckIn } = useDailyCheckInAchievement();
  const [timeLeft, setTimeLeft] = useState<number>();
  const { referralClaims, claimReferral, share, refetchReferrals } = useReferrals();
  const { achievements: quests } = useQuestAchievements();

  const tg = window.Telegram.WebApp;

  usePageViewTracking('rewards');
  const { data: playerData, isLoading: isLoading } = usePlayerData();
  const navigate = useNavigate();

  useEffect(() => {
    if (dailyCheckIn === undefined) return; // do nothing until this is set

    setTimeLeft(calculateTimeLeftToReset(dailyCheckIn));
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeftToReset(dailyCheckIn));
    }, 1000);

    return () => clearInterval(timer);
  }, [dailyCheckIn, calculateTimeLeftToReset]);

  function formatTime(time: number): string {
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    return `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m`;
  }

  async function claimDailyCheckIn(): Promise<void> {
    if (dailyCheckIn === undefined) return;

    setIsClaiming(true);
    try {
      await dailyCheckIn.claim();
      await queryClient.invalidateQueries();
    } catch (e) {
      console.error(e);
    } finally {
      setIsClaiming(false);
    }
  }

  async function claimQuestAchievement(achievementId: string): Promise<void> {
    await claimAchievement(achievementId);
    await queryClient.invalidateQueries();
  }

  async function handleClaimReferral(code: string, recipientId: string): Promise<void> {
    if (!hiroClient || !session) {
      return;
    }

    claimReferral(session, hiroClient, code, recipientId);
    await queryClient.invalidateQueries();
    await refetchReferrals();
  }

  async function goToUrl(achievement: Achievement): Promise<void> {
    const url = achievement.additional_properties!['url'];
    if (tg.isVersionAtLeast('7.1')) {
      // This function closes the App up to version 7.0
      // https://core.telegram.org/bots/webapps#initializing-mini-apps
      tg.openTelegramLink(url);
    } else {
      tg.openLink(url, { try_instant_view: true });
    }
    await updateAchievement(achievement.id!, 1);
    await queryClient.invalidateQueries();
  }

  return (
    <Modal onClose={() => navigate(root.home.path)} title="Paws">
      <Text as="p" type="body" className={styles.currencyDescription}>
        Collect Paws from points scored, daily check-ins, referrals, and quests.
      </Text>

      <div className={styles.currencyAmount}>
        <PawsIcon />
        {playerData && !isLoading && <span>{playerData.currencyAmount}</span>}
        <img src={catImage} className={styles.catImage} />
      </div>

      <Text as="p" type="mono" className={styles.subHeading}>
        Daily Streak
      </Text>
      <div className={styles.dailyCheckin}>
        <img src={coinsBackground} className={styles.coinsBackground} />
        <div className={styles.streak}>
          <FireIcon />
          <h2>{dailyCheckIn?.streak}</h2>
        </div>
        <div className={styles.checkinRewards}>
          {!dailyCheckIn || isClaimed(dailyCheckIn) ? (
            <div className={styles.timerWrapper}>
              <Text as="span" type="body" className={styles.timer}>
                Ready in{' '}
              </Text>
              <span className={styles.timer}>{formatTime(timeLeft ?? 0)}</span>
            </div>
          ) : (
            <>
              <span className={styles.reward}>
                <PawsIcon />+{dailyCheckIn.pointsReward}
              </span>
            </>
          )}
        </div>
        <Button
          primary
          fullWidth
          disabled={!dailyCheckIn || isClaimed(dailyCheckIn) || isClaiming}
          className={classnames(
            {
              [styles.disabled]: !dailyCheckIn || isClaimed(dailyCheckIn) || isClaiming,
            },
            styles.checkinButton
          )}
          onClick={claimDailyCheckIn}
        >
          <Text as="span" type="button">
            {!dailyCheckIn || !isClaimed(dailyCheckIn) ? 'Check In' : 'Checked In'}
          </Text>
        </Button>
      </div>

      <Text as="p" type="mono" className={styles.subHeading}>
        Referrals
      </Text>
      <div className={styles.list}>
        {referralClaims && referralClaims.length > 0 ? (
          <>
            {referralClaims.map((claim) => {
              return (
                <div key={claim.recipientId}>
                  <div className={styles.listItem}>
                    <div className={styles.itemDescription}>
                      <b>{claim.recipientName}</b>
                      <span>
                        <PawsIcon />+{claim.reward}
                      </span>
                    </div>
                    <Button secondary onClick={() => handleClaimReferral(claim.code, claim.recipientId)}>
                      Claim
                    </Button>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className={styles.listItem}>
              <div className={styles.itemDescription}>
                <b>Share with friends to earn </b>
                <span className={styles.emptyReferralText}>
                  Earn <PawsIcon /> +100 each
                </span>
              </div>
              <div>
                <Button onClick={share}>
                  <ArrowRight />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>

      <p className={styles.subHeading}>Quests</p>
      <div className={styles.list}>
        {quests &&
          quests.length > 0 &&
          quests
            .sort((a, b) => {
              // unclaimed achievements show up first
              if (isClaimed(a) !== isClaimed(b)) {
                return isClaimed(a) ? 1 : -1;
              }

              // one-time achievements show up first
              if (a.category !== b.category) {
                return a.category === 'once' ? -1 : 1;
              }

              return 0;
            })
            .map((quest) => {
              return (
                <div key={quest.id} className={`${styles.listItem} ${isClaimed(quest) ? styles.claimed : ''}`}>
                  <div className={styles.itemDescription}>
                    <b>{quest.description}</b>
                    <span>
                      <PawsIcon />+{getPointsReward(quest)}
                      {quest.category === 'daily' ? (
                        <>
                          <Ellipse />
                          <span className={styles.dailyQuestSubline}>Daily Quest</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                  <div>
                    {isClaimed(quest) ? (
                      <Checkmark />
                    ) : quest.category === 'once' ? (
                      <Button onClick={() => goToUrl(quest)}>
                        <ArrowRight />
                      </Button>
                    ) : quest.count === quest.max_count ? (
                      <Button secondary onClick={() => claimQuestAchievement(quest.id!)}>
                        claim
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
      </div>
    </Modal>
  );
}
