import { UserCommunity } from '@/hooks/use-user-account';
import { UserGroup } from '@/types/UserGroup';

export const isEventActive = (endAt: Date) => {
  const now = new Date();
  return endAt > now;
};

export const isUserInEvent = (userGroup: UserCommunity, eventCommunities: UserGroup[]) => {
  return eventCommunities.find((x) => x.id === userGroup.id) !== undefined;
};
