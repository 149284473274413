import { assertNever, formatTime } from '@/game-raw/utils';
import styles from './LeaderboardEntry.module.scss';
import cls from 'classnames';

export enum LeaderboardScoreType {
  numberScore,
  timeScore,
}

export const LeaderboardEntry = ({
  name,
  score = -1,
  scoreType = LeaderboardScoreType.numberScore,
  rank = -1,
  highlight = false,
  img,
  className,
  fowardedRef,
  single,
}: {
  name: string;
  score?: number;
  scoreType?: LeaderboardScoreType;
  rank?: number;
  highlight?: boolean;
  img?: string;
  className?: string;
  fowardedRef?: React.ForwardedRef<HTMLDivElement>;
  single?: boolean;
}) => {
  let formattedScore;
  switch (scoreType) {
    case LeaderboardScoreType.numberScore:
      formattedScore = score >= 0 ? score : '-';
      break;
    case LeaderboardScoreType.timeScore:
      formattedScore = formatTime(score / 1000);
      break;
    default:
      assertNever(scoreType);
      break;
  }
  return (
    <div
      ref={fowardedRef}
      key={rank}
      className={cls(
        className,
        styles.leaderboardItem,
        {
          [styles.highlightItem]: highlight,
        },
        {
          [styles.single]: single,
        }
      )}
    >
      {rank && <h4 className={styles.playerRank}>{rank >= 0 ? rank : '-'}</h4>}
      <div className={styles.player}>
        {img && <img src={img} />}
        <p className={styles.playerUsername}>{name}</p>
      </div>
      <h3 className={styles.playerScore}>{formattedScore}</h3>
    </div>
  );
};
