import styles from './CommunityDetail.module.scss';
import { Text } from '@/components/Text/Text';
import { TelegramButton } from '@/components/Button/TelegramButton';
import { PartnerGroup } from '@/hooks/use-partner-groups';

type CommunityDetailProps = {
  community: PartnerGroup;
};

export const CommunityDetail = ({ community }: CommunityDetailProps) => {
  const horizontalLogo = community.horizontalLogo;
  const telegramLink = community.telegramLink;

  return (
    <>
      <div className={styles.detailsContainer}>
        <div className={styles.communityHeader}>
          {horizontalLogo && <img src={horizontalLogo} />}
          {telegramLink && <TelegramButton telegramLink={telegramLink} communityName={community.displayName} />}
        </div>
        <Text as={'p'} type={'body'} size="s" translate={false}>
          {community.description}
        </Text>
      </div>
    </>
  );
};
