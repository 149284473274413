import { LeaderboardRecord } from '@metatheoryinc/nakama-js';

export enum LeaderboardType {
  ALL = 'global_alltime_highscore',
  WEEKLY = 'global_weekly_highscore',
  DAILY = 'global_daily_highscore',
  DAILY_CHALLENGE = 'global_daily_challenge_highscore',
  COMMUNITY_REPRESENTATION = 'community_rep_user_score',
}

export type LeaderboardItem = {
  id: string;
  name: string;
  score: number;
  rank: number;
  image?: string;
  communityId?: number;
};

export const getScoreFrom = (record?: LeaderboardRecord): number => Number(record?.score ?? 0);

export const getRankFrom = (record?: LeaderboardRecord): number =>
  record?.rank !== undefined ? Number(record.rank) : -1;
