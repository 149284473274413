import seedrandom from 'seedrandom';
import { BlockModel, BlockShape, BlockSpawnDirection, BlockType, MovementAxis } from '../block-model';
import { GameMode } from '../game-run';
import { GameRunStrategy } from './game-run-strategy';

export class DailyRunStrategy implements GameRunStrategy {
  public readonly gameMode: GameMode = GameMode.DailyChallenge;
  public readonly telegramUserId: number;
  public readonly startTime: number;
  public readonly blocks: BlockModel[];
  private random: seedrandom.PRNG;

  // branding considerations
  private readonly minLargeBlockSize: number = 80;
  private readonly LargeBlockInterval: number = 8;
  private currentLargeBlockInterval: number = 0;

  constructor(telegramUserId: number, startTime?: number) {
    this.telegramUserId = telegramUserId;
    this.startTime = startTime || Date.now();
    this.random = seedrandom(this.seed);
    this.blocks = [
      {
        type: BlockType.STARTING,
        shape: BlockShape.SQUARE,
        size: 100,
        scoreValue: 1,
      },
      {
        type: BlockType.NORMAL,
        shape: BlockShape.SQUARE,
        distance: 400,
        size: 100,
        direction: this.computeNextBlockSpawnDirection(),
        longJump: false,
        scoreValue: 1,
      },
    ];
  }

  public computeNextBlock(previousBlock: BlockModel, blocksCleared: number): BlockModel {
    const difficultyRamp = 5;
    // const circleShapeChance = 0.25;
    const shakingBlockChance = 0;
    const movingBlockChance = 0.1;
    const bridgeSequenceChance = 0;
    const longDistanceChance = 0.1;
    // const donutChance = 0.0; // donuts are broken atm

    // random block size
    let mod = 70 - blocksCleared * difficultyRamp;
    mod = mod < 0 ? 0 : mod;
    let blockSize = 30 + mod + this.random() * (90 - mod);
    const blockSpawnDistance =
      previousBlock.size + blockSize + 100 + this.random() * 200 + (previousBlock.type === BlockType.MOVING ? 100 : 0);
    const blockSpawnDirection: BlockSpawnDirection =
      previousBlock.type === BlockType.MOVING ? previousBlock.direction : this.computeNextBlockSpawnDirection();

    // Ensure large block appears at the given interval for branding purposes
    if (blockSize >= this.minLargeBlockSize) {
      this.currentLargeBlockInterval = this.LargeBlockInterval;
    } else {
      this.currentLargeBlockInterval--;
      if (this.currentLargeBlockInterval <= 0) {
        this.currentLargeBlockInterval = this.LargeBlockInterval;
        blockSize = this.minLargeBlockSize;
      }
    }

    // FIXME: Nico: apparently circle blocks create multiple canvases in Safari/iOS!?
    // Need to investigate what is going on here, in the meantime disable circle blocks.
    const blockShape = BlockShape.SQUARE;

    // random block shape
    // let blockShape = BlockShape.SQUARE;
    // if (this.random() < donutChance) {
    //   blockShape = BlockShape.DONUT;
    // } else if (this.random() < circleShapeChance) {
    //   blockShape = BlockShape.CIRCLE;
    // }

    const blockSizeBonus = blockSize < 50 ? 1 : 0;

    // continue bridge sequence
    if (previousBlock.type == BlockType.BRIDGE && previousBlock.count > 0) {
      return {
        type: BlockType.BRIDGE,
        shape: BlockShape.SQUARE,
        distance: previousBlock.size + 120 + this.random() * 120,
        size: 60,
        direction: blockSpawnDirection,
        count: previousBlock.count - 1,
        scoreValue: 1,
      };
    }
    // Add shaking block
    else if (blocksCleared > 10 && this.random() < shakingBlockChance) {
      return {
        type: BlockType.SHAKING,
        shape: blockShape,
        distance: blockSpawnDistance,
        size: blockSize,
        direction: blockSpawnDirection,
        scoreValue: 1 + blockSizeBonus,
      };
    }
    // Add long distance jump
    else if (blocksCleared > 15 && previousBlock.type == BlockType.NORMAL && this.random() < longDistanceChance) {
      return {
        type: BlockType.NORMAL,
        shape: blockShape,
        distance: blockSpawnDistance + 200 + Math.random() * 200,
        size: blockSize,
        direction: blockSpawnDirection,
        longJump: true,
        scoreValue: 2 + blockSizeBonus,
      };
    }
    // Add bridge sequence
    else if (blocksCleared > 20 && this.random() < bridgeSequenceChance) {
      return {
        type: BlockType.BRIDGE,
        shape: BlockShape.SQUARE,
        distance: blockSpawnDistance,
        size: 60,
        direction: blockSpawnDirection,
        count: 8,
        scoreValue: 1,
      };
    }
    // Add moving block
    else if (blocksCleared > 25 && previousBlock.type == BlockType.NORMAL && this.random() < movingBlockChance) {
      return {
        type: BlockType.MOVING,
        shape: blockShape,
        distance: blockSpawnDistance + 100,
        size: blockSize < 60 ? 60 : blockSize, // Don't allow moving blocks below a certain size
        direction: previousBlock.direction,
        movementAxis: previousBlock.direction === BlockSpawnDirection.RIGHT ? MovementAxis.X : MovementAxis.Y,
        scoreValue: 2 + blockSizeBonus,
      };
    }
    // Add normal block
    else {
      return {
        type: BlockType.NORMAL,
        shape: blockShape,
        distance: blockSpawnDistance,
        size: blockSize,
        direction: blockSpawnDirection,
        longJump: false,
        scoreValue: 1 + blockSizeBonus,
      };
    }
  }

  /**
   *
   * Private helpers that use seeded randomness
   *
   */
  private get seed(): string {
    // FIXME: do this via an API
    return new Date().toISOString().slice(0, 10);
  }
  private computeNextBlockSpawnDirection() {
    return this.random() < 0.5 ? BlockSpawnDirection.RIGHT : BlockSpawnDirection.LEFT;
  }
}
