import { Modal } from '@/components/Modal/Modal';
import styles from './EventDetail.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { root } from '@/app/routes';
import { Tab, Tabs } from '@/components/Tabs/Tabs';
import { useState } from 'react';
import { Text } from '@/components/Text/Text';
import { Sparkles } from '@/components/Icons/Sparkles';
import { Accordion } from '@/components/Accordion/Accordion';
import { formatDateToUTC } from '@/utils/date';
import { TelegramButton } from '@/components/Button/TelegramButton';
import { CommunityEvent } from '@/types/event';
import { LeaderboardTable } from '../leaderboard/LeaderboardTable';
import { useEvent } from './hooks/use-event';
import { useEventLeaderboard } from './api/use-event-leaderboard';
import { LeaderboardEntry } from '../leaderboard/LeaderboardEntry';
import { Button } from '@/components/Button/Button';
import { t } from 'i18next';
import { useReferrals } from '@/contexts/ReferralsProvider';
import TelegramIcon from '@/components/Icons/TelegramIcon';
import { isEventActive, isUserInEvent } from '@/utils/events';
import { ArrowRight } from '@/components/Icons/ArrowRight';
import { usePageViewTracking } from '@/game-react/hooks/use-page-view-tracking';
import { useUserAccount } from '@/hooks/use-user-account';
import { usePartnerGroups } from '@/hooks/use-partner-groups';

const tabs: Tab[] = [
  {
    name: 'Overview',
    controls: 'all',
    id: 'overview',
  },
  {
    name: 'Leaderboard',
    controls: 'all',
    id: 'leaderboard',
  },
];

export function EventDetail() {
  usePageViewTracking('event-detail');
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedTab, setSelectedTab] = useState('overview');
  const { share, referralIncentive } = useReferrals();
  const { event } = useEvent({ id: id ?? '' });

  const { userAccount } = useUserAccount();
  const userCommunity = userAccount?.community;

  const canPlayEvent = event && userCommunity && isUserInEvent(userCommunity, event.organizers);

  return (
    <Modal
      onClose={() => {
        navigate(root.event.path);
      }}
      title={'Events'}
    >
      {event && (
        <>
          <div>
            <img src={event.splashImage} className={styles.cardImg} />
            <div className={styles.cardHeader}>
              <Text as={'h1'} type={'heading'} size="xl" className={styles.cardTitle} translate={false}>
                {event.title}
              </Text>
              <div>
                <span className={styles.tag}>{event.type}</span>
                <span className={styles.endDate}>{`ends ${formatDateToUTC(event.endTime)}`}</span>
              </div>
              {isEventActive(event.endTime) && (
                <>
                  <div className={styles.actionContainer}>
                    <Button
                      primary
                      fullWidth
                      onClick={() => navigate(root.home.path)}
                      className={styles.startGame}
                      disabled={!canPlayEvent}
                    >
                      {t('Play now')}
                    </Button>
                    <Button
                      onClick={share}
                      className={styles.shareButton}
                      playSound={false}
                      disabled={!referralIncentive}
                    >
                      <TelegramIcon fill={styles.telegramIconColor} />
                    </Button>
                  </div>
                  {!canPlayEvent && (
                    <div className={styles.joinCommunityCta}>
                      <Text as={'p'} type={'body'}>
                        Join {{ communities: event.organizers.map((x) => x.displayName).join(t(' or ')) }} community to
                        participate.
                      </Text>
                      <Button onClick={() => navigate(root.profile.path)}>
                        <ArrowRight color={styles.red} />
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.eventContent}>
            <Tabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            {selectedTab === 'overview' ? (
              <EventOverview event={event} />
            ) : (
              <div>
                <EventLeaderboardTab id={id ?? ''} />
              </div>
            )}
          </div>
        </>
      )}
    </Modal>
  );
}

const EventOverview = ({ event }: { event: CommunityEvent }) => {
  return (
    <>
      <Text as={'p'} type={'body'} size="s" className={styles.bodyText}>
        {event.description}
      </Text>
      <Accordion
        header={
          <div className={styles.header}>
            <div className={styles.contestImgWrapper}>
              <Sparkles className={styles.sparklesTop} />
              <Sparkles className={styles.sparklesBottom} />
              <img src="/images/events/gift.png" />
            </div>
            <Text as={'h3'} type={'heading'} size="md" className={styles.headerText}>
              {event.prize.title}
            </Text>
          </div>
        }
        content={
          <Text as={'p'} type={'body'} size="s" className={styles.bodyText}>
            {event.prize.description}
          </Text>
        }
      />
      <div className={styles.divider} />
      <Text as={'h2'} type={'mono'} size="xs" uppercase>
        Organized by
      </Text>
      {event.organizers.map((org) => {
        return (
          <Accordion
            key={`org-${org.displayName}`}
            header={
              <div className={styles.organizersHeader}>
                <img src={org.logo} className={styles.logo} />
                {org.horizontalLogo && <img src={org.horizontalLogo} className={styles.horizontalLogo} />}
              </div>
            }
            content={
              <div className={styles.organizerContent}>
                <Text as={'p'} type={'body'} size="s" className={styles.bodyText}>
                  {org.description}
                </Text>
                {org.telegramLink && <TelegramButton telegramLink={org.telegramLink} communityName={org.displayName} />}
              </div>
            }
          />
        );
      })}
    </>
  );
};

const EventLeaderboardTab = ({ id }: { id: string }) => {
  const { leaderboardData, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage, error, refetch } =
    useEventLeaderboard({ id });

  const { getPartnerGroup } = usePartnerGroups();
  const community = getPartnerGroup(leaderboardData.playerResult?.communityId?.toString());

  return (
    <div className={styles.leaderboardTab}>
      <div className={styles.divider} />
      <Text as={'h2'} type={'mono'} size="xs" uppercase>
        Your Standing
      </Text>
      <LeaderboardEntry
        name={leaderboardData.playerResult?.name ?? ''}
        img={community?.logo}
        score={leaderboardData.playerResult?.score}
        rank={leaderboardData.playerResult?.rank}
        single
      />
      <div className={styles.divider} />
      <Text as={'h2'} type={'mono'} size="xs" uppercase>
        Organized by
      </Text>
      <LeaderboardTable
        items={leaderboardData?.results}
        isFetchingData={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage ?? false}
        fetchNextPage={fetchNextPage}
        error={error === undefined}
        onError={refetch}
      />
    </div>
  );
};
