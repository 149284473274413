import { useState, useMemo, useCallback } from 'react';
import styles from './EditCommunity.module.scss';
import { useGroups } from '@/game-react/hooks/use-groups';
import { CommunityDetail } from './CommunityDetail';
import debounce from 'lodash/debounce';
import { Modal } from '@/components/Modal/Modal';
import SearchIcon from '@/components/Icons/SearchIcon';
import { ArrowRight } from '@/components/Icons/ArrowRight';
import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text/Text';
import { useGenericLeaderboardData, useGroupLeaderboardData } from '@/hooks/use-leaderboard-data';
import { BottomSheet } from '@/components/BottomSheet/BottomSheet';
import { useToasts } from '@/contexts/ToastProvider';
import { PartnerGroup, usePartnerGroups } from '@/hooks/use-partner-groups';
import { usePlayerData } from '@/hooks/use-player-leaderboard-data';

type EditCommunityModalProps = {
  onClose: () => void;
};

export function EditCommunity({ onClose }: EditCommunityModalProps) {
  const [groupDetails, setGroupDetails] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState<PartnerGroup | undefined>(undefined);
  const { updateCommunity } = useGroups();
  const [search, setSearch] = useState('');
  const { refetch: refetchPlayerData } = usePlayerData();
  const { refetch: refetchLeaderboardData } = useGenericLeaderboardData();
  const { refetchGroupLeaderboardData } = useGroupLeaderboardData();
  const { addToast } = useToasts();
  const { partnerGroups } = usePartnerGroups();

  if (!partnerGroups) {
    return null;
  }

  const communities = useMemo(() => {
    if (!search) {
      return partnerGroups;
    }

    return partnerGroups.filter((x) => x.displayName.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
  }, [search, partnerGroups]);

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearch(value);
      }, 300),
    []
  );

  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(e.target.value);
    },
    [debouncedSearch]
  );

  const handleUpdateCommunity = useCallback(async () => {
    if (!selectedCommunity) return;

    await updateCommunity(selectedCommunity).catch((error: Error) => {
      addToast(error.message, 'info', 'bottom');
    });
    await refetchPlayerData();
    await refetchLeaderboardData();
    await refetchGroupLeaderboardData();
    onClose();
  }, [
    selectedCommunity,
    updateCommunity,
    refetchPlayerData,
    refetchLeaderboardData,
    refetchGroupLeaderboardData,
    onClose,
    addToast,
  ]);

  return (
    <Modal onClose={onClose} title={'Select a Community'}>
      <Text type="body" as="p">
        Leaving a community will result in disqualification from their active contests.
      </Text>
      <div>
        <div className={styles.search}>
          <SearchIcon />
          <input className={styles.inputStyle} placeholder="Search" onChange={onSearch} />
        </div>
        <div className={styles.communitiesList}>
          {communities.map((community, i) => (
            <Button
              key={`${community.displayName}-${i}`}
              onClick={() => {
                setSelectedCommunity(community);
                setGroupDetails(true);
              }}
            >
              <div className={styles.communityCard}>
                {/** Translation Note: community.displayName shouldn't be translated? */}
                <Text as={'p'} type={'body'} weight="bold" size="md">
                  {community.displayName}
                </Text>
                <ArrowRight />
              </div>
            </Button>
          ))}
        </div>
      </div>
      {groupDetails && selectedCommunity && (
        <BottomSheet
          onClose={onClose}
          title={selectedCommunity.displayName}
          action={
            <Button onClick={handleUpdateCommunity} fullWidth primary>
              <Text type="button" as="span">
                Select Community
              </Text>
            </Button>
          }
        >
          <CommunityDetail community={selectedCommunity} />
        </BottomSheet>
      )}
    </Modal>
  );
}
