import { BitmapText, Container, Graphics, Point, TextStyle } from 'pixi.js';
import { randomRangeInt } from '../utils';
import { ThemeColors } from '../../api/player';

export enum ParticleType {
  Star,
  Coin,
  Confetti,
  FloatText,
  PopText,
  WowText,
}

const confettiColors = [0xb279dd, 0xff7360, 0xffab44, 0x7b92c9];
function randConfColor() {
  return confettiColors[randomRangeInt(0, confettiColors.length - 1)];
}

export class Particle extends Container {
  type: ParticleType = ParticleType.Coin;
  lifetime: number = 1.0;
  rotationSpeed: number = 0;
  spinTimer: number = 0;
  spinSpeed: number = 5;
  gravity: number = 0;
  damping: number = 0;
  delta: Point = new Point(0, 0);
  graphics: Graphics;
  text: BitmapText;

  constructor() {
    super();

    this.graphics = new Graphics();
    this.addChild(this.graphics);

    this.text = new BitmapText({
      text: 'POINTS',
      style: new TextStyle({
        fontFamily: 'DMMono-Medium',
        fontSize: 42,
        letterSpacing: 4,
        fill: 0x796a5e,
      }),
    });
    this.addChild(this.text);
  }

  public init(type: ParticleType, x: number, y: number, text?: string): void {
    this.type = type;
    this.x = x;
    this.y = y;
    this.lifetime = 1.0;
    this.rotationSpeed = 0;
    this.gravity = 0;
    this.damping = 0;
    this.delta.set(0, 0);
    this.scale.set(1, 1);
    this.pivot.set(0);
    this.rotation = 0;
    this.graphics.visible = false;
    this.text.visible = false;
    this.alpha = 1.0;
    this.tint = 0xffffff;

    switch (type) {
      case ParticleType.Star:
        this.graphics.clear();
        this.graphics.rect(-8, -8, 16, 16);
        this.graphics.fill(0xfffbf4);
        this.rotation = Math.PI * 0.25;
        this.x += -50 + Math.random() * 100;
        this.y += -50 + Math.random() * 100;
        this.graphics.visible = true;

        break;
      case ParticleType.Coin:
        this.graphics.clear();
        this.graphics.circle(0, 0, 16);
        this.graphics.fill(0xffc119);
        this.graphics.stroke({ width: 2, color: 0xc9703a });

        this.gravity = 3000;
        this.lifetime = 2.0;
        this.spinTimer = Math.random();
        this.spinSpeed = 5 + Math.random() * 15;
        this.rotation = Math.PI * 0.5 + Math.random() * Math.PI;
        this.rotationSpeed = -Math.PI + Math.random() * Math.PI * 2;
        this.graphics.visible = true;

        this.delta.x = -300 + Math.random() * 600;
        this.delta.y = -500 - Math.random() * 1000;
        break;
      case ParticleType.Confetti:
        this.graphics.clear();
        {
          const s = 8 + Math.random() * 16;
          this.graphics.moveTo(-s, 0);
          this.graphics.quadraticCurveTo(0, -s * 0.25, s, 0);
          this.graphics.stroke({ width: s, color: randConfColor() });
        }

        this.gravity = 2000;
        this.lifetime = 3.0;

        this.damping = 4;
        this.spinTimer = Math.random();
        this.spinSpeed = 10 + Math.random() * 15;
        this.rotation = Math.PI * 0.5 + Math.random() * Math.PI;
        this.rotationSpeed = -Math.PI * 2 + Math.random() * Math.PI * 4;
        this.graphics.visible = true;

        this.delta.x = -1000 + Math.random() * 2000;
        this.delta.y = -500 - Math.random() * 2000;
        break;
      case ParticleType.FloatText:
        this.text.text = text ?? 'GOOD'; // ah yes, assign text to the text's text
        this.text.visible = true;
        this.lifetime = 2.0;

        this.delta.x = 0;
        this.delta.y = -100;
        this.text.style.fontFamily = 'DMMono-Medium';
        this.text.style.fontSize = 52;
        this.text.style.fill = ThemeColors.uiText;
        this.text.style.letterSpacing = 4;
        this.pivot.set(this.text.width * 0.5, this.text.height * 0.5);
        break;
      case ParticleType.PopText:
        this.text.text = text ?? '+';
        this.text.visible = true;
        this.lifetime = 1.0;
        this.rotation = -Math.PI * 0.1 + Math.random() * Math.PI * 0.2;
        this.rotationSpeed = -Math.PI * 0.1 + Math.random() * Math.PI * 0.2;
        this.x += -50 + Math.random() * 100;
        this.y += -25 + Math.random() * 50;

        this.text.style.fontFamily = 'Rubik-Bold';
        this.text.style.fontSize = 64;
        this.text.style.fill = ThemeColors.uiAccentText;
        this.text.style.letterSpacing = 0;
        this.pivot.set(this.text.width * 0.5, this.text.height * 0.5);
        break;
      case ParticleType.WowText:
        this.text.text = text || '';
        this.text.visible = true;
        this.lifetime = 1.0 + Math.random() * 0.35;
        this.rotation = -Math.PI * 0.05 + Math.random() * Math.PI * 0.1;
        this.rotationSpeed = -Math.PI * 0.1 + Math.random() * Math.PI * 0.2;

        this.text.style.fontFamily = 'ComicSansMS-Bold';
        this.text.style.fontSize = this.text.text.length < 5 ? 80 : 64;
        this.text.style.fill = ThemeColors.uiText;
        this.text.style.letterSpacing = 0;
        this.pivot.set(this.text.width * 0.5, this.text.height * 0.5);
        break;
    }
  }

  public deInit() {
    this.graphics.visible = false;
    this.text.visible = false;
  }

  public update(dt: number): void {
    this.x += this.delta.x * dt;
    this.y += this.delta.y * dt;
    this.delta.y += this.gravity * dt;
    this.lifetime -= dt;

    // apply damping
    const ratio = 1 / (1 + dt * this.damping);
    this.delta.x *= ratio;
    this.delta.y *= ratio;

    switch (this.type) {
      case ParticleType.Star:
        if (this.lifetime > 0.5) {
          const val = (this.lifetime - 0.5) / 0.5;
          this.scale.set(easeOutBack(1.0 - val));
        } else {
          this.scale.set(easeOutCubic(this.lifetime * 2));
        }
        break;
      case ParticleType.Coin:
        this.rotation += this.rotationSpeed * dt;
        this.spinTimer += this.spinSpeed * dt;
        this.scale.y = Math.sin(this.spinTimer);

        // don't let the coin go completely flat
        if (this.scale.y > 0 && this.scale.y < 0.4) this.scale.y = 0.4;
        if (this.scale.y < 0 && this.scale.y > -0.4) this.scale.y = -0.4;

        // TODO: need to investigate if tint breaks batching
        if (this.scale.y < 0) {
          this.tint = 0xd6c6be;
        } else {
          this.tint = 0xffffff;
        }
        break;
      case ParticleType.Confetti:
        this.rotation += this.rotationSpeed * dt;
        break;
      case ParticleType.FloatText:
        this.alpha = this.lifetime / 2.0;
        break;
      case ParticleType.PopText:
        this.rotation += this.rotationSpeed * dt;
        if (this.lifetime > 0.5) {
          const val = (this.lifetime - 0.5) / 0.5;
          this.scale.set(easeOutElastic(1.0 - val));
        } else {
          this.scale.set(easeOutBack(this.lifetime * 2));
        }
        break;
      case ParticleType.WowText:
        if (this.lifetime > 1.0) {
          this.visible = false;
          return;
        }
        this.visible = true;
        this.rotation += this.rotationSpeed * dt;

        if (this.lifetime > 0.5) {
          const val = (this.lifetime - 0.5) / 0.5;
          this.scale.set(easeOutBack(1.0 - val));
        } else {
          this.scale.set(easeOutBack(this.lifetime * 2));
        }
        break;
    }

    // Easing curves from https://easings.net/
    function easeOutElastic(x: number): number {
      const c4 = (2 * Math.PI) / 3;

      return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
    }

    function easeOutBack(x: number): number {
      const c1 = 1.70158;
      const c3 = c1 + 1;

      return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
    }

    function easeOutCubic(x: number): number {
      return 1 - Math.pow(1 - x, 3);
    }
  }
}
