import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text/Text';
import styles from './LeaderboardFeature.module.scss';

export const LeaderboardError = ({ onTryAgain }: { onTryAgain: () => void }) => (
  <div className={styles.leaderboardTextContainer}>
    <Text as={'h1'} type={'heading'} size="xl" className={styles.errorText}>
      Oops!
    </Text>
    <div>
      <Text as="p" type="body" className={styles.errorText}>
        Looks like the leaderboard is taking a moment.
      </Text>
      <Text as="p" type="body" className={styles.errorText}>
        Tap to refresh and try again.
      </Text>
    </div>
    <Button primary onClick={onTryAgain} className={styles.retry}>
      <Text as="span" type="button">
        Retry
      </Text>
    </Button>
  </div>
);
