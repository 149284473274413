import { Graphics, Assets, MeshPlane, Texture, Point, FillGradient, DestroyOptions } from 'pixi.js';
import { distance, toIsoX, toIsoY } from '../../game-raw/utils';
import { isoContainer } from '../../game-raw/isoContainer';
import { gsap } from 'gsap';
import { Player } from '../player/player';
import { BlockShape } from '../engine/block-model';
import { ThemeColors } from '../../api/player';

export class Block extends isoContainer {
  size: number = 0;
  zHeight: number = 0;
  shape: BlockShape = BlockShape.SQUARE;
  graphics: Graphics = new Graphics();
  startPos: Point = new Point(0, 0);
  chargeEasing: string = 'power1.out';

  //branding images
  topPlane?: MeshPlane;
  leftPlane?: MeshPlane;
  rightPlane?: MeshPlane;
  topPlaneRotated: boolean = false;
  currentBrand: string = 'none';

  //colors
  topColor: string;
  sideColor: string;
  darkColor: string;
  outlineColor: string;

  public constructor() {
    super(0, 0, 0);
    this.addChild(this.graphics);
    this.topColor = ThemeColors.blockTop;
    this.sideColor = ThemeColors.blockSide;
    this.darkColor = ThemeColors.blockDark;
    this.outlineColor = ThemeColors.blockOutline;
  }

  public init(x: number, y: number, z: number, size: number, shape: BlockShape): void {
    this.setPosition(x, y, z);
    this.startPos.set(x, y);

    this.topColor = ThemeColors.blockTop;
    this.sideColor = ThemeColors.blockSide;
    this.darkColor = ThemeColors.blockDark;
    this.outlineColor = ThemeColors.blockOutline;
    this.currentBrand = 'none';

    this.size = size;
    this.zHeight = 100;
    this.shape = shape;

    this.blockSize.x = size;
    this.blockSize.y = this.zHeight;

    // TODO: move this to game-run
    if (this.shape == BlockShape.DONUT && this.size < 120) this.size = 120;

    this.redraw();
  }

  public deInit(): void {
    this.graphics.clear();
    this.removeBranding();
  }

  public destroy(options: DestroyOptions): void {
    this.graphics.destroy(options);
    super.destroy(options);
  }

  public animateIn(): void {
    this.size = 0;
    this.zHeight = 0;

    gsap.to(this, {
      duration: 0.2,
      size: this.blockSize.x,
      zHeight: this.blockSize.y,
      ease: 'back.out',
      onUpdate: () => {
        this.redraw();
      },
    });
  }

  public addBranding(
    brandId: string,
    topImage: string,
    leftImage?: string,
    rightImage?: string,
    topColor?: string,
    sideColor?: string,
    darkColor?: string,
    outlineColor?: string
  ): void {
    // Donuts need a special type of decal
    // TODO: implement donut decal (follow circular path)
    if (this.shape == BlockShape.DONUT) return;
    this.currentBrand = brandId;

    // set branding colors if available
    if (topColor || sideColor || darkColor || outlineColor) {
      this.topColor = topColor ?? this.topColor;
      this.sideColor = sideColor ?? this.sideColor;
      this.darkColor = darkColor ?? this.darkColor;
      this.outlineColor = outlineColor ?? this.outlineColor;
    }

    const promises = [];

    const texturePromise = Assets.load(topImage);
    promises.push(texturePromise);
    texturePromise
      .then((texture) => {
        this.topPlane = addPlane(texture, this);
      })
      .catch((error) => {
        console.log(error);
      });

    // Don't show side decals on circle blocks, it will look wierd
    if (this.shape != BlockShape.SQUARE) {
      leftImage = undefined;
      rightImage = undefined;
    }

    if (leftImage) {
      const texturePromise = Assets.load(leftImage);
      promises.push(texturePromise);
      texturePromise
        .then((texture) => {
          this.leftPlane = addPlane(texture, this);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (rightImage) {
      const texturePromise = Assets.load(rightImage);
      promises.push(texturePromise);
      texturePromise
        .then((texture) => {
          this.rightPlane = addPlane(texture, this);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    Promise.allSettled(promises).then(() => {
      this.updateBrandingPlanes();
      this.showBrandingPlanes(true);
    });

    function addPlane(texture: Texture, parent: Block): MeshPlane {
      const plane = new MeshPlane({
        texture,
        verticesX: 2,
        verticesY: 2,
      });
      parent.addChild(plane);
      plane.visible = false;
      return plane;
    }
  }

  public removeBranding(): void {
    this.currentBrand = 'none';
    this.topPlaneRotated = false;
    this.showBrandingPlanes(false);
    if (this.topPlane) {
      this.removeChild(this.topPlane);
      this.topPlane.destroy();
      this.topPlane = undefined;
    }
    if (this.leftPlane) {
      this.removeChild(this.leftPlane);
      this.leftPlane.destroy();
      this.leftPlane = undefined;
    }
    if (this.rightPlane) {
      this.removeChild(this.rightPlane);
      this.rightPlane.destroy();
      this.rightPlane = undefined;
    }
  }

  public chargeJump(maxHoldTime: number): void {
    gsap.to(this, {
      zHeight: this.blockSize.y * 0.5,
      duration: maxHoldTime * 0.001,
      ease: this.chargeEasing,
      onUpdate: () => {
        this.redraw();
      },
    });
  }

  public cancelJump(): void {
    gsap.to(this, {
      duration: 0.1,
      zHeight: this.blockSize.y,
      ease: 'back.out',
      overwrite: true,
      onUpdate: () => {
        this.redraw();
      },
    });
  }

  public update(_dt: number): void {}

  // blessed are ye whom
  public receivePlayer(): void {
    gsap.to(this, {
      zHeight: this.blockSize.y * 0.9,
      duration: 0.05,
      repeat: 1,
      yoyo: true,
      ease: 'sine.out',
      onUpdate: () => {
        this.redraw();
      },
    });
  }

  public sendOffPlayer(): void {
    gsap.killTweensOf(this);
    gsap.to(this, {
      zHeight: this.blockSize.y,
      duration: 0.25,
      ease: 'elastic.out',
      onUpdate: () => {
        this.redraw();
      },
    });
  }

  public redraw() {
    const graphics = this.graphics;
    const w = this.size;
    const h = this.zHeight;

    graphics.clear();

    switch (this.shape) {
      case BlockShape.SQUARE:
        this.drawSquare(graphics, w, h);
        break;
      case BlockShape.CIRCLE:
        this.drawCircle(graphics, w, h);
        break;
      case BlockShape.DONUT:
        this.drawDonut(graphics, w, h);
        break;
    }

    this.updateBrandingPlanes();
  }

  public drawCircle(graphics: Graphics, w: number, h: number): void {
    const gradientFill = new FillGradient(-w, 0, w * 2, 0);
    gradientFill.addColorStop(0, this.sideColor);
    gradientFill.addColorStop(1.0, this.darkColor);

    // body
    this.isoBlockCircle(graphics, 0, 0, 0, h, w);
    graphics.fill(gradientFill);

    // top
    this.isoCircle(graphics, 0, 0, -h, w);
    graphics.fill(this.topColor);

    // outline
    this.isoBlockCircle(graphics, 0, 0, 0, h, w);
    graphics.stroke({ width: 3, color: this.outlineColor });
  }

  public drawDonut(graphics: Graphics, w: number, h: number): void {
    const z = -50;
    const gradientFill = new FillGradient(-w, 0, w * 2, 0);
    gradientFill.addColorStop(0.0, this.sideColor);
    gradientFill.addColorStop(1.0, this.darkColor);

    // body
    this.isoBlockCircle(graphics, 0, 0, z, h + z, w);
    graphics.fill(gradientFill);

    this.isoCircle(graphics, 0, 0, z, w * 0.5, 0.5);
    graphics.cut();

    // top
    this.isoCircle(graphics, 0, 0, -h, w);
    graphics.fill(this.topColor);

    // hole
    this.isoCircle(graphics, 0, 0, -h, w * 0.5);
    graphics.cut();

    // hole outline
    this.isoCircle(graphics, 0, 0, -h, w * 0.5);
    graphics.stroke({ width: 3, color: this.outlineColor });

    // outline
    this.isoBlockCircle(graphics, 0, 0, z, h + z, w);
    graphics.stroke({ width: 3, color: this.outlineColor });
  }

  public drawSquare(graphics: Graphics, w: number, h: number): void {
    // top
    graphics.moveTo(toIsoX(-w, -w, -h), toIsoY(-w, -w, -h));
    graphics.lineTo(toIsoX(w, -w, -h), toIsoY(w, -w, -h));
    graphics.lineTo(toIsoX(w, w, -h), toIsoY(w, w, -h));
    graphics.lineTo(toIsoX(-w, w, -h), toIsoY(-w, w, -h));
    graphics.fill(this.topColor);

    // left
    graphics.moveTo(toIsoX(-w, w, -h), toIsoY(-w, w, -h));
    graphics.lineTo(toIsoX(w, w, -h), toIsoY(w, w, -h));
    graphics.lineTo(toIsoX(w, w, 0), toIsoY(w, w, 0));
    graphics.lineTo(toIsoX(-w, w, 0), toIsoY(-w, w, 0));
    graphics.fill(this.sideColor);

    // right
    graphics.moveTo(toIsoX(w, w, -h), toIsoY(w, w, -h));
    graphics.lineTo(toIsoX(w, -w, -h), toIsoY(w, -w, -h));
    graphics.lineTo(toIsoX(w, -w, 0), toIsoY(w, -w, 0));
    graphics.lineTo(toIsoX(w, w, 0), toIsoY(w, w, 0));
    graphics.fill(this.darkColor);

    // outline
    graphics.moveTo(toIsoX(-w, -w, -h), toIsoY(-w, -w, -h));
    graphics.lineTo(toIsoX(-w, w, -h), toIsoY(-w, w, -h));
    graphics.lineTo(toIsoX(-w, w, 0), toIsoY(-w, w, 0));
    graphics.lineTo(toIsoX(w, w, 0), toIsoY(w, w, 0));
    graphics.lineTo(toIsoX(w, -w, 0), toIsoY(w, -w, 0));
    graphics.lineTo(toIsoX(w, -w, -h), toIsoY(w, -w, -h));
    graphics.lineTo(toIsoX(-w, -w, -h), toIsoY(-w, -w, -h));
    graphics.stroke({ width: 3, color: this.outlineColor });
  }

  public updateBrandingPlanes(): void {
    const w = this.size;
    const h = this.zHeight;

    if (this.topPlane) {
      const { buffer } = this.topPlane.geometry.getAttribute('aPosition');

      // vertex array
      const v = this.topPlaneRotated ? [2, 3, 6, 7, 0, 1, 4, 5] : [0, 1, 2, 3, 4, 5, 6, 7];

      // top left vertex
      buffer.data[v[0]] = toIsoX(-w, -w, -h);
      buffer.data[v[1]] = toIsoY(-w, -w, -h);
      // top right
      buffer.data[v[2]] = toIsoX(w, -w, -h);
      buffer.data[v[3]] = toIsoY(w, -w, -h);
      // bottom left
      buffer.data[v[4]] = toIsoX(-w, w, -h);
      buffer.data[v[5]] = toIsoY(-w, w, -h);
      //bottom right
      buffer.data[v[6]] = toIsoX(w, w, -h);
      buffer.data[v[7]] = toIsoY(w, w, -h);
    }

    if (this.leftPlane) {
      const { buffer } = this.leftPlane.geometry.getAttribute('aPosition');

      // top left vertex
      buffer.data[0] = toIsoX(-w, w, -h);
      buffer.data[1] = toIsoY(-w, w, -h);
      // top right
      buffer.data[2] = toIsoX(w, w, -h);
      buffer.data[3] = toIsoY(w, w, -h);
      // bottom left
      buffer.data[4] = toIsoX(-w, w, 0);
      buffer.data[5] = toIsoY(-w, w, 0);
      //bottom right
      buffer.data[6] = toIsoX(w, w, 0);
      buffer.data[7] = toIsoY(w, w, 0);
    }

    if (this.rightPlane) {
      const { buffer } = this.rightPlane.geometry.getAttribute('aPosition');

      // top left vertex
      buffer.data[0] = toIsoX(w, w, -h);
      buffer.data[1] = toIsoY(w, w, -h);
      // top right
      buffer.data[2] = toIsoX(w, -w, -h);
      buffer.data[3] = toIsoY(w, -w, -h);
      // bottom left
      buffer.data[4] = toIsoX(w, w, 0);
      buffer.data[5] = toIsoY(w, w, 0);
      //bottom right
      buffer.data[6] = toIsoX(w, -w, 0);
      buffer.data[7] = toIsoY(w, -w, 0);
    }
  }

  public showBrandingPlanes(visible: boolean = true): void {
    if (this.topPlane) this.topPlane.visible = visible;
    if (this.leftPlane) this.leftPlane.visible = visible;
    if (this.rightPlane) this.rightPlane.visible = visible;
  }

  public checkHit(isoX: number, isoY: number): boolean {
    const dist = distance(isoX, isoY, this.isoX, this.isoY);
    switch (this.shape) {
      case BlockShape.CIRCLE:
        return dist <= this.size;
      case BlockShape.DONUT:
        return dist > this.size * 0.4 && dist <= this.size;
      default:
        return (
          isoX > this.isoX - this.size &&
          isoX < this.isoX + this.size &&
          isoY > this.isoY - this.size &&
          isoY < this.isoY + this.size
        );
    }
  }

  public checkDonutHole(player: Player): boolean {
    return this.shape == BlockShape.DONUT && this.getPlayerDist(player) < this.size;
  }

  public getPlayerDist(player: Player): number {
    return distance(player.isoX, player.isoY, this.isoX, this.isoY);
  }

  private isoCircle(graphics: Graphics, x: number, y: number, z: number, r: number, ratio: number = 1.0) {
    const sides = 32;
    const step = (Math.PI * 2) / sides;
    const startAngle = Math.PI * 0.75;
    const l = Math.floor(sides * ratio) + 1;
    for (let i = 0; i < l; i++) {
      const angle = startAngle + step * i;
      const dx = Math.cos(angle) * r;
      const dy = Math.sin(angle) * r;
      if (i == 0) graphics.moveTo(toIsoX(x + dx, y + dy, z), toIsoY(x + dx, y + dy, z));
      else graphics.lineTo(toIsoX(x + dx, y + dy, z), toIsoY(x + dx, y + dy, z));
    }
  }

  private isoBlockCircle(graphics: Graphics, x: number, y: number, z: number, h: number, r: number) {
    const sides = 32;
    const step = (Math.PI * 2) / sides;
    const startAngle = -Math.PI * 0.25;
    for (let i = 0; i < sides + 1; i++) {
      const angle = startAngle + step * i;
      const dx = Math.cos(angle) * r;
      const dy = Math.sin(angle) * r;
      if (i == 0) {
        graphics.moveTo(toIsoX(x + dx, y + dy, z - h), toIsoY(x + dx, y + dy, z - h));
        graphics.lineTo(toIsoX(x + dx, y + dy, z), toIsoY(x + dx, y + dy, z));
      } else if (i == Math.floor(sides * 0.5)) {
        graphics.lineTo(toIsoX(x + dx, y + dy, z), toIsoY(x + dx, y + dy, z));
        graphics.lineTo(toIsoX(x + dx, y + dy, z - h), toIsoY(x + dx, y + dy, z - h));
      } else if (i < Math.floor(sides * 0.5)) {
        graphics.lineTo(toIsoX(x + dx, y + dy, z), toIsoY(x + dx, y + dy, z));
      } else {
        graphics.lineTo(toIsoX(x + dx, y + dy, z - h), toIsoY(x + dx, y + dy, z - h));
      }
    }
  }
}
