import { assertNever } from '../utils';
import { BlockModel } from './block-model';
import { JUMP_RATING } from './jump-judger';

export class ScoreManager {
  public score: number = 0;
  public combo: number = 0;
  public timeScore: number = 0;
  static readonly minCombo: number = 0;
  static readonly maxCombo: number = 25;
  static readonly dailyChallengeBlocks: number = 50;

  public constructor() {
    this.score = 0;
    this.combo = 0;
  }

  public updateDailyChallengeTimeScore(newTimeScore: number): number {
    this.timeScore = newTimeScore;
    return this.timeScore;
  }

  public increaseScoreFromJump(
    jumpRating: JUMP_RATING,
    blockModel: BlockModel
  ): {
    points: number;
    newTotalScore: number;
    currentCombo: number;
  } {
    const { combo: newCombo, points } = this.getPointsAndComboFromJump(blockModel.scoreValue, this.combo, jumpRating);

    this.combo = newCombo;
    this.score += points;

    return {
      points,
      newTotalScore: this.score,
      currentCombo: this.combo,
    };
  }

  public clearCombo(): number {
    this.combo = 0;
    return this.combo;
  }

  private getPointsAndComboFromJump(
    scoreValue: number,
    previousCombo: number,
    jump: JUMP_RATING
  ): { combo: number; points: number } {
    switch (jump) {
      case JUMP_RATING.PURRFECT:
        return {
          combo: clamp(previousCombo + 1),
          points: (previousCombo + 1) * 8 * scoreValue,
        };
      case JUMP_RATING.GREAT:
        return {
          combo: clamp(previousCombo + 1),
          points: (previousCombo + 1) * 6 * scoreValue,
        };
      case JUMP_RATING.GOOD:
        return {
          combo: clamp(previousCombo - 1),
          points: (previousCombo + 1) * 4 * scoreValue,
        };
      case JUMP_RATING.OK:
        return {
          combo: clamp(previousCombo - 2),
          points: (previousCombo + 1) * 2 * scoreValue,
        };
      case JUMP_RATING.ADEQUATE:
        return {
          combo: clamp(previousCombo - 3),
          points: (previousCombo + 1) * 1 * scoreValue,
        };
      default:
        return assertNever(jump) as unknown as {
          combo: number;
          points: number;
        };
    }

    function clamp(val: number): number {
      const minCombo = ScoreManager.minCombo;
      const maxCombo = ScoreManager.maxCombo;
      return val > minCombo ? (val < maxCombo ? val : maxCombo) : 0;
    }
  }
}
