import { getAuth } from '@/api/player';
import { Loader } from './loader';

export type AmplifyJwt = string;

export class AmplifyJwtLoader implements Loader<AmplifyJwt> {
  constructor() {}
  public async load(): Promise<AmplifyJwt> {
    try {
      const auth = await getAuth();
      if (auth) {
        return auth.jwt;
      } else {
        throw 'error fetching jwt';
      }
    } catch (_e) {
      throw 'error fetching jwt';
    }
  }
}
