import ArrowLeft from '../Icons/ArrowLeft';
import { Button } from '../Button/Button';

import styles from './BackButton.module.scss';

type BackButtonPops = {
  onClick: () => void;
};
export const BackButton = ({ onClick }: BackButtonPops) => {
  return (
    <Button className={styles.backButton} onClick={onClick}>
      <ArrowLeft color="#FFFFFF" />
    </Button>
  );
};
