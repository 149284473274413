import { z } from 'zod';

export const LeaderboardRecordDto = z.object({
  leaderboard_id: z.string(),
  username: z.string(),
  rank: z.number(),
  score: z.number(),
  num_score: z.number(),
  owner_id: z.string(),
  metadata: z.object({
    tgPartnerGroupId: z.number().optional(),
  }),
});

export const LeaderboardRecordListDto = z.array(LeaderboardRecordDto);
