import React from 'react';

type Props = {
  fill?: string;
};

export const ProfileIcon: React.FC<Props> = React.memo(({ fill = '#202025' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
      <g fill={fill}>
        <circle cx={12.5} cy={7.709} r={5.151} />
        <path d="M3.983 19.653a5.376 5.376 0 0 1 5.376-5.376h6.281a5.376 5.376 0 0 1 5.376 5.376v.997c0 .99-.802 1.792-1.791 1.792H5.774c-.99 0-1.792-.802-1.792-1.792v-.997Z" />
      </g>
    </svg>
  );
});
