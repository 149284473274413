import * as PIXI from 'pixi.js';
import { gameStore, GameplayState } from '../store/gameStore';
import { Sound, sound } from '@pixi/sound';

import { Analytics } from '../utils/analytics';
import { GameMode } from './engine/game-run';
import { initGame } from './GimmeCatGame';
import { CatCostume } from '@/types/CatCostume';
import { DevTools } from '@/utils/dev-tools';

// disable telegram's "swipe down to close" behavior
window.Telegram.WebApp.disableVerticalSwipes();

// disable context menu (for accessing dev tools) with a toggle
DevTools.disableContextMenu();

// detect visibility change / phone sleep
document.onvisibilitychange = () => {
  if (document.visibilityState == 'visible') {
    resumeGame();
  } else {
    pauseGame();
  }
};

// disable long-press vibration
// document.body.ontouchstart = e => {
//   e.preventDefault();
//   e.stopPropagation();
// };
// document.body.ontouchend = e => {
//   e.preventDefault();
//   e.stopPropagation();
// };

let initializedGamePromise: ReturnType<typeof initGame> | null = null;

export function initializeGame() {
  if (initializedGamePromise === null) {
    initializedGamePromise = initGame(new PIXI.Application());
  }
}

function getGame(): ReturnType<typeof initGame> {
  if (initializedGamePromise === null) {
    throw 'Error: tried to use game before initGame was called.';
  } else {
    return initializedGamePromise;
  }
}

let handleGameOverFunction: () => void;
let handleHomeButtonFunction: () => void;
let handleUpdateAchievementFunction: (achievement: string) => void;

export function setHandlers(
  handleGameOver: () => void,
  handleHomeButton: () => void,
  handleUpdateAchievement: (achievement: string) => void
) {
  handleGameOverFunction = handleGameOver;
  handleHomeButtonFunction = handleHomeButton;
  handleUpdateAchievementFunction = handleUpdateAchievement;
}

export async function initializeNewGameRun() {
  (await getGame()).initializeNewGameRun();
}

export async function startGame() {
  if (gameStore.getState().gameplayState != GameplayState.ReadyToPlay) return;

  gameStore.getState().bumpGameSessionRunCount();

  gameStore.getState().setGameplayState(GameplayState.Playing);
  (await getGame()).addListeners();

  Analytics.recordPageView('game');
  await Analytics.recordGameEvent('game_run_start', gameStore);
}

export async function startDailyChallenge() {
  if (gameStore.getState().gameplayState != GameplayState.ReadyToPlay) return;

  (await getGame()).initializeNewGameRun(GameMode.DailyChallenge);
  gameStore.getState().setGameplayState(GameplayState.Playing);

  // FIXME: use proper analytics for daily challange mode
  Analytics.recordPageView('daily-challenge-game');
  await Analytics.recordGameEvent('game_run_start', gameStore);
}

export async function getPixiCanvas() {
  return (await getGame()).getCanvas();
}

export async function updateHighScoreUI(val: number) {
  (await getGame()).updateHighScoreUI(val);
}

export async function setEquippedCostume(equippedCostume: CatCostume) {
  (await getGame()).setPlayerCostume(equippedCostume);
  applySFXOverride(equippedCostume);
}

async function applySFXOverride(equippedCostume: CatCostume) {
  // load fail sound
  const failSfx = `${equippedCostume}_fail`;
  // check if override already exists
  if (!sound.exists(failSfx)) {
    try {
      const url = `/sfx/override/${equippedCostume}/fail.mp3`;
      const sfx: Sound = await PIXI.Assets.load(url);
      sound.add(failSfx, sfx);
    } catch {
      // no sfx override found
    }
  }

  // load cancel sound
  const cancelSfx = `${equippedCostume}_cancel`;
  // check if override already exists
  if (!sound.exists(cancelSfx)) {
    try {
      const url = `/sfx/override/${equippedCostume}/cancel.mp3`;
      const sfx: Sound = await PIXI.Assets.load(url);
      sound.add(cancelSfx, sfx);
    } catch {
      // no sfx override found
    }
  }
}

export async function loadPlayerCostume(url: string) {
  // only pass the url to the folder containing the skin
  // e.g. '/images/cat-skins/pajamas/'
  // the folder should have 3 pngs: idle.png, crouch.png, jump.png
  (await getGame()).loadPlayerCostume(url);
}

export async function setBgmVolume(val: number) {
  (await getGame()).setBgmVolume(val);
}

export async function setAllSoundVolume(val: number) {
  sound.volumeAll = val;
}

export async function initializeSound({ musicVolume, soundVolume }: { musicVolume: number; soundVolume: number }) {
  (await getGame()).setBgmVolume(musicVolume);
  sound.volumeAll = soundVolume;
  (await getGame()).startBgm();
}

export async function getGameplayState() {
  return gameStore.getState().gameplayState;
}

export async function initAfterBrandingData() {
  if (gameStore.getState().gameplayState === GameplayState.PreInitializing) {
    console.log('READY TO INIT!!!');
    gameStore.getState().setGameplayState(GameplayState.Initializing);
    await initializeNewGameRun();
  }
}

async function resumeGame() {
  (await getGame()).playBgm(true);

  if (gameStore.getState().gameplayState == GameplayState.Playing) {
    (await getGame()).addListeners();
    (await getGame()).resumeUpdateLoop();
  }
}

async function pauseGame() {
  (await getGame()).playBgm(false);

  if (gameStore.getState().gameplayState == GameplayState.Playing) {
    (await getGame()).removeListeners();
    (await getGame()).stopUpdateLoop();
  }
}

export async function homeButtonClicked() {
  sound.play('tab_switch_1');
  (await getGame()).removeListeners();
  initializeNewGameRun();
  if (handleHomeButtonFunction) handleHomeButtonFunction();
}

export async function handleGameOver() {
  if (handleGameOverFunction) handleGameOverFunction();
}

export function updateAchievement(achievement: string): void {
  if (handleUpdateAchievementFunction) handleUpdateAchievementFunction(achievement);
}

export async function loadPixiAssets(callback: (progress: number) => void) {
  await PIXI.Assets.init({ manifest: '/assetManifest.json' });
  await PIXI.Assets.loadBundle(['game-bitmapfonts', 'game-textures', 'game-sfx', 'game-bgm'], callback);
}
