export const ChevronIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none">
      <path
        fill="#F7F0E5"
        fillRule="evenodd"
        d="m3.785 11.375-.831-.836L7.26 6.251 2.973 1.944l.835-.832 5.12 5.143-5.143 5.12Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
