import { Container, Point } from 'pixi.js';
import { gsap } from 'gsap';

export class PlayerSkin extends Container {
  chargeScaleVal: Point = new Point(1.25, 0.5);

  constructor() {
    super();
  }

  public loadSkin(_url: string): void {}

  public reset(): void {}

  public update(_dt: number): void {}

  public animateCharge(chargeDuration: number): void {
    gsap.killTweensOf(this);
    gsap.to(this, {
      pixi: { scaleX: this.chargeScaleVal.x, scaleY: this.chargeScaleVal.y },
      duration: chargeDuration,
      ease: 'sine.out',
    });
  }

  public animateJump(_jumpDuration: number): void {
    gsap.killTweensOf(this);
    this.scale.x = this.scale.y = 1;
  }

  public animateCancel(): void {
    gsap.to(this, {
      duration: 0.1,
      pixi: { scaleY: 1, scaleX: 1 },
      ease: 'back.out',
      overwrite: true,
    });
  }

  public animateLand(): void {
    gsap.to(this, {
      y: 12,
      pixi: { scaleY: 0.8, scaleX: 1.2 },
      duration: 0.05,
      ease: 'sine.out',
    });
    gsap.to(this, {
      y: 0,
      pixi: { scaleY: 1, scaleX: 1 },
      duration: 0.075,
      delay: 0.05,
      ease: 'sine.inOut',
    });
  }
}
