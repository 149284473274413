import React, { useCallback, useRef, useState, useEffect } from 'react';
import styles from './Slider.module.scss';
import classnames from 'classnames';

type Props = {
  value: number;
  onChange: (value: number) => void;
  className?: string;
  min?: number;
  max?: number;
  debounceTime?: number;
};

const Slider = ({ value, onChange, className, min = 0, max = 100, debounceTime = 300 }: Props) => {
  const [localValue, setLocalValue] = useState(value);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const percentage = ((localValue - min) / (max - min)) * 100;

  const debouncedOnChange = useCallback(
    (newValue: number) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        onChange(newValue);
      }, debounceTime);
    },
    [onChange, debounceTime]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    setLocalValue(newValue);
    debouncedOnChange(newValue);
  };

  return (
    <input
      type="range"
      min={min}
      max={max}
      value={localValue}
      onChange={handleChange}
      className={classnames(styles.slider, className)}
      style={{
        background: `linear-gradient(to right, #796A5E 0%, #796A5E ${percentage}%, #EEDEC4 ${percentage}%, #EEDEC4 100%)`,
      }}
    />
  );
};

export default Slider;
