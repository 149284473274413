export interface Loader<T> {
  load(): Promise<T>;
}

export class OneTimeLoader<T> implements Loader<T> {
  private loadPromise: Promise<T> | null = null;
  private loader: Loader<T>;

  constructor(loader: Loader<T>) {
    this.loader = loader;
  }

  public load(): Promise<T> {
    if (!this.loadPromise) {
      this.loadPromise = this.loader.load();
    }
    return this.loadPromise;
  }
}

export function JustOnce<T>(loader: Loader<T>) {
  return new OneTimeLoader<T>(loader);
}
