import { createPortal } from 'react-dom';
import { Button } from '../Button/Button';
import { CloseIcon } from '../Icons/CloseIcon';
import { Text } from '@/components/Text/Text';
import styles from './BottomSheet.module.scss';

export type BottomSheetProps = {
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  action?: React.ReactNode;
};

export const BottomSheet = ({ title, onClose, children, action }: BottomSheetProps) => {
  return createPortal(
    <div className={styles.bottomSheetContainer}>
      <div className={styles.bottomSheet}>
        <div className={styles.header}>
          <Text className={styles.title} as={'h3'} type={'heading'} size="xl">
            {title}
          </Text>
          <Button onClick={onClose} className={styles.close}>
            <CloseIcon fill="#FFFFFF" />
          </Button>
        </div>
        {children}
        {action && <div className={styles.actionContainer}>{action}</div>}
      </div>
    </div>,
    document.body
  );
};
