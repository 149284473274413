import { useId } from 'react';

export const RefreshIcon = () => {
  const id = useId();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <mask id={id} width={22} height={17} x={1} y={3.301} fill="#000" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M1 3.301h22v17H1z" />
        <path d="M8.946 11.754a.5.5 0 0 0-.353-.854H6.62a.009.009 0 0 1-.009-.01 5.452 5.452 0 0 1 .827-1.965 5.56 5.56 0 0 1 2.42-1.992c.333-.141.68-.249 1.032-.32a5.586 5.586 0 0 1 2.22 0 5.476 5.476 0 0 1 2.409 1.16c.212.176.527.179.723-.016l.849-.847a.476.476 0 0 0-.011-.696A7.738 7.738 0 0 0 15 4.905a7.615 7.615 0 0 0-1.447-.449 7.787 7.787 0 0 0-3.1 0 7.634 7.634 0 0 0-1.448.45 7.744 7.744 0 0 0-3.388 2.788 7.676 7.676 0 0 0-1.164 2.779.513.513 0 0 1-.497.427H2.207a.5.5 0 0 0-.353.854l3.192 3.193a.5.5 0 0 0 .708 0l3.192-3.193Zm6.108.493a.5.5 0 0 0 .353.853h1.973c.005 0 .008.004.008.01a5.474 5.474 0 0 1-2.314 3.45 5.44 5.44 0 0 1-1.965.828 5.58 5.58 0 0 1-2.219 0 5.454 5.454 0 0 1-2.41-1.162.537.537 0 0 0-.723.017l-.848.849a.476.476 0 0 0 .01.695c.62.544 1.323.986 2.083 1.308.467.198.954.349 1.447.45a7.777 7.777 0 0 0 3.1 0 7.735 7.735 0 0 0 4.834-3.24 7.673 7.673 0 0 0 1.163-2.777.512.512 0 0 1 .497-.428h1.75a.5.5 0 0 0 .353-.853l-3.192-3.193a.5.5 0 0 0-.708 0l-3.192 3.193Z" />
      </mask>
      <path
        fill="#FFFBF4"
        d="M8.946 11.754a.5.5 0 0 0-.353-.854H6.62a.009.009 0 0 1-.009-.01 5.452 5.452 0 0 1 .827-1.965 5.56 5.56 0 0 1 2.42-1.992c.333-.141.68-.249 1.032-.32a5.586 5.586 0 0 1 2.22 0 5.476 5.476 0 0 1 2.409 1.16c.212.176.527.179.723-.016l.849-.847a.476.476 0 0 0-.011-.696A7.738 7.738 0 0 0 15 4.905a7.615 7.615 0 0 0-1.447-.449 7.787 7.787 0 0 0-3.1 0 7.634 7.634 0 0 0-1.448.45 7.744 7.744 0 0 0-3.388 2.788 7.676 7.676 0 0 0-1.164 2.779.513.513 0 0 1-.497.427H2.207a.5.5 0 0 0-.353.854l3.192 3.193a.5.5 0 0 0 .708 0l3.192-3.193Zm6.108.493a.5.5 0 0 0 .353.853h1.973c.005 0 .008.004.008.01a5.474 5.474 0 0 1-2.314 3.45 5.44 5.44 0 0 1-1.965.828 5.58 5.58 0 0 1-2.219 0 5.454 5.454 0 0 1-2.41-1.162.537.537 0 0 0-.723.017l-.848.849a.476.476 0 0 0 .01.695c.62.544 1.323.986 2.083 1.308.467.198.954.349 1.447.45a7.777 7.777 0 0 0 3.1 0 7.735 7.735 0 0 0 4.834-3.24 7.673 7.673 0 0 0 1.163-2.777.512.512 0 0 1 .497-.428h1.75a.5.5 0 0 0 .353-.853l-3.192-3.193a.5.5 0 0 0-.708 0l-3.192 3.193Z"
      />
      <path
        stroke="#FFFBF4"
        strokeWidth={1.1}
        d="M8.946 11.754a.5.5 0 0 0-.353-.854H6.62a.009.009 0 0 1-.009-.01 5.452 5.452 0 0 1 .827-1.965 5.56 5.56 0 0 1 2.42-1.992c.333-.141.68-.249 1.032-.32a5.586 5.586 0 0 1 2.22 0 5.476 5.476 0 0 1 2.409 1.16c.212.176.527.179.723-.016l.849-.847a.476.476 0 0 0-.011-.696A7.738 7.738 0 0 0 15 4.905a7.615 7.615 0 0 0-1.447-.449 7.787 7.787 0 0 0-3.1 0 7.634 7.634 0 0 0-1.448.45 7.744 7.744 0 0 0-3.388 2.788 7.676 7.676 0 0 0-1.164 2.779.513.513 0 0 1-.497.427H2.207a.5.5 0 0 0-.353.854l3.192 3.193a.5.5 0 0 0 .708 0l3.192-3.193Zm6.108.493a.5.5 0 0 0 .353.853h1.973c.005 0 .008.004.008.01a5.474 5.474 0 0 1-2.314 3.45 5.44 5.44 0 0 1-1.965.828 5.58 5.58 0 0 1-2.219 0 5.454 5.454 0 0 1-2.41-1.162.537.537 0 0 0-.723.017l-.848.849a.476.476 0 0 0 .01.695c.62.544 1.323.986 2.083 1.308.467.198.954.349 1.447.45a7.777 7.777 0 0 0 3.1 0 7.735 7.735 0 0 0 4.834-3.24 7.673 7.673 0 0 0 1.163-2.777.512.512 0 0 1 .497-.428h1.75a.5.5 0 0 0 .353-.853l-3.192-3.193a.5.5 0 0 0-.708 0l-3.192 3.193Z"
        mask={`url(#${id})`}
      />
    </svg>
  );
};
