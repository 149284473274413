import { queryKeys } from '@/api/queryKeys';
import { isUserMetadata } from '@/api/types/users';
import { sdk } from '@/gimmecat';
import { QueryConfig } from '@/lib/react-query';
import { getRankFrom, getScoreFrom, LeaderboardType } from '@/types/leaderboard';
import { useQuery } from 'react-query';

async function getPlayerData() {
  if (!sdk.nakamaClients) return;
  const { nakamaClient: client, nakamaSession: session } = sdk.nakamaClients;

  const username = session.username;

  const highScoreRes = await client.listLeaderboardRecords(session, LeaderboardType.ALL, [session.user_id!]);
  const highScore = getScoreFrom(highScoreRes?.owner_records?.[0]);
  const leaderboardPosition = getRankFrom(highScoreRes?.owner_records?.[0]);

  const account = await client.getAccount(session);
  const wallet = JSON.parse(account.wallet ?? '');
  const currencyAmount = Number(wallet.points ?? 0);
  const metadata = JSON.parse(account.user?.metadata ?? '');

  return {
    highScore,
    leaderboardPosition,
    currencyAmount,
    username,
    userId: account.user?.id,
    communityId: isUserMetadata(metadata) ? metadata.communityId : -1,
  };
}

export function getPlayerDataQueryOptions() {
  return {
    queryKey: queryKeys.getPlayerData(),
    queryFn: getPlayerData,
  };
}

type UsePlayerDataOptions = {
  queryConfig?: QueryConfig<typeof getPlayerDataQueryOptions>;
};

export function usePlayerData({ queryConfig }: UsePlayerDataOptions = {}) {
  return useQuery({ ...getPlayerDataQueryOptions(), ...queryConfig });
}
