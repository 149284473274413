import { z } from 'zod';

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);

type Literal = z.infer<typeof literalSchema>;

type Json = Literal | { [key: string]: Json } | Json[];

const jsonSchema: z.ZodType<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]));

export const json = () => jsonSchema;

export const stringToJSONSchema = z.string().transform((str): z.infer<ReturnType<typeof json>> => {
  return JSON.parse(str);
});

export const jsonArray = () => z.array(jsonSchema);

const mightBeArray = (str: string): boolean => {
  const trimmed = str.trim();
  return trimmed.startsWith('[') && trimmed.endsWith(']');
};

export const stringToJSONArraySchema = z
  .string()
  .refine(mightBeArray, {
    message: "String must represent a JSON array (start with '[' and end with ']')",
  })
  .transform((str, ctx): z.infer<ReturnType<typeof jsonArray>> => {
    try {
      const parsed = JSON.parse(str);
      if (!Array.isArray(parsed)) {
        ctx.addIssue({
          code: 'custom',
          message: 'Parsed JSON is not an array',
        });
        return z.NEVER;
      }
      return parsed;
    } catch {
      ctx.addIssue({
        code: 'custom',
        message: 'Invalid JSON array format',
      });
      return z.NEVER;
    }
  });
