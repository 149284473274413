import { Button } from '../Button/Button';
import cls from 'classnames';
import styles from './Tabs.module.scss';

export type Tab = {
  id: string;
  name: string;
  controls: string;
};

export type TabsProps = {
  tabs: Tab[];
  selectedTab: string;
  setSelectedTab: (type: string) => void;
};

export function Tabs({ tabs, selectedTab, setSelectedTab }: TabsProps) {
  return (
    <ul className={styles.tabsNavStyle}>
      {tabs.map((tab: Tab, i: number) => (
        <div key={'button-container-' + tab.name} className={styles.tab}>
          <Button
            key={tab.name}
            onClick={async () => {
              setSelectedTab(tab.id);
            }}
            disabled={tab.controls === 'disabled'}
            className={cls(styles.linkComponent, {
              [styles.isSelected]: selectedTab === tab.id,
              [styles.disabled]: tab.controls === 'disabled',
            })}
          >
            {tab.name}
          </Button>
          {i < tabs.length - 1 && <div className={styles.separator} />}
        </div>
      ))}
    </ul>
  );
}
