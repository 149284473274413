import { Container, Point } from 'pixi.js';
import { gsap } from 'gsap';
import { isoContainer } from '../isoContainer';
import { Block } from '../blocks/block';
import { ShakingBlock } from '../blocks/shakingBlock';
import { MovingBlock } from '../blocks/movingBlock';
import { sound } from '@pixi/sound';
import { distance, toIsoX, toIsoY } from '../utils';
import { PlayerShadow } from './playershadow';
import { PlayerSkin } from './playerSkin';
import { playerSkinSprite } from './playerSkinSprite';
import { costumes } from '../../utils/constants';
import { Particles } from '../particles/particles';
import { ParticleType } from '../particles/particle';
import { gameStore } from '../../store/gameStore';
import { ScoreManager } from '../engine/score-manager';
import { CatCostume } from '@/types/CatCostume';

export class Player extends isoContainer {
  shadow: PlayerShadow;
  skin: PlayerSkin;
  skinContainer: Container;
  isJumping: boolean = false;
  isFalling: boolean = false;
  isOnFallingBlock: boolean = false;
  catScale: number = 0.9;
  prevZ: number = 0;
  deltaZ: number = 0;
  starTimer: number = 0;
  localPosition: Point;
  lastPosition: Point;
  jumpDelta: Point;
  currentBlock?: Block;
  prevParent?: Container;
  currentCostume: CatCostume = CatCostume.Default;

  public constructor(x: number, y: number, z: number) {
    super(x, y, z);
    this.shadow = new PlayerShadow(this);
    this.addChild(this.shadow);

    this.localPosition = new Point(0, 0);
    this.lastPosition = new Point(0, 0);
    this.jumpDelta = new Point(0, 0);

    this.skinContainer = new Container();
    this.addChild(this.skinContainer);

    //this.skin = new PlayerSkinSimple(0x241e25);
    this.skin = new playerSkinSprite();
    this.skinContainer.addChild(this.skin);
  }

  public loadSkin(url: string): void {
    this.skin.loadSkin(url);
  }

  public selectRandomSkin() {
    const randomSkin = costumes[Math.floor(Math.random() * costumes.length)];
    this.currentCostume = randomSkin;
    this.loadSkin(`images/cat-skins/${randomSkin}/`);
  }

  public selectEquippedCostume(equippedCostume: CatCostume) {
    this.currentCostume = equippedCostume;

    // this shouldn't happen but just in case
    if (!costumes.includes(equippedCostume)) {
      console.error('Invalid skin equipped', equippedCostume);
      this.currentCostume = CatCostume.Default;
    }
    this.loadSkin(`images/cat-skins/${this.currentCostume}/`);
  }

  public update(dt: number, blocks?: Block[], particles?: Particles): void {
    const startSparklingAtCombo = 10;
    if (particles && gameStore.getState().scoreManager.combo > startSparklingAtCombo) {
      this.starTimer -= dt;

      if (this.starTimer <= 0) {
        const idleRate = this.isJumping ? 0 : 0.24;
        const emissionRate =
          1.0 -
          (gameStore.getState().scoreManager.combo - startSparklingAtCombo) /
            (ScoreManager.maxCombo - startSparklingAtCombo);
        this.starTimer = 0.016 + (emissionRate * 0.08 + (idleRate + emissionRate * idleRate));
        const pos = particles.parent.toLocal(new Point(this.x, this.y), this.parent);
        particles.addParticles(pos.x, pos.y, ParticleType.Star, 1);
      }
    }

    this.prevZ = this.isoZ;
    if (this.isFalling) {
      this.deltaZ += 8000 * dt;
      this.isoX += this.jumpDelta.x * dt;
      this.isoY += this.jumpDelta.y * dt;
      this.isoZ += this.deltaZ * dt;
      this.updatePosition();
      return;
    }

    if (blocks) this.shadow.update(blocks);

    if (this.isJumping) return;
    const block = this.currentBlock;

    if (block instanceof MovingBlock) {
      this.isoX = block.isoX + this.localPosition.x;
      this.isoY = block.isoY + this.localPosition.y;
      this.updatePosition();
    } else if (block instanceof ShakingBlock) {
      this.isoX = this.localPosition.x;
      this.isoY = this.localPosition.y;
      this.updatePosition();
      if (block.isFalling) {
        this.isOnFallingBlock = true;
      }
    }

    this.skin.update(dt);
  }

  public updatePosition(): void {
    const zOffset = this.currentBlock ? this.currentBlock.isoZ : 0;
    this.x = toIsoX(this.isoX, this.isoY, this.isoZ - zOffset);
    this.y = toIsoY(this.isoX, this.isoY, this.isoZ - zOffset);
  }

  public chargeJump(maxHoldTime: number): void {
    // Use the easing curve of the current block
    const chargeEasing = this.currentBlock ? this.currentBlock.chargeEasing : 'power1.out';

    gsap.killTweensOf(this);
    gsap.to(this, {
      isoZ: -50,
      duration: maxHoldTime * 0.001,
      ease: chargeEasing,
      onUpdate: () => {
        this.updatePosition();
      },
    });
    this.currentBlock?.chargeJump(maxHoldTime);
    sound.play('charge_jump');

    this.skin.animateCharge(maxHoldTime * 0.001);
  }

  public cancelJump(): void {
    gsap.to(this, {
      duration: 0.1,
      isoZ: -100,
      ease: 'back.out',
      overwrite: true,
      onUpdate: () => {
        this.updatePosition();
      },
    });
    this.currentBlock?.cancelJump();
    this.skin.animateCancel();
    this.playCancelSound();
  }

  public placeOnBlock(block: Block): void {
    this.currentBlock = block;
    this.localPosition.x = this.isoX - block.isoX;
    this.localPosition.y = this.isoY - block.isoY;
    this.jumpDelta.set(0, 0);

    if (block instanceof ShakingBlock) {
      this.prevParent = this.parent;
      block.addChild(this);
    }

    this.skin.animateLand();
  }

  public reset(): void {
    gsap.killTweensOf(this);
    this.isFalling = false;
    this.isJumping = false;
    this.isOnFallingBlock = false;
    this.scale.set(this.catScale);
    this.skin.reset();
    this.isoZ = -100;
  }

  public faceDir(left: boolean): void {
    this.skinContainer.scale.x = left ? -1 : 1;
  }

  public clearBlock(): void {
    sound.stop('charge_jump');
    if (this.currentBlock) {
      this.isoX = this.currentBlock.isoX + this.localPosition.x;
      this.isoY = this.currentBlock.isoY + this.localPosition.y;
      this.updatePosition();
    }
    if (this.prevParent) {
      this.prevParent.addChildAt(this, this.prevParent.children.length - 2);
      this.prevParent = undefined;
    }
  }

  public jumpTo(x: number, y: number, jumpDuration: number): void {
    this.clearBlock();
    this.lastPosition.set(this.isoX, this.isoY);

    this.starTimer = 0;
    this.rotation = 0;
    this.isJumping = true;
    this.currentBlock?.sendOffPlayer();
    this.currentBlock = undefined;
    this.jumpDelta.x = x / jumpDuration;
    this.jumpDelta.y = y / jumpDuration;

    const jumpDist = distance(0, 0, x, y);
    if (jumpDist < 300) sound.play('jump_short');
    else if (jumpDist < 480) sound.play('jump_medium');
    else sound.play('jump_long');

    gsap.killTweensOf(this);
    gsap.to(this, {
      duration: jumpDuration,
      isoX: this.isoX + x,
      isoY: this.isoY + y,
      ease: 'none',
      onComplete: () => {
        this.isJumping = false;
      },
    });

    gsap.to(this, {
      duration: jumpDuration * 0.5,
      isoZ: -100 - 400 * jumpDuration,
      repeat: 1,
      yoyo: true,
      ease: 'power1.out',
      onUpdate: () => {
        this.updatePosition();
      },
    });

    this.skin.animateJump(jumpDuration);
  }

  public getIdealCameraPos(): Point {
    if (this.currentBlock) {
      if (this.currentBlock instanceof MovingBlock) {
        return this.currentBlock.startPos;
      } else {
        return new Point(this.currentBlock.isoX + this.localPosition.x, this.currentBlock.isoY + this.localPosition.y);
      }
    } else {
      return new Point(this.isoX, this.isoY);
    }
  }

  public fall(): void {
    if (this.isFalling) return;

    if (this.currentBlock) {
      this.clearBlock();
      this.currentBlock = undefined;
    }
    gsap.killTweensOf(this);
    this.isFalling = true;
    this.deltaZ = 1200;
  }

  public respawn(): void {
    this.reset();
    this.setPosition(this.lastPosition.x, this.lastPosition.y, this.isoZ);
  }

  public playCancelSound() {
    const alias = `${this.currentCostume}_cancel`;
    if (sound.exists(alias)) {
      sound.play(alias);
    } else {
      sound.play('cat_cancel');
    }
  }

  public playFailSound() {
    const alias = `${this.currentCostume}_fail`;
    if (sound.exists(alias)) {
      sound.play(alias);
    } else {
      sound.play('cat_fail');
    }
  }
}
