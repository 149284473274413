import { AmplifyJwt, AmplifyJwtLoader } from './loaders/amplify-jwt-loader';
import { JustOnce } from './loaders/loader';
import { InitializedNakamaClients, NakamaLoader } from './loaders/nakama-loader';
import EventEmitter from 'eventemitter3';
import { FlagLoader, InitializedFlags } from './loaders/satori-flag-loader';

export interface ArcadeGameType {
  GameDataType: unknown;
  ProgressType: unknown;
}

export interface GameOptions<T extends ArcadeGameType> {
  name: string;

  // dataLoader is a user-supplied function which returns Promise<T['GameDataType']>
  // it can depend on nakamaPromise, and it can fire a callback to report progress
  dataLoader: (
    nakamaPromise: Promise<InitializedNakamaClients>,
    progressCallback: (progress: T['ProgressType']) => void
  ) => Promise<T['GameDataType']>;
}

export enum ArcadeSdkLoadingState {
  NotReady = 'NotReady',
  Loading = 'Loading',
  Ready = 'Ready',
  Error = 'Error',
}

export class ArcadeGame<T extends ArcadeGameType> extends EventEmitter {
  public state: ArcadeSdkLoadingState = ArcadeSdkLoadingState.NotReady;
  public progress: T['ProgressType'] | null = null;
  public nakamaClients: InitializedNakamaClients | null = null;
  public flags: InitializedFlags | null = null;

  constructor(private gameOptions: GameOptions<T>) {
    super();
  }

  public async initialize() {
    try {
      this.state = ArcadeSdkLoadingState.Loading;

      const amplifyJwtLoader = JustOnce<AmplifyJwt>(new AmplifyJwtLoader());
      const nakamaLoader = JustOnce(new NakamaLoader(amplifyJwtLoader));
      const flagLoader = JustOnce(new FlagLoader(nakamaLoader));

      const amplifyJwtPromise = amplifyJwtLoader.load();
      const nakamaPromise = nakamaLoader.load().then((nakamaClients) => {
        /**
         * setting this property before resolving this promise insures that the dataLoader
         * can access this instance's nakamaClients once nakamaPromise resolves
         */
        this.nakamaClients = nakamaClients;
        return nakamaClients;
      });
      const flagPromise = flagLoader.load();
      const dataLoaderPromise = this.gameOptions.dataLoader(nakamaPromise, (progress) => {
        this.progress = progress;
        this.emit('progress-change', this.progress);
      });

      const [_jwt, _nakamaClients, flags, _gameData] = await Promise.all([
        amplifyJwtPromise,
        nakamaPromise,
        flagPromise,
        dataLoaderPromise,
      ]);

      this.state = ArcadeSdkLoadingState.Ready;
      this.flags = flags;
    } catch (e) {
      console.error(`Arcade.Game boot sequence failed for error: ${e}`);
      this.state = ArcadeSdkLoadingState.Error;
    }
  }
}

export const Arcade = {
  Game: ArcadeGame,
};
