import { BackButton } from '../BackButton/BackButton';
import { Button } from '../Button/Button';
import styles from './TermsOfService.module.scss';

type TermsOfServiceProps = {
  onClose: () => void;
};

export const TermsOfService = ({ onClose }: TermsOfServiceProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton onClick={onClose} />
        <div className={styles.username}>
          <h3>Terms and Conditions</h3>
        </div>
      </div>
      <article className={styles.termsOfService}>
        <h1>Metatheory, Inc.</h1>
        <h2>Terms of Service</h2>
        <p>
          <strong>Last Updated: October 15, 2024</strong>
        </p>

        <p>
          Welcome to GimmeCat (the “Game”)! The Game is a copyrighted work belonging to Metatheory, Inc. (“Metatheory”,
          “us”, “our”, and “we”). These Terms of Service set forth the legally binding terms and conditions that govern
          your use of the Game (“TOS” or “Terms”).
        </p>

        <p>
          These Terms require the use of arbitration (Section 10.2) on an individual basis to resolve disputes, rather
          than jury trials or class actions, and also limit the remedies available to you in the event of a dispute.
        </p>

        <h3>1. Access to the Game.</h3>
        <h4>1.1 Eligibility.</h4>
        <p>Only persons meeting the following requirements may access the Game:</p>
        <ul>
          <li>
            Persons who have the legal capacity to form a binding contract with Metatheory pursuant to the applicable
            laws of their jurisdiction;
          </li>
          <li>
            Persons who are the older of 18 years or the legal age to form a binding agreement in their jurisdiction;
          </li>
          <li>
            Persons who have a valid Telegram account and have read, understand, agree, and consent to Telegram’s
            Privacy Policy, Terms of Service and any other Telegram terms that may govern your use of the Game or any
            aspect thereof such as the Terms of Service for Telegram Stars (each of the foregoing as they may be updated
            by Telegram from time to time); and
          </li>
          <li>
            Persons who have read, understand, agree, and consent to all of these Terms, as well as Metatheory’s Privacy
            Policy.
          </li>
        </ul>

        <h4>1.2 License.</h4>
        <p>
          Subject to Section 1.1 and your continued compliance with all of these TOS, Metatheory grants you a limited,
          non-transferable, non-exclusive, revocable, limited license to play the Game for your personal, non-commercial
          use via the Telegram platform.
        </p>

        <h4>1.3 License Restrictions.</h4>
        <p>The rights granted to you in Section 1.2 are subject to the following restrictions. You may not:</p>
        <ul>
          <li>
            Copy, reproduce, distribute, republish, download, display, post or transmit in any form or by any means the
            Game, except as expressly permitted by this license;
          </li>
          <li>
            Modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable,
            of the Game;
          </li>
          <li>
            Reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the
            source code of the Game or any part thereof;
          </li>
          <li>
            Remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual
            property or proprietary rights notices from the Game, including any copy thereof;
          </li>
          <li>Access or use the Game in order to build a similar or competitive product or service;</li>
          <li>
            Rent, license, lease, lend, sell, sublicense, assign, distribute, host, publish, transfer, commercially
            exploit or otherwise make available the Game, or any features or functionality of the Game, to any third
            party for any reason, including by making the Game available on a network where it is capable of being
            accessed by more than one device at any time; or
          </li>
          <li>
            Remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights
            management, or security features in or protecting the Game.
          </li>
        </ul>

        <p>
          Unless otherwise indicated, any future release, update, patch, DLC, or other addition to functionality of the
          Game will be subject to this Agreement. All copyright and other proprietary notices on or within the Game must
          be retained on all copies thereof.
        </p>

        <h4>1.4 Free Content.</h4>
        <p>
          Upon accessing the Game, users will have access to certain features of the Game (“Free Features”). Metatheory
          may add, remove, modify or otherwise change the Free Features at any time with or without notice to you.
        </p>

        <h4>1.5 Modifications/Updates.</h4>
        <p>
          Metatheory reserves the right, at any time, to modify, suspend access to, or discontinue support of the Game
          (in whole or in part) with or without notice to you. Additionally, Metatheory may from time to time in its
          sole discretion develop and provide Game updates, which may include upgrades, bug fixes, patches, other error
          corrections, and/or new features (collectively, including related documentation, “Updates“). Updates may also
          modify or delete in their entirety certain features and functionality. You agree that Metatheory has no
          obligation to provide any Updates or to continue to provide or enable any particular features or
          functionality. Based on your device settings when your device is connected to the internet either: (i) the
          Game will automatically download and install all available Updates; or (ii) you may receive notice of or be
          prompted to download and install available Updates. You agree to promptly download and install all Updates and
          acknowledge and agree that the Game or portions thereof may not properly operate should you fail to do so. You
          further agree that all Updates will be deemed part of the Game and be subject to these Terms.
        </p>

        <h4>1.6 Account.</h4>
        <p>
          To access and use the Game you must have an account with Telegram, and you must abide by the terms of service
          associated with your Telegram account. You acknowledge and agree that Metatheory is not and will not be
          responsible for any issues related to your Telegram account.
        </p>

        <h4>1.7 No Support or Maintenance.</h4>
        <p>
          You acknowledge and agree that Metatheory will have no obligation to provide you with any support or
          maintenance in connection with the Game.
        </p>

        <h3>2. Use of the Game.</h3>
        <h4>2.1 Acceptable Use Policy.</h4>
        <p>You agree not to:</p>
        <ul>
          <li>
            (i) upload, transmit, or distribute to or through the Game any computer viruses, worms, or any software
            intended to damage or alter a computer system or data;
          </li>
          <li>
            (ii) send through the Game unsolicited or unauthorized advertising, promotional materials, junk mail, spam,
            chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial
            or otherwise;
          </li>
          <li>
            (iii) use the Game to harvest, collect, gather or assemble information or data regarding other users,
            including email addresses, without their consent;
          </li>
          <li>
            (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Game, or
            violate the regulations, policies or procedures of such networks;
          </li>
          <li>
            (v) attempt to gain unauthorized access to the Game (or to other computer systems or networks connected to
            or used together with the Game), whether through password mining or any other means;
          </li>
          <li>(vi) harass or interfere with any other user’s use and enjoyment of the Game; or</li>
          <li>
            (vii) use software or automated agents or scripts to produce multiple accounts on the Game, or to generate
            automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Game.
          </li>
        </ul>

        <h4>2.2 Enforcement.</h4>
        <p>
          We reserve the right (but have no obligation) to investigate and/or take appropriate action against you in our
          sole discretion if you violate these Terms or otherwise create liability for us or any other person. Such
          action may include terminating your ability to access or play the Game and/or reporting you to law enforcement
          authorities.
        </p>

        <h3>3. Ownership; Reservation of Rights.</h3>
        <h4>3.1 Proprietary Rights; Reservation of Rights.</h4>
        <p>
          You acknowledge and agree that the Game is provided under license, and not sold, to you. You do not acquire
          any ownership interest in the Game under this TOS, or any other rights other than to use the Game in
          accordance with this TOS. Metatheory and its licensors and service providers reserve and retain their entire
          right, title, and interest in and to the Game, including all copyrights, trademarks, and other related
          intellectual property rights, except as expressly granted to you in this TOS. Neither this TOS (nor your
          access to the Game) transfers to you or any third party any rights, title or interest in or to such
          intellectual property rights, except for the limited access rights expressly set forth in Section 1.2.
          Metatheory and its suppliers reserve all rights not granted in this TOS. There are no implied licenses granted
          under these Terms.
        </p>

        <h4>3.2 Feedback.</h4>
        <p>
          If you provide us with any feedback or suggestions regarding the Game (“Feedback”), you hereby assign to
          Metatheory all rights in such Feedback and agree that Metatheory will have the right to use and fully exploit
          such Feedback and related information in any manner it deems appropriate. Metatheory will treat any Feedback
          you provide to Metatheory as non-confidential and non-proprietary. You agree that you will not submit to
          Metatheory any information or ideas that you consider to be confidential or proprietary.
        </p>

        <h3>4. Virtual Items.</h3>
        <h4>4.1 Purchases of Virtual Items.</h4>
        <p>
          Our Service may permit you to use Telegram Stars to purchase Game-specific virtual tokens, coins or items
          (such as skins or other in-game resources) (all such Game-specific items collectively “Virtual Items”) for use
          with our Service. All purchases using Telegram Stars are subject to Telegram’s Terms of Service for Telegram
          Stars (as the same may be updated from time to time). Persons who are not eligible to use the Game for any
          reason under Section 1.1 are also not eligible to purchase Virtual Items. You agree that once purchased
          Virtual Items may have no monetary value and may never be able to be exchanged for real money, real goods or
          real services from us or anyone else. You also agree that you will only obtain Virtual Items from us, and not
          from any third party. You agree that Virtual Items are not transferable to anyone else and you will not
          transfer or attempt to transfer any Virtual Items to anyone else.
        </p>

        <h4>4.2 License of Virtual Items.</h4>
        <p>
          You do not own Virtual Items but instead you purchase a limited personal revocable license to use them - any
          balance of Virtual Items does not reflect any stored value.
        </p>

        <h4>4.3 Final Sale.</h4>
        <p>
          You agree that, to the greatest extent permissible by law, all sales to you of Virtual Items are final and
          that you will not be entitled to a refund in connection with any Virtual Item transaction, under any
          circumstances, once it has been made. If you live in the European Union you have certain rights to withdraw
          from distance purchases; however, please note that when you purchase a license to use Virtual Items from us,
          you acknowledge and agree that we will begin the provision of the Virtual Items to you promptly once your
          purchase is complete and therefore your right of withdrawal is lost at this point. For the purposes of this
          Section, a “purchase” is complete at the time the Telegram’s server validates your purchase and the applicable
          Virtual Items are successfully credited to your account.
        </p>

        <h4>4.4 Account Linking.</h4>
        <p>
          The Virtual Items that you purchase will be linked to your Telegram account. You understand and agree that you
          cannot transfer Virtual Items from one account to another. You understand and agree that we are not
          responsible for any problems or losses associated with your Telegram account, including without limitation,
          problems transferring purchases of Virtual Items or Stars from one device to another, restoring purchases from
          a lost or damaged device to a different device, or any other losses of Virtual Items. The risk of loss of
          Virtual Items is transferred to you upon completion of the purchase as described in Section 4.3 above.
        </p>

        <h4>4.5 Control of Virtual Items.</h4>
        <p>
          We reserve the right to control, regulate, change or remove any Virtual Items without any liability to you.
        </p>

        <h4>4.6 Pricing of Virtual Items.</h4>
        <p>
          We may revise the pricing for Virtual Items offered through the Game at any time. We may limit the total
          amount of Virtual Items that may be purchased at any one time, and/or limit the total amount of Virtual Items
          that may be held in your account in the aggregate. You are only allowed to purchase Virtual Items from us or
          our authorized partners through the Game, and not in any other way.
        </p>

        <h4>4.7 Terms for Virtual Items Purchases.</h4>
        <p>
          Any Virtual Items purchased will be purchased using Telegram Stars and such purchase will be subject to
          Telegram’s respective terms of service and user agreement. You agree that we will have no liability to you for
          any actions or inactions of Telegram in respect of the Virtual Items. For example we will have no liability if
          Telegram discontinues Stars or stops supporting some or all of the Stars functionality. Please check usage
          rights for each purchase as these may differ from item to item. Unless otherwise shown, content available in
          any in-game store has the same age rating as the game.
        </p>

        <h4>4.8 Loss of Access to Virtual Items.</h4>
        <p>
          Without limiting Section 4.4, if we suspend or terminate your account in accordance with these Terms, you may
          lose access to any Virtual Items that you may have accumulated, and you agree that you will not be entitled to
          any refund or other compensation for this loss.
        </p>

        <h4>4.9 Legal Restrictions on Virtual Items.</h4>
        <p>
          Our ability to permit some users to purchase Virtual Items may be restricted by the laws or regulations of
          your jurisdiction or locality. You agree that we will not be liable to you for failing to permit you to
          purchase or use Virtual Items because of the laws of your jurisdiction or locality.
        </p>

        <h3>5. Indemnification.</h3>
        <p>
          You agree to indemnify and hold Metatheory (and its officers, employees, and agents) harmless, including costs
          and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of
          the Game, (b) your Virtual Items, (c) your violation of these Terms, or (c) your violation of applicable laws
          or regulations. Metatheory reserves the right, at your expense, to assume the exclusive defense and control of
          any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these
          claims. You agree not to settle any matter without the prior written consent of Metatheory. Metatheory will
          use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
        </p>

        <h3>6. Release.</h3>
        <p>
          You hereby release and discharge Metatheory (and our officers, employees, agents, successors, and assigns)
          from all disputes, claims, controversies, demand, right, obligation, liability, action and cause of action of
          every kind and nature that relates in any way to the Game (including any interactions with, or act or omission
          of, other Game users or any Third Party Materials). IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE
          CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT
          EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
          EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH
          THE DEBTOR.”
        </p>

        <h3>7. Disclaimers.</h3>
        <p>
          THE GAME IS PROVIDED TO YOU “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE
          MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, METATHEORY EXPRESSLY DISCLAIMS ALL WARRANTIES WITH RESPECT TO
          THE GAME, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
          ENJOYMENT AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE,
          USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, METATHEORY PROVIDES NO WARRANTY OR UNDERTAKING,
          AND MAKES NO REPRESENTATION OF ANY KIND THAT THE GAME WILL MEET YOUR REQUIREMENTS.
        </p>

        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO
          YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
          LIMITATION MAY NOT APPLY TO YOU.
        </p>

        <h3>8. Limitation on Liability.</h3>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL METATHEORY HAVE ANY LIABILITY, INCLUDING
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE GAME.
        </p>

        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
          DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </p>

        <h3>9. Term and Termination.</h3>
        <p>
          Subject to this Section, these Terms will remain in full force and effect while you use the Game. We may
          suspend or terminate your rights to use the Game at any time for any reason at our sole discretion, including
          for any use of the Game in violation of these Terms. Upon termination of your rights under these TOS, your
          right to access and use the Game will terminate immediately. Metatheory will not have any liability whatsoever
          to you for any termination of your rights under these Terms. Even after your rights under these Terms are
          terminated, the provisions of these Terms which by their nature should survive the termination of this
          agreement will survive it, including without limitation: Sections 1.3, 1.7, 3, and 4 through 10.
        </p>

        <h3>10. General.</h3>

        <h4>10.1 Changes.</h4>
        <p>
          These Terms are subject to occasional revision, and if we make any substantial changes, we will notify you by
          prominently posting notice of the changes on the storefront where the Game can be accessed, such as the
          Metatheory website or the direct Telegram link. That prominent posting of notice of changes on the Game will
          be considered effective notice of such changes. Any changes to these Terms will be effective thirty (30)
          calendar days following our posting of notice of the changes. These changes will be effective immediately for
          new users of our Game. Continued use of our Game following notice of such changes will indicate your
          acknowledgment of such changes and agreement to be bound by the terms and conditions of such changes.
        </p>

        <h4>10.2 Arbitration.</h4>
        <p>
          Any dispute, claim or controversy arising out of or relating to this Agreement or the breach, termination,
          enforcement, interpretation or validity thereof, including the determination of the scope or applicability of
          this agreement to arbitrate, may be determined only by confidential arbitration in the State of Illinois. The
          arbitration will be administered pursuant to JAMS Comprehensive Arbitration Rules and Procedures and in
          accordance with the Expedited Procedures in those Rules. Judgment on the award may be entered in any court
          having jurisdiction. This clause does not preclude the Parties from seeking provisional remedies in aid of
          arbitration from a court of appropriate jurisdiction.
        </p>

        <h4>10.3 Export.</h4>
        <p>
          The Game may be subject to U.S. export control laws and may be subject to export or import regulations in
          other countries. You agree not to export, reexport, or transfer, directly or indirectly, any U.S. technical
          data acquired from Metatheory, or any products utilizing such data, in violation of the United States export
          laws or regulations.
        </p>

        <h4>10.4 Disclosures.</h4>
        <p>
          Metatheory is located at the address in Section 10.12. If you are a California resident, you may report
          complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department
          of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at
          (800) 952-5210.
        </p>

        <h4>10.5 Electronic Communications.</h4>
        <p>
          The communications between you and Metatheory use electronic means, whether you use the Game or send us
          emails, or whether Metatheory posts notices on the Game or communicates with you via email. For contractual
          purposes, you (a) consent to receive communications from Metatheory in an electronic form; and (b) agree that
          all terms and conditions, agreements, notices, disclosures, and other communications that Metatheory provides
          to you electronically satisfy any legal requirement that such communications would satisfy if it were to be in
          a hardcopy writing. The foregoing does not affect your non-waivable rights.
        </p>

        <h4>10.6 Severability.</h4>
        <p>
          If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the
          provision will be amended to achieve as closely as possible the effect of the original term and all other
          provisions of this Agreement will continue in full force and effect.
        </p>

        <h4>10.7 Governing Law.</h4>
        <p>
          This Agreement is governed by and construed in accordance with the internal laws of the State of Illinois
          without giving effect to any choice or conflict of law provision or rule.
        </p>

        <h4>10.8 Limitation of Time for Claims.</h4>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE GAME MUST BE
          COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.
        </p>

        <h4>10.9 Waiver.</h4>
        <p>
          No failure to exercise, and no delay in exercising, on the part of either party, any right or any power
          provided by these Terms will operate as a waiver of said right or power, nor will any single or partial
          exercise of any right or power preclude further exercise of that or any other right. In the event of a
          conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement will
          govern.
        </p>

        <h4>10.10 Entire Terms.</h4>
        <p>
          The TOS constitutes the entire agreement between you and us regarding the use of the Game. Our failure to
          exercise or enforce any right or provision of these Terms will not operate as a waiver of such right or
          provision. The section titles in these Terms are for convenience only and have no legal or contractual effect.
          The word “including” means “including without limitation”. If any provision of these Terms is, for any reason,
          held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired, and the invalid
          or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent
          permitted by law. These Terms, and your rights and obligations herein, may not be assigned, subcontracted,
          delegated, or otherwise transferred by you without Metatheory’s prior written consent, and any attempted
          assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.
          Metatheory may freely assign these Terms. The terms and conditions set forth in these Terms will be binding
          upon assignees.
        </p>

        <h4>10.11 Copyright/Trademark Information.</h4>
        <p>
          Copyright © 2024 Metatheory, Inc. All rights reserved. All trademarks, logos and service marks (“Marks”)
          displayed on the Game are our property or the property of other third parties. You are not permitted to use
          these Marks without our prior written consent or the consent of such third party which may own the Marks.
        </p>

        <h4>10.12 Contact Information:</h4>
        <p>
          Metatheory, Inc.
          <br />
          1 East Erie Street, Suite 525-4017
          <br />
          Chicago, IL 60611
          <br />
          United States
        </p>
        <p>
          For technical support or other inquiries, please contact us at{' '}
          <a href="https://support.metatheory.gg/hc/en-us">https://support.metatheory.gg/hc/en-us</a>.
        </p>
      </article>

      <div className={styles.closeContainer}>
        <Button primary fullWidth onClick={onClose}>
          close
        </Button>
      </div>
    </div>
  );
};
