import React from 'react';

type Props = {
  fill?: string;
};

export const AlertCircle: React.FC<Props> = React.memo(({ fill = '#FF5546' }) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.80686 6.12247L8.80686 6.1224C8.79313 4.42625 8.32518 3.12975 7.52739 2.26883C6.73498 1.41373 5.57186 0.939206 4.0605 0.969454C2.91792 0.99504 2.01684 1.4001 1.39927 2.07474C0.779108 2.75222 0.410713 3.73932 0.410713 4.98728C0.410713 6.40945 0.652902 7.57298 1.25249 8.36568C1.83217 9.13205 2.80498 9.62639 4.45681 9.58712L8.80686 6.12247ZM8.80686 6.12247C8.81504 7.11232 8.29008 7.95542 7.46171 8.57336M8.80686 6.12247L7.46171 8.57336M7.46171 8.57336C6.62983 9.19393 5.52088 9.56134 4.45697 9.58712L7.46171 8.57336Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.821426"
      />
    </svg>
  );
});
