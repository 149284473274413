import { useRef, useEffect } from 'react';
import { getPixiCanvas } from '../game-raw/StartGame';

/**
 *
 * TODO: consider making startGame and endGame idempotent so mistakes
 * in this bridge between React Pixi and Pixi do not cause problems.
 */
export default function JumpJump() {
  const containerRef = useRef<HTMLDivElement>(null);
  const isAppendedToContainerRef = useRef<boolean>(false);

  useEffect(() => {
    const initializeApp = async () => {
      if (containerRef.current && !isAppendedToContainerRef.current) {
        isAppendedToContainerRef.current = true;
        containerRef.current.appendChild(await getPixiCanvas());
      }
    };
    initializeApp();

    return () => {
      if (isAppendedToContainerRef.current) {
        isAppendedToContainerRef.current = false;
      }
    };
  }, [containerRef]);

  return <div className="JumpJump" ref={containerRef} />;
}
