import { Point } from 'pixi.js';
import { Block } from './block';
import { gsap } from 'gsap';
import { sound } from '@pixi/sound';
import { BlockShape } from '../engine/block-model';

export class ShakingBlock extends Block {
  shakePos: Point = new Point(0, 0);
  timer: number = 0;
  shakeSpeed: number = 8;
  fallTimer: number = 2.0;
  deltaZ: number = 0;
  playerLanded: boolean = false;
  isFalling: boolean = false;

  public init(x: number, y: number, z: number, size: number, shape: BlockShape): void {
    super.init(x, y, z, size, shape);
    this.timer = 0;
    this.shakeSpeed = 8;
    this.fallTimer = 2.0;
    this.deltaZ = 0;
    this.playerLanded = false;
    this.isFalling = false;
  }

  public update(dt: number) {
    super.update(dt);

    if (this.playerLanded && !this.isFalling) {
      this.fallTimer -= dt;
      if (this.fallTimer <= 0) this.fall();
    }

    if (this.isFalling) {
      this.deltaZ += 8000 * dt;
      this.isoZ += this.deltaZ * dt;
      this.updatePosition();
    } else {
      this.timer += dt * this.shakeSpeed;
      this.rotation = Math.sin(this.timer) * Math.PI * 0.02;
    }
  }

  public receivePlayer(): void {
    super.receivePlayer();
    this.startShaking();
  }

  public startShaking(): void {
    sound.play('shake_platform');
    this.shakeSpeed *= 2;
    this.playerLanded = true;
  }

  public fall(): void {
    this.isFalling = true;
    gsap.killTweensOf(this);
    this.isoX = this.startPos.x;
    this.isoY = this.startPos.y;
    this.deltaZ = 1200;

    sound.stop('shake_platform');
    sound.play('drop_platform');
  }
}
