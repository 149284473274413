import { restApiBase } from './utils/api';
import { AuthData, AuthDataSchema } from './types/auth-data';
import { authenticatedApi } from '@/lib/api';

export const getAuth = async (): Promise<AuthData> => {
  try {
    const response = await authenticatedApi.get(`${restApiBase}/auth`);
    return AuthDataSchema.parse(response.data);
  } catch (_error) {
    throw new Error('Failed to fetch JWT from Amplify');
  }
};

export const ThemeColors = {
  blockOutline: '#c38671',
  blockTop: '#ffd199',
  blockSide: '#efbd7f',
  blockDark: '#e0a862',
  uiText: '#202025',
  uiAccentText: '#ff5546',
  // TODO: add background colors
};
